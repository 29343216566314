import hasFeature from './hasFeature';

function getPublicProviderName(): string | null {
  try {
      const data = localStorage.getItem('aws-amplify-cacheuserProfile');
      if (data) {
          const parsedData = JSON.parse(data);
          return parsedData['data']['public_provider_name'] || null;
      }
  } catch (error) {
      console.error(`Error reading public_provider_name from localStorage: ${error}`);
  }

  return null;
}

function isInternalUser(): boolean {
  return getPublicProviderName() === 'AmazonFederate';
}

export function displaySip(): boolean {
  return hasFeature('sip') && isInternalUser();
}