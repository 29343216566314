import { Button, Header, SpaceBetween } from '@amzn/awsui-components-react';

import { useIntl } from 'react-intl';

export interface IUserListHeaderProps {
  count: number;
  onAddUserHandler: () => void;
};

const UserListHeader = ( { count, onAddUserHandler }: IUserListHeaderProps) => {

const { formatMessage } = useIntl();

return (
    <Header
      variant='awsui-h1-sticky'
      counter={`(${count})`}
      description={formatMessage({ id: 'usersSub' })}
      actions={
        <SpaceBetween
          size='xs'
          direction='horizontal'
        >
          <Button
            variant='primary'
            onClick={onAddUserHandler}
          >
            {formatMessage({ id: 'addUser' })}
          </Button>
        </SpaceBetween>
      }
    >
        {formatMessage({ id: 'users' })}
    </Header>
  );
};

export default UserListHeader;