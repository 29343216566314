import { Button, Header, SpaceBetween } from '@amzn/awsui-components-react';

import { displaySip } from '../../util/displaySip';
import { useIntl } from 'react-intl';

const HomepageHeader = () => {
  const { formatMessage } = useIntl();
  const displaySIP = displaySip();

  const descriptionId = displaySIP ? 'sip-headerDesc' : 'homePageHeaderInfo';
  const headerId = displaySIP ? 'sip-header' : 'homePageHeader';

  return (
    <SpaceBetween size='m'>
      <Header
        variant='h1'
        description={formatMessage({ id: descriptionId })}
        actions={
          <SpaceBetween
            size='xs'
            direction='horizontal'
            className='button-group'
          >
            {displaySIP && (
              <Button href='#/sales-accounts'>
                {formatMessage({ id: 'sipAccounts' })}
              </Button>
            )}
            <Button href='#/engagements'>
              {formatMessage({ id: 'viewEngagements' })}
            </Button>
            <Button
              href='#/lna-creation'
              variant='primary'
            >
              {formatMessage({ id: 'createEngagement' })}
            </Button>
          </SpaceBetween>
        }
      >
        {formatMessage({ id: headerId })}
      </Header>
    </SpaceBetween>
  );
};

export default HomepageHeader;
