import { useEffect, useState } from 'react';

import AccountDetailsComponent from './accountDetailsComponent';
import { ApiHookStatuses } from '../../../interfaces';
import { useGetAccountDetails } from '../../../services/api/account-hook';
import { useParams } from 'react-router-dom';

const AccountDetailsContainer = () => {
  const { accountId = '' } = useParams();
  const [loading, setLoading] = useState<boolean>(true); // initial status should be true, otherwise if will show no engagement in the list at the very beginning
  const [fetchError, setFetchError] = useState<any>();
  const { status, error, data, refetch } = useGetAccountDetails({ accountId });

  useEffect(() => {
    if (status === ApiHookStatuses.loading) { // Set requestArgs.token === '' to prevent the page from refresh list
      setLoading(true);
    }
    if (status === ApiHookStatuses.error && error) {
      setLoading(false);
      setFetchError(error);
    }
    if (status === ApiHookStatuses.success && data) {
      setLoading(false);
      }
    }, [status]);

  return (
    <AccountDetailsComponent
      loading={loading}
      data={data}
      fetchError={fetchError}
      refetch={refetch}
    />
  );
};

export default AccountDetailsContainer;
