import { ApiHookStatuses, IEngagement, Optional } from '../../../interfaces';
import {
  Button,
  Checkbox,
  Container,
  DatePicker,
  Form,
  FormField,
  Header,
  Input,
  Link,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { CreateEngagementRequest, EditEngagementRequest, ReopenEngagementRequest } from '../../../services/DAO/engagementsDAO';
import { deleteNotification, showNotification } from '../../../redux/reducers/notificationReducer';
import { useCreateEngagement, useEditEngagement, useReopenEngagement } from '../../../services/api/engagements-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import CancelModal from '../../../modals/cancelModal';
import { DynamicInputFields } from '../../../common/dynamic-input-fields';
import { GlobalState } from '../../../redux/reducers';
import { SERVER_TAKE_TOO_LONG } from '../../../common/const';
import { UUID } from '../../../util/uuid';
import { containsOnlySpaces } from '../../../util/containEmptyString';
import { encodedStr } from '../../../util/html-encoding';
import { getDirtyStringValues } from '../../../util/getDirtyStringValues';
import { isAtLeastTomorrow } from '../../../util/isAtLeastTmr';
import { isEmailValid } from '../../../util/isValidEmail';
import { isValidPartnerName } from "../../../util/isPartnerNameValid";
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

/**
 * updateTools, setup the help panel content by passing a topic id.
 */
type LNAFormProps = {
  isEditMode: boolean;
  isReopenMode: boolean;
  value?: IEngagement;
  updateTools?: (topic: string) => void;
};

type FormErrorProps = {
  accountName?: string;
  sfdcID?: string;
  engagementName?: string;
  closeDate?: string;
  numberOfParticipants?: string;
  engagementType?: string;
  teamNames?: string;
  collaborators?: string;
  partner?: string,
  partnerName?: string;
};

type EngagementData = CreateEngagementRequest | EditEngagementRequest;

const ADayInMilliseconds = (23 * 60 * 60 * 1000) + (59 * 60 * 1000);

const LnaFormContent = (props: LNAFormProps) => {
  const { formatMessage } = useIntl();
  const roles = useSelector<GlobalState, string[]>(state => state.user.roles);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate: createMutate, status: createStatus, error: createError } = useCreateEngagement();
  const { mutate: editMutate, status: editStatus, error: editError } = useEditEngagement();
  const { mutate: reopenMutate, status: reopenStatus, error: reopenError } = useReopenEngagement();
  const { isEditMode, isReopenMode, value, updateTools } = props;
  const isCreateMode = !isEditMode && !isReopenMode;

  const handleMutationResult = useCallback((status: any, error: any, successMessage: string, navigateTo: string, errorMessageHeader?: string) => {
    if (status === ApiHookStatuses.error && error) {
      setBtnLoading(false);
      if (error.message === SERVER_TAKE_TOO_LONG) {
        navigate('/engagements');
      }
      dispatch(deleteNotification());
      dispatch(showNotification({
        type: 'error',
        dismissible: true,
        headerText: formatMessage({ id: error.message === SERVER_TAKE_TOO_LONG ? '504ErrorCreation' : errorMessageHeader }),
        content: error.message,
        id: UUID(),
        displayInNewLocation: error.message === SERVER_TAKE_TOO_LONG ? true : false,
        displayInNewLocationDone: false
      }));
    }
    if (status === ApiHookStatuses.success) {
      setBtnLoading(false);
      navigate(navigateTo);
      dispatch(showNotification({
        type: 'success',
        dismissible: true,
        content: formatMessage({ id: successMessage }),
        id: UUID(),
        displayInNewLocation: true,
        displayInNewLocationDone: false
      }));
    }
  }, [dispatch, formatMessage, navigate]);

  // Handle the create engagement mutate results
  useEffect(() => {
    handleMutationResult(createStatus, createError, 'engagementCreated', '/engagements', 'createEngagementFailedHeader');
  }, [createStatus, handleMutationResult]);

  // Handle the edit engagement mutate results
  useEffect(() => {
    handleMutationResult(editStatus, editError, 'editSucceed', `/engagement-detail/${value?.engagementId}`, 'editFailed');
  }, [editStatus, handleMutationResult, value]);

  // Handle the reopen engagement mutate results
  useEffect(() => {
    handleMutationResult(reopenStatus, reopenError, 'reopenSucceed', `/engagement-detail/${value?.engagementId}`, 'reopenFailed');
  }, [reopenStatus, handleMutationResult, value]);


  let type = {};
  if (value && value.engagementType === 'Partner') {
    type = { value: 'Partner', label: formatMessage({ id: 'partner' }), description: formatMessage({ id: 'typeDescriptionP' }) };
  } else if (value && value.engagementType === 'Customer') {
    type = { value: 'Customer', label: formatMessage({ id: 'customer' }), description: formatMessage({ id: 'typeDescriptionC' }) };
  }

  const [formError, setFormError] = useState<FormErrorProps>({
    accountName: '',
    sfdcID: '',
    engagementName: '',
    closeDate: '',
    numberOfParticipants: '',
    engagementType: '',
    teamNames: '',
    collaborators: '',
    partner: '',
    partnerName: ''
  });

  let displayTime = '';
  if (value?.engagementCloseDate) {
    const utcTime = new Date(value.engagementCloseDate).toISOString();
    displayTime = utcTime.split('T')[0];
  }

  const [accountName, setAccountName] = useState<string>(value?.accountName || '');
  const [sfdcID, setSfdcId] = useState<string>(value?.accountSFDCId || '');
  const [engagementName, setEngagementName] = useState<string>(value?.engagementName || '');
  const [closeDate, setCloseDate] = useState<string>(displayTime);
  const [numberOfParticipants, setNumberOfParticipants] = useState<string>(`${value?.estimatedNumberOfParticipants}` || '');
  const lastUpdateDate = value?.lastUpdateDate;
  const versionNumber = value?.versionNumber;
  const [engagementType, setEngagementType] = useState<Optional<any>>(value ? type : undefined);
  const [displayIndividual, setDisplayIndividual] = useState<boolean>(value?.displayIndividualRecommendations || false);
  const [internal, setInternal] = useState<boolean>(value?.internalAWSTestEngagement || false);
  const [teamNames, setTeamNames] = useState<any[]>(['']);
  const [partnerId, setPartnerId] = useState<string>(value?.partnerId || '');
  const [partnerNameVal, setPartnerName] = useState<string>(value?.partnerName || '');
  const [btnLoading, setBtnLoading] = useState<boolean>(false); // When user submit form, we need to disable button, preventing user double submit
  const [visible, setVisible] = useState<boolean>(false); // Control the cancel modal visibility
  const [collaborators, setCollaborators] = useState<any[]>([]);

  /**
   * Two types of engagement: internal engagement and external engagement
   * Collaborator field status as below:
   * Visible: lna-atp-admin, lna-user, lna-admin can view the collaborator field on engagement.
   * Invisible: lna-atp-user cannot view collaborator field on engagement.
   * Disable: lna-atp-admin cannot edit collaborator but can view collaborator field on engagement.
   * Enable: lna-user and lna-admin can edit collaborator on internal engagement.
   */
  const logInEmail =  useSelector<GlobalState, string>(state => state.user.email || '');
  const isAdmin = roles.some(el => el === 'lna-admin');
  const creatorEmail = value?.engagementCreatedBy;
  // lna-atp-user and lna-atp-admin create atp engagement. Existing atp engagement has partnerId
  const isATPEngagement = partnerId !== '';
  // lna-atp-user cannot view collaborator field on form. All other roles can view collaborator field
  const isCollaboratorFormFieldVisible = !roles.some(el => el === 'lna-atp-user');
  // Engagement is non-atp engagement and admin/user role can edit/create collaborators. In creation form, no engagement creator email.
  const isCollaboratorEnabled = !isATPEngagement && (isAdmin || (roles.some(el => el === 'lna-user') && (creatorEmail == undefined || creatorEmail === logInEmail)));

  const checkFormError = (): boolean => {

    // We need to verify all team fields and collaborators fields, to address one situation that is user click add button but leaves the button untouched.
    // If not, even backend will throw error, but no error is shown on frontend.
    teamNames.map(item => { item.dirty = true; return item });
    collaborators.map(item => { item.dirty = true; return item});

    const errorObj = {};

    validateAccountName(errorObj);
    validateSFDC(errorObj);
    validatePartnerId(errorObj);
    validatePartnerName(errorObj);
    validateEngagementName(errorObj);
    validateDate(errorObj);
    validateEngagementType(errorObj);
    validateEstimatedNumberOfParticipants(errorObj);
    validateTeamNames(errorObj);
    validateEmail(errorObj);

    if (Object.values(errorObj).some(value => value != '')) {
      setFormError(errorObj);
      return false;
    } else {
      setFormError({});
      return true;
    }
  };

  /**
   * This set the cancel modal invisible when user click the nevermind button on cancel modal
   */
  const onCancelHandler = () => {
    setVisible(false);
  };

  const onDismissHandler = () => {
    setVisible(false);
  };

  const openCancelModal = () => {
    setVisible(true);
  };

  /**
   * This controls the behavior when user click the confirm button on Cancel modal
   * The current behavior is to router user to engagement list page
   */
  const onConfirmHandler = () => {
    navigate('/engagements');
  };

  const onSubmitHandler = () => {
    if (!checkFormError()) return;

    setBtnLoading(true);

    const engagementData: EngagementData = {
      accountName: encodedStr(accountName),
      accountSFDCId: encodedStr(sfdcID),
      partnerId: encodedStr(partnerId),
      partnerName: encodedStr(partnerNameVal),
      engagementName: encodedStr(engagementName),
      engagementCloseDate: new Date(closeDate).getTime() + ADayInMilliseconds,
      engagementType: engagementType.value,
      teams: teamNames.map((item) =>  encodedStr(item.value)),
      collaborators: collaborators.map((item) => encodedStr(item.value)),
      // when user touch this field in any way, then left this field blank, or something like -, this field will throw backend error, participant 0 is NOT allowed.
      // We don't want backend throw error, to avoid that, we want to emit this field to undefined, so backend will enter 100 by default.
      estimatedNumberOfParticipants: Number(numberOfParticipants) === 0 ? undefined : Number(numberOfParticipants),
      lastUpdateDate: Number(lastUpdateDate),
      versionNumber: Number(versionNumber),
      displayIndividualRecommendations: displayIndividual,
      internalAWSTestEngagement: internal,
    };

    // For LNA-ATP-User, we Allow user to see Partner ID on edit engagements page, but when we submit the Edit Engagement Request, we need to remove this attribute
    if (roles.some(el => el === 'lna-atp-user')) {
        delete engagementData.partnerId;
        delete engagementData.partnerName;
    }

    if (value) {
      if (isEditMode) {
        const editBody: EditEngagementRequest = {
          ...engagementData,
          engagementId: value.engagementId,
        };
        editMutate(editBody);
      } else if (isReopenMode) {
        const reopenBody: ReopenEngagementRequest = {
          engagementId: value.engagementId,
          engagementCloseDate: engagementData.engagementCloseDate,
        };
        reopenMutate(reopenBody);
      }
    } else if (isCreateMode) {
      createMutate(engagementData as CreateEngagementRequest);
    }
  };

  const sfdcInfoHandler = (e: Event) => {
    if (updateTools) {
      updateTools('sfdc');
    }
  };

  const collaboratorInfoHandler = (e: Event) => {
    if (updateTools) updateTools('collaboration');
  }

  const setFormErrorForField = (field: string, messageId: string, errorObj?: any) => {
    setFormError({ ...formError, [field]: formatMessage({ id: messageId }) });
    if (errorObj) errorObj[field] = formatMessage({ id: messageId });
  };

  const clearFormErrorForField = (field: string, errorObj?: any) => {
    setFormError({ ...formError, [field]: '' });
    if (errorObj) errorObj[field] = '';
  };

  const validateAccountName = (errorObj?: any): void => {
    if (!accountName.length) {
      setFormErrorForField('accountName', 'accountNameEmpty', errorObj);
    } else if (accountName.length < 3 || accountName.length > 100) {
      setFormErrorForField('accountName', 'accountNameLength', errorObj);
    } else {
      clearFormErrorForField('accountName', errorObj);
    }
  };

  const validateSFDC = (errorObj?: any): void => {
    // We only display SFDC ID field if user if not atp user, so we want to skip the check if login as atp user;
    if (!displaySFDCId) return;

    if (!sfdcID.length) {
      setFormErrorForField('sfdcID', 'sfdcEmpty', errorObj);
    } else if (sfdcID.length !== 18 || sfdcID.indexOf(' ') >= 0) {
      setFormErrorForField('sfdcID', sfdcID.length !== 18 ? 'sfdcLength' : 'whiteSpace', errorObj);
    } else {
      clearFormErrorForField('sfdcID', errorObj);
    }
  };

  const validateEngagementName = (errorObj?: any): void => {
    if (!engagementName.length) {
      setFormErrorForField('engagementName', 'engagementNameEmpty', errorObj);
    } else if (engagementName.length < 3 || engagementName.length > 100) {
      setFormErrorForField('engagementName', 'engagementNameLength', errorObj);
    } else {
      clearFormErrorForField('engagementName', errorObj);
    }
  };

  /**
   * Validates the close date and returns true if it's valid, false otherwise.
   * If the close date is invalid, a form error is set.
   * Valid if the date is, non empty, a date in future
   *
   * @returns {boolean} Whether the close date is valid or not.
   */
  const validateDate = (errorObj?: any): void => {
    // Check if the close date is empty or null
    if (!closeDate) {
      setFormErrorForField('closeDate', 'closeDateEmpty', errorObj);
    } else if (!isAtLeastTomorrow(closeDate)) { // Check if the close date is at least one day ahead of the current date, or today if is edit mode
      setFormErrorForField('closeDate', 'closeDateInvalid', errorObj);
    } else { // Clear any previous form errors for the close date and return true
      clearFormErrorForField('closeDate', errorObj);
    }
  };

  const validateEngagementType = (errorObj?: any): void => {
    if (engagementType === undefined) {
      setFormErrorForField('engagementType', 'engagementTypeEmpty', errorObj);
    } else {
      clearFormErrorForField('engagementType', errorObj);
    }
  };

  const validateEstimatedNumberOfParticipants = (errorObj?: any): void => {
    if (!numberOfParticipants) {
      clearFormErrorForField('numberOfParticipants', errorObj);
      return;
    }
    const numParticipants = Number(numberOfParticipants);
    if (numParticipants <= 0) {
      setFormErrorForField('numberOfParticipants', 'EstimatedNumberOfParticipantsPositive', errorObj);
    } else if (numParticipants >= 100000) {
      setFormErrorForField('numberOfParticipants', 'EstimatedNumberOfParticipantsLarge', errorObj);
    } else {
      clearFormErrorForField('numberOfParticipants', errorObj);
    }
  };

  const validateTeamNames = (errorObj?: any): void => {
    if (teamNames.length > 50) {
      setFormErrorForField('teamNames', 'teamNameLarge', errorObj);
    } else if (teamNames.length === 0) {
      setFormErrorForField('teamNames', 'teamNameEmpty', errorObj);
    } else if (containsOnlySpaces(getDirtyStringValues(teamNames))) {
      setFormErrorForField('teamNames', 'emptyStringTeamName', errorObj);
    } else if (teamNames.some(currentObj => currentObj.value !== undefined && currentObj.value.length > 100)) { // check is any team name is larger than 100 characters long
      setFormErrorForField('teamNames', 'teamNameTooLong', errorObj);
    } else {
      clearFormErrorForField('teamNames', errorObj);
    }
  };

  const validatePartnerId = (errorObj?: any): void => {
    if (!displayPartnerInfo) return;
    if (partnerId.length === 0) {
      setFormErrorForField('partner', 'partnerIdEmpty', errorObj);
    } else if (/^[0-9]{4,7}$/.test(partnerId) === false) { // Only numeric string is allowed and 4-7 digits
      setFormErrorForField('partner', 'partnerIdFormatError', errorObj);
    } else {
      clearFormErrorForField('partner', errorObj);
    }
  }

  const validatePartnerName = (errorObj?: any): void => {
    if (!displayPartnerInfo) return;
    if (!isValidPartnerName(partnerNameVal)) {
      setFormErrorForField('partnerName', 'partnerNameFormatError', errorObj);
    } else {
      clearFormErrorForField('partnerName', errorObj);
    }
  }

  // Same reason as below
  useEffect( () => {
    validateTeamNames();
  }, [teamNames]);

  // This will solve the issue when user delete a field that has error, but the validation logic is still shown error
  // That is because the validation logic will use the old data (The data before deletion due to set hook is async function)
  // Retrigger this validation logic once the setState hook update
  useEffect(() => {
    validateEmail();
  }, [collaborators]);

  const validateEmail = (errorObj?: any): void => {
    if (collaborators.length > 10) { // Check if user enter more than 10 collaborators
      setFormErrorForField('collaborators', 'tooManyCollaborators', errorObj);
    } else if (collaborators.some(currentObj => currentObj.value.length > 100)) { // check is any email address is large than 100 characters long
      setFormErrorForField('collaborators', 'emailLengthTooLong', errorObj);
    } else if (containsOnlySpaces(getDirtyStringValues(collaborators))) {
      // we only validate the strings that are "dirty", if the dirty strings contains only empty string, we throw error
      // If user click Add button without touching it, the field will not be dirty, we won't throw error, (But it will be validate when user click submit button)
      setFormErrorForField('collaborators', 'emailEmpty', errorObj);
    } else if (collaborators.some(currentObj => currentObj.dirty && !isEmailValid(currentObj.value))) {
      // Since all the untouched email is consider as clean and valid, we only validate those dirty ones, if any email is dirty and invalid, we throw error.
      setFormErrorForField('collaborators', 'notValidEmail', errorObj);
    } else {
      clearFormErrorForField('collaborators', errorObj);
    }
  }

  const participantsOnChangeHandler = ({ detail }: any) => {
    setNumberOfParticipants(detail.value);
  };

  const dateOnChangeHandler = ({ detail }: any) => {
    setCloseDate(detail.value)
  };

  const displaySFDCId = isCreateMode && roles.some(el => el.indexOf('atp') < 0) || (!isCreateMode && value?.accountSFDCId);

  const displayPartnerInfo = isCreateMode && roles.some(el => el === 'lna-atp-admin') || (!isCreateMode && value?.partnerId);

  return (
    <>
      <Form
        actions={
          <SpaceBetween
            size='xs'
            direction='horizontal'
          >
            <Button
              variant='link'
              onClick={openCancelModal}
              data-test='cancel-button'
            >
              {formatMessage({ id: 'cancel' })}
            </Button>
            <Button
              variant='primary'
              onClick={onSubmitHandler}
              loading={btnLoading}
              data-test='submit-form'
            >
              {formatMessage({ id: 'submit' })}
            </Button>
          </SpaceBetween>
        }
      >
        <Container
          header={
            <Header variant='h2'>
              {formatMessage({ id: 'engagementDetails' })}
            </Header>
          }
        >
          <SpaceBetween
            size='l'
            direction='vertical'
          >
            <FormField
              label={formatMessage({ id: 'accountNameLabel' })}
              description={formatMessage({ id: 'accountNameDescription' })}
              errorText={formError.accountName}
            >
              <Input
                value={accountName}
                onChange={({ detail }) => setAccountName(detail.value)}
                placeholder={formatMessage({ id: 'accountNamePlaceHolder' })}
                onBlur={validateAccountName}
                disabled={isReopenMode}
                data-test='account-name'
              />
            </FormField>
            {
              displayPartnerInfo &&
              <FormField
                label={formatMessage({ id: 'partnerLabel' })}
                description={formatMessage({ id: 'partnerIdDescription' })}
                errorText={formError.partner}
              >
                <Input
                  value={partnerId}
                  onChange={({ detail }) => setPartnerId(detail.value)}
                  placeholder={formatMessage({ id: 'partnerIdPlaceholder' })}
                  onBlur={validatePartnerId}
                  disabled={roles.some(el => el === 'lna-atp-user') || isReopenMode}
                  data-test='partner-id'
                />
              </FormField>
            }
            {
                displayPartnerInfo &&
                <FormField
                    label={formatMessage({ id: 'partnerNameLabel' })}
                    description={formatMessage({ id: 'partnerNameDescription' })}
                    errorText={formError.partnerName}
                >
                  <Input
                      value={partnerNameVal}
                      onChange={({ detail }) => setPartnerName(detail.value)}
                      placeholder={formatMessage({ id: 'partnerNamePlaceholder' })}
                      onBlur={validatePartnerName}
                      disabled={roles.some(el => el === 'lna-atp-user') || isReopenMode}
                      data-test='partner-name'
                  />
                </FormField>
            }
            { displaySFDCId &&
            <FormField
              label={formatMessage({ id: 'sfdcIdLabel' })}
              description={formatMessage({ id: 'sfdcDescription' })}
              errorText={formError.sfdcID}
              info={
                <Link
                  variant='info'
                  onFollow={sfdcInfoHandler}
                >
                  {formatMessage({ id: 'info' })}
                </Link>
              }
            >
              <Input
                value={sfdcID}
                onChange={({ detail }) => setSfdcId(detail.value)}
                placeholder={formatMessage({ id: 'sfdcPlaceHolder' })}
                onBlur={validateSFDC}
                disabled={isReopenMode}
                data-test='sfdc-id'
              />
            </FormField>}
            <FormField
              label={formatMessage({ id: 'engagementNameLabel' })}
              description={formatMessage({ id: 'engagementNameDescription' })}
              errorText={formError.engagementName}
            >
              <Input
                value={engagementName}
                onChange={({ detail }) => setEngagementName(detail.value)}
                placeholder={formatMessage({ id: 'engagementNamePlaceholder' })}
                onBlur={validateEngagementName}
                disabled={isReopenMode}
                data-test='engagement-name'
              />
            </FormField>
            <FormField
              label={formatMessage({ id: 'surveyCloseDate' })}
              description={formatMessage({ id: 'surveyCloseDescription' })}
              errorText={formError.closeDate}
            >
              <DatePicker
                value={closeDate}
                onChange={dateOnChangeHandler}
                onBlur={validateDate}
                openCalendarAriaLabel={(selectedDate) =>
                  'Choose Date' +
                  (selectedDate ? `, selected date is ${selectedDate}` : '')
                }
                nextMonthAriaLabel={formatMessage({ id: 'nextMonth' })}
                placeholder={formatMessage({ id: 'surveyClosePlaceholder' })}
                previousMonthAriaLabel={formatMessage({ id: 'previousMonth' })}
                todayAriaLabel={formatMessage({ id: 'today' })}
                isDateEnabled={(date) => date >= new Date()}
                data-test='date-picker'
              />
            </FormField>
            <FormField
              label={formatMessage({ id: 'engagementType' })}
              description={formatMessage({ id: 'engagementTypeDescription' })}
              errorText={formError.engagementType}
            >
              <Select
                selectedOption={engagementType}
                onChange={({ detail }) =>
                  setEngagementType(detail.selectedOption)
                }
                options={[
                  { label: formatMessage({ id: 'customer' }), value: 'Customer', description: formatMessage({ id: 'typeDescriptionC' }) },
                  { label: formatMessage({ id: 'partner' }), value: 'Partner', description: formatMessage({ id: 'typeDescriptionP' }) },
                ]}
                disabled={isEditMode || isReopenMode}
                selectedAriaLabel={formatMessage({ id: 'selected' })}
                placeholder={formatMessage({ id: 'engagementTypePlaceholder' })}
                onBlur={validateEngagementType}
                data-test='engagement-type'
              />
            </FormField>
            <FormField
              label={formatMessage({ id: 'teamNames' })}
              description={formatMessage({ id: 'teamNamesDescription' }, {
                u: (...str: any) => (<u key={str && str.toString()}>{str}</u>)
              })}
              errorText={formError.teamNames}
            >
              <DynamicInputFields
                fields={value?.teams || teamNames}
                isReopenMode={isReopenMode}
                setParentVariables={setTeamNames}
                onBlur={validateTeamNames}
                placeholder='enterTeamName'
                attributeName='teamName'
                addBtnText='addATeam'
                maximumSize={50}
                data-test='dynamic-inputs-team'
              />
            </FormField>
            {isCollaboratorFormFieldVisible && <FormField
              label={formatMessage({ id: 'collaborators' })}
              description={formatMessage({ id: 'collaboratorDesc' })}
              errorText={formError.collaborators}
              info={
                <Link
                  variant='info'
                  onFollow={collaboratorInfoHandler}
                >
                  {formatMessage({ id: 'info' })}
                </Link>
              }
            >
              <DynamicInputFields
                fields={value?.collaborators || collaborators}
                isReopenMode={isReopenMode}
                isCollaboratorEnabled={isCollaboratorEnabled}
                setParentVariables={setCollaborators}
                placeholder='collaboratorPlaceholder'
                attributeName='collaborators'
                addBtnText='addCollaborator'
                maximumSize={10}
                onBlur={validateEmail}
                data-test='dynamic-inputs-collaborator'
              />
            </FormField>}
            <FormField
              label={
                <span>
                  {formatMessage(
                    { id: 'estimatedNumberOfParticipantsLabel' },
                    { optional: <i>- optional</i> }
                  )}
                </span>
              }
              description={formatMessage({
                id: 'estimatedNumberOfParticipantsDescription',
              })}
              errorText={formError.numberOfParticipants}
            >
              <Input
                placeholder={formatMessage({
                  id: 'estimatedNumberOfParticipantsPlaceholder',
                })}
                onBlur={validateEstimatedNumberOfParticipants}
                value={numberOfParticipants}
                onChange={participantsOnChangeHandler}
                inputMode='numeric'
                type='number'
                disabled={isReopenMode}
                data-test='participant-number'
              />
            </FormField>
            <FormField>
              <Checkbox
                checked={displayIndividual}
                disabled={isEditMode || isReopenMode}
                onChange={({ detail }) => setDisplayIndividual(detail.checked)}
                description={formatMessage({
                  id: 'displayIndividualDescription',
                })}
                data-test='display-individual'
              >
                {formatMessage({ id: 'displayIndividual' })}
              </Checkbox>
            </FormField>
            <FormField>
              <Checkbox
                checked={internal}
                disabled={isEditMode || isReopenMode}
                onChange={({ detail }) => setInternal(detail.checked)}
                description={formatMessage({ id: 'internalTestDescription' })}
                data-test='internal-test'
              >
                {formatMessage({ id: 'internalTest' })}
              </Checkbox>
            </FormField>
          </SpaceBetween>
        </Container>
      </Form>
      <CancelModal
        visible={visible}
        onDismissHandler={onDismissHandler}
        onConfirmHandler={onConfirmHandler}
        onCancelHandler={onCancelHandler}
      />
    </>
  );
};

export default LnaFormContent;
