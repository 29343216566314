import { Link } from '@amzn/awsui-components-react';

type CounterLinkProps = {
  children: React.ReactNode;
  href?: string;
  isClickable?: boolean;
};

export const CounterLink = (props: CounterLinkProps) => {
  const { children, href, isClickable = true } = props;
  return (
    <>
      {isClickable && (
        <Link
          variant='awsui-value-large'
          href={href}
        >
          {children}
        </Link>
      )}
      {!isClickable && (
        <div className='not-clickable'>
          <Link
            variant='awsui-value-large'
          >
            {children}
          </Link>
        </div>
      )}
    </>
  );
};
