import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

export type NotificationState = {
  shouldShowNotification: boolean;
  notification?: {
    type: string;
    content?: string;
    id?: string;
    className?: string;
    headerText: string;
    loading?: boolean;
    dismissible?: boolean;
    dismissLabel?: string;
    Action?: React.ReactNode;
    displayInNewLocation?: boolean; // set this value to true if you want the notification to be displayed on a new URL once
    displayInNewLocationDone?: boolean; // set this to false if you want the notification to be displayed on a new URL
  }
};

const initialState: NotificationState = {
  shouldShowNotification: false
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification(state, action) {
      state.shouldShowNotification = true;
      state.notification = action.payload;
    },
    deleteNotification(state) {
      state.shouldShowNotification = false;
      state.notification = undefined;
    },
    markNewLocationDone(state) {
      if (state.notification) {
        state.notification.displayInNewLocationDone = true;
      }
    }
  }
});

export const { showNotification, deleteNotification, markNewLocationDone } = notificationSlice.actions;

export default notificationSlice.reducer;
