import { CookieConsent } from './cookie-consent';

declare global {
  interface Window {
    AwsUiConsent: CookieConsent;
  }
}

/**
 * It will need to onboard shortbread to use the content function
 * https://w.amazon.com/bin/view/Shortbread/adoption
 * That would require additional work, just simply return true for now
 * @returns
 */
const hasConsent = () => {
  // if (!process.env.EXTERNAL_SITE) {
  //   return true;
  // }

  // if (typeof window.AwsUiConsent === 'undefined') {
  //   return false;
  // }

  // const cookieConsent = window.AwsUiConsent.getConsentCookie();
  // return cookieConsent?.functional ?? false;
  return true;
};

export const save = (key: string, value: any) => {
  if (hasConsent()) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const remove = (key: string) => localStorage.removeItem(key);

export const load = (key: string) => {
  const value = localStorage.getItem(key);
  try {
    return value && JSON.parse(value);
  } catch (e) {
    console.warn(
      `⚠️ The ${key} value that is stored in localStorage is incorrect. Try to remove the value ${key} from localStorage and reload the page`
    );
    return undefined;
  }
};