import { AddPermissionsRequest, AddPermissionsResponse, GetUserRoleResponse, RemovePermissionsRequest, RemovePermissionsResponse, UpdateUserPermissionsRequest, UpdateUserPermissionsResponse, userDAO } from './../DAO/userDAO';
import { ApiHookResult, ApiMutateHookResult } from './../../interfaces/index';
import { useMutation, useQuery } from '@tanstack/react-query';

import { queryClient } from '../../components/App';

export type GetUserListResponse = {
  totalItem: number;
  pageSize: number;
  users: Array<{
    partnerId: string,
    partnerName: string,
    userId: string,
    roles: string[]
  }>;
  token?: string;
};

export type GetUserListRequest = {
  sort?: string;
  pageSize?: string;
  token?: string;
};

export const useGetUserRole = (): ApiHookResult<GetUserRoleResponse, Error> => {
  const result = useQuery({
    queryKey: ['getUserRole'],
    queryFn: () => userDAO.getUserRole()
  });

  return {
    status: result.status,
    data: result.data,
    error: result.error as Error,
    refetch: result.refetch
  }
};

export const useGetUerList = (req: GetUserListRequest): ApiHookResult<GetUserListResponse, Error> => {
  const result = useQuery({
    queryKey: ['getUserList'],
    queryFn: () => userDAO.getUserLists(req)
  });

  return {
    status: result.status,
    data: result.data,
    error: result.error as Error,
    refetch: result.refetch
  }
};

export const useUpdatePermission = (): ApiMutateHookResult<UpdateUserPermissionsResponse, UpdateUserPermissionsRequest, Error> => {
  const { data, error, status, mutate } = useMutation(
    (req: UpdateUserPermissionsRequest) => userDAO.updateUserPermissions(req),
    {
      mutationKey: ['updateUserPermissions'],
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['getUserList'] })
      }
    }
  );
  return {
    mutate,
    status,
    data,
    error: error as Error
  }
};

export const useAddPermission = (): ApiMutateHookResult<AddPermissionsResponse, AddPermissionsRequest, Error> => {
  const { data, error, status, mutate } = useMutation(
    (req: AddPermissionsRequest) => userDAO.addPermissions(req),
    {
      mutationKey: ['addPermission'],
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['getUserList'] })
      }
    }
  );
  return {
    mutate,
    status,
    data,
    error: error as Error
  }
};

export const useRemovePermission = (): ApiMutateHookResult<RemovePermissionsResponse, RemovePermissionsRequest, Error> => {
  const { data, error, status, mutate } = useMutation(
    (req: RemovePermissionsRequest) => userDAO.removePermissions(req),
    {
      mutationKey: ['removePermission'],
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['getUserList'] })
      }
    }
  );
  return {
    mutate,
    status,
    data,
    error: error as Error
  }
}