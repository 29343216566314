import Cache from '@aws-amplify/cache';
import { IEngagement } from '../../interfaces';
import { SERVER_TAKE_TOO_LONG } from '../../common/const';
import { delay } from '../../util/delay';
import { isJsonString } from '../../util/isJsonString';
import store from '../../redux/store';

export type ListEngagementRequest = {
  pageSize?: number;
  token?: string;
  sort?: string;
}

export type ListEngagementsResponse = {
  engagements: IEngagement[];
  pageSize: number;
  totalItem: number;
  token?: string;
};

export type GetEngagementRequest = {
  engagementId: string;
};

export type GetEngagementResponse = IEngagement;

export type CreateEngagementRequest = {
  accountName: string;
  accountSFDCId: string;
  engagementName: string;
  engagementCloseDate: number;
  engagementType: string;
  teams: string[];
  estimatedNumberOfParticipants?: number;
  displayIndividualRecommendations: boolean;
  internalAWSTestEngagement: boolean;
  collaborators?: string[];
  partnerId?: string;
  partnerName?: string;
};

export type CreateEngagementResponse = {
  engagementId: string;
};

export type DownloadResponseRequest = {
  engagementId: string;
};

// https://code.amazon.com/packages/LNAService/blobs/mainline/--/src/main/java/com/amazon/lnaservice/pojo/UpdateEngagementRequest.java
export type EditEngagementRequest = {
  engagementId: string;
  accountName: string;
  accountSFDCId: string;
  engagementName: string;
  engagementCloseDate: number;
  teams: string[];
  estimatedNumberOfParticipants?: number;
  collaborators?: string[];
  partnerId?: string;
  partnerName?: string;
  lastUpdateDate?: number;
  versionNumber?: number;
};

export type EditEngagementResponse = {
  engagementId: string;
};

export type ReopenEngagementRequest = {
  engagementId: string;
  engagementCloseDate: number;
};

export type ReopenEngagementResponse = {
  engagementId: string;
};

/**
 * @todo Right now We are reading the api endpoint in a asynchronous way, and we need to call the listEngagements at the beginning of page loads.
 *       And some time we also need to call get Engagement at the page loads, both API needs to know the endpoint.
 *       Race condition:
 *        1. fetch('/settings.json') -> retrieve API endpoint information
 *        2. listEngagements('${apiEndpoint}') -> this one needs the results from 1
 *
 *       We need to make sure to run the listEngagements after the 1 finish, at the time 1 finish, it will dispatch a state change to Redux store
 *       We read the api endpoint from the redux store, but since this is asynchronous. We currently have a work around is to wait half seconds
 *
 * @Solution We want to figure out how to insert the endpoint information into Front end's meta tag so we can read it in synchronous way, And this can be done
 */
class EngagementsDAO {

  stage!: string;
  endpoint!: string;
  authToken!: string;

  constructor() {
    this.init();
  }

  init = () => {
    const { api: { stage, endpoint } } = store.getState();
    this.stage = stage;
    this.endpoint = endpoint;
    this.authToken = Cache.getItem('federatedInfo')?.token;
  }

  /**
   *
   * @param url {string} The URL endpoint that API will call to
   * @param config {object} https://developer.mozilla.org/en-US/docs/Web/API/fetch
   * @param delayTime {number} How long this API will wait before it call the endpoint, this is temporary, to wait to other config files finish loading
   * @param errorMessage {string} The default error message
   * @returns {JSON} The response, right now only expect all data returned as JSON
   */
  call = async (url: string, config?: RequestInit, delayTime?: number, errorMessage?:string): Promise<any> => {

    if (delayTime) {
      await delay(delayTime);
    }

    // Magic line, don't modify this block, if you don't know what you're doing, stop
    // Maximum retry attempts
    const maxRetries = 10;
    let retries = 0;
    while ((this.endpoint === '' || this.authToken === '') && retries < maxRetries) {
      await delay(500); // Wait for half second before retrying
      this.init();
      retries++;
    }
    if (retries === maxRetries) {
      throw new Error('Maximum retries hit, still no token.')
    }
    // Magic line, don't modify this block, if you don't know what you're doing, stop

    const configWithToken = { ...config, headers: { 'Authorization': this.authToken }};
    try {
      const response = await fetch(`${this.endpoint}/${url}`, configWithToken);

      if (response.ok) {
        return response.json();
      }

      // If we get 504 error during create engagement call, we use the url and POST method to identify engagement creation api call, this might change in the future
      if ((response.status === 504 || response === undefined) && url === 'engagements' && config?.method === 'POST') {
        throw new Error(SERVER_TAKE_TOO_LONG);
      }

      return response.text().then( text => {
        if (isJsonString(text)) {
          const obj = JSON.parse(text);
          throw new Error(obj.Message || obj.message || errorMessage);
        } else {
          throw new Error(text);
        }
      });
    } catch (error) {
      console.error(error);
      throw new Error(SERVER_TAKE_TOO_LONG);
    }
  };

  listEngagements = async (req: ListEngagementRequest): Promise<ListEngagementsResponse> => {
    const queryParameters = new URLSearchParams();
    if (req.token) {
      queryParameters.set('token', req.token);
    }
    const queryString = queryParameters.toString();
    return this.call(`engagements/?${queryString}`, {
      method: 'GET',
      mode: 'cors'
    }, 500, 'list engagements failed');
  }

  /**
   *
   * @param req {GetEngagementRequest}
   * @returns IEngagement[]
   */
  getEngagement = async (req: GetEngagementRequest): Promise<IEngagement> => {
    return this.call(`engagements/${req.engagementId}`, {
      method: 'GET',
      mode: 'cors'
    }, 500, 'get engagement failed');
  }

  async createEngagement(req: CreateEngagementRequest): Promise<CreateEngagementResponse> {
    return this.call(`engagements`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    }, 0, 'create engagement failed');
  }

  async downloadResponse(req: DownloadResponseRequest): Promise<any> {
    return this.call(`engagements/${req.engagementId}/report`, {
      method: 'GET',
      mode: 'cors'
    }, 0, 'get report failed');
  }

  async editEngagement(req: EditEngagementRequest): Promise<EditEngagementResponse> {
    const requestBody: any = {
      accountName: req.accountName,
      accountSFDCId: req.accountSFDCId,
      engagementCloseDate: req.engagementCloseDate,
      teams: req.teams,
      engagementName: req.engagementName,
      collaborators: req.collaborators,
      partnerId: req.partnerId,
      partnerName: req.partnerName,
      lastUpdateDate: req.lastUpdateDate,
      versionNumber: req.versionNumber,
    };
    if (req.estimatedNumberOfParticipants) {
      requestBody['estimatedNumberOfParticipants'] = req.estimatedNumberOfParticipants;
    }
    return this.call(`engagements/${req.engagementId}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    }, 0, 'update engagement failed');
  }

  async reopenEngagement(req: ReopenEngagementRequest): Promise<ReopenEngagementResponse> {
    const requestBody: any = {
      engagementCloseDate: req.engagementCloseDate,
    };
    return this.call(`engagements/${req.engagementId}/reopen`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    }, 0, 'reopen engagement failed');
  }
}

export const engagementsDAO = new EngagementsDAO();
