import Box from '@amzn/awsui-components-react/polaris/box';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';
import { ReactNode } from 'react';

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export const CONTENT_SELECTOR_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'id', label: 'Distribution ID', editable: false },
      { id: 'domainName', label: 'Domain name', editable: true },
      { id: 'deliveryMethod', label: 'Delivery method', editable: true },
      { id: 'priceClass', label: 'Price class', editable: true },
      { id: 'sslCertificate', label: 'SSL certificate', editable: true },
      { id: 'origin', label: 'Origin', editable: true },
      { id: 'status', label: 'Status', editable: true },
      { id: 'state', label: 'State', editable: true },
      { id: 'logging', label: 'Logging', editable: true }
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
  { value: 10, label: '10 Distributions' },
  { value: 30, label: '30 Distributions' },
  { value: 50, label: '50 Distributions' }
];

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
  { value: 'table', label: 'Table' },
  { value: 'cards', label: 'Cards' }
];

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 30,
  visibleContent: ['id', 'domainName', 'deliveryMethod', 'sslCertificate', 'status', 'state'],
  wrapLines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};