import './index.scss';

import {
  AppLayout,
  AppLayoutProps,
  Flashbar,
  FlashbarProps,
  NonCancelableCustomEvent,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';

import BreadCrumbs from '../../../common/breadcrumbs';
import DashboardComponent from './dashboardComponent';
import { GlobalState } from '../../../redux/reducers';
import { NotificationState } from '../../../redux/reducers/notificationReducer';
import { ServiceNavigation } from '../../navigation';
import { Type } from '../../../interfaces';
import { UUID } from '../../../util/uuid';
import toolsTopics from '../../../common/tools-content';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [toolsOpen, setToolsOpen] = useState(false);
  const { shouldShowNotification, notification } = useSelector<
    GlobalState,
    NotificationState
  >((state) => state.notifications);
  const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  useEffect(() => {
    if (shouldShowNotification && notification) {
      const object = [
        {
          type: notification.type as Type,
          dismissible: notification.dismissible,
          onDismiss: () => setItems([]),
          content: notification.content,
          header: notification?.headerText,
          id: UUID(),
        },
      ];
      setItems(object);
    } else if (!shouldShowNotification) {
      setItems([]);
    }
  }, [shouldShowNotification, notification]);

  const { formatMessage } = useIntl();

  const onToolsChangeHandler = (
    event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>
  ): void => {
    setToolsOpen(event.detail.open);
  };

  const onNavigationChangeHandler = (
    event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>
  ): void => {
    setNavigationOpen(event.detail.open);
  };

  return (
    <AppLayout
      toolsOpen={toolsOpen}
      breadcrumbs={BreadCrumbs('dashboardBreadcrumbs', formatMessage)}
      onToolsChange={onToolsChangeHandler}
      tools={toolsTopics('salesAccounts', formatMessage)}
      navigation={<ServiceNavigation />}
      navigationOpen={navigationOpen}
      onNavigationChange={onNavigationChangeHandler}
      content={<DashboardComponent />}
      notifications={<Flashbar items={items} />}
    />
  );
};

export default Dashboard;
