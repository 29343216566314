import { ApiHookResult, ApiMutateHookResult, GetAccountDetailsRequest, GetAccountDetailsResponse, GetAccountsRequest, GetAccountsResponse, SortAccountsByPropertyRequest, SortAccountsByPropertyResponse } from '../../interfaces';
import { useMutation, useQuery } from '@tanstack/react-query';

import { accountsDAO } from '../DAO/accountsDAO';

export const useGetAccounts = (req: GetAccountsRequest): ApiHookResult<GetAccountsResponse, Error> => {
  const result = useQuery({
    queryKey: ['getAccounts', req],
    queryFn: () => accountsDAO.getAccounts(req),
    cacheTime: 0 // Set cacheTime to 0 for no caching.
  });

  return {
    status: result.status,
    data: result.data,
    error: result.error as Error,
    refetch: result.refetch
  }
};

export const useGetAccountsMutate = (): ApiMutateHookResult<GetAccountsResponse, GetAccountsRequest, Error> => {
  const { data, error, status, mutate } = useMutation(
    (req: GetAccountsRequest) => accountsDAO.getAccounts(req),
    {
      mutationKey: ['getAccountsMutation']
    }
  );
  return {
    mutate: mutate,
    status: status,
    data: data,
    error: error as Error
  }
};
export const useSortAccountsMutate = (): ApiMutateHookResult<SortAccountsByPropertyResponse, SortAccountsByPropertyRequest, Error> => {
  const { data, error, status, mutate } = useMutation(
    (req: SortAccountsByPropertyRequest) => accountsDAO.sortAccountsByProperty(req),
    {
      mutationKey: ['sortAccountsByProperty']
    }
  );

  return {
    mutate: mutate,
    status: status,
    data: data,
    error: error as Error
  }
};


export const useGetAccountDetails = (req: GetAccountDetailsRequest): ApiHookResult<GetAccountDetailsResponse, Error> => {
  const result = useQuery({
    queryKey: ['getAccountDetails', req.accountId],
    queryFn: () => accountsDAO.getAccountDetails(req),
    enabled: !!req.accountId
  });
  return {
    status: result.status,
    data: result.data,
    error: result.error as Error,
    refetch: result.refetch
  }
};

