/**
 * @description This function is used to check if a specific feature is enabled for the current user.
 * It checks both allowed and denied access using 'allowlist' and 'denylist'.
 * @param featureName {string} The name of the feature
 * @returns boolean, true if the feature is enabled. If there's no explicit rule, it will return the general status of the feature or false if that is not defined.
 */
export default function hasFeature (featureName: string): boolean {
  // Retrieve current login user email
  const loginCacheUserProfile = localStorage.getItem('aws-amplify-cacheuserProfile');
  let email;
  if (loginCacheUserProfile) {
    email = JSON.parse(loginCacheUserProfile).data.email;
  }

  // Locate the meta tag by name
  const metaTag = document.querySelector('meta[name="fac"]') as HTMLMetaElement;

  // If there's no meta tag, return false
  if (!metaTag) return false;


  // Parse the content to JSON
  const facData = JSON.parse(metaTag.content);
  // Determine the environment based on the hostname
  const hostname = window.location.hostname;

  // Set 'env' according to the current hostname
  let env;
  if (hostname.includes('beta')) {
    env = 'beta';
  } else if (hostname.includes('gamma')) {
    env = 'gamma';
  } else if (hostname === 'lna.aws.training') {
    env = 'prod';
  } else {
    env = 'dev';
  }

  /*
 * Check if environment data exists in facData,
 * if not, return false as we can't determine features without environment data
 * Here we are securing our function against possible undefined values
 */
  if (!facData[env]) return false;

  if (facData[env]['uniqueControl']) {

    const allowlist = facData[env]['uniqueControl']['allowlist'];
    const denylist = facData[env]['uniqueControl']['denylist'];

    // Deny list will take higher priority, in case we wrongly put same user in both allowlist and denylist, we deny them
    if (denylist) {
      for (const [key, values] of Object.entries(denylist)) {
        if (featureName === key) {
          for (let value of values as string[]) {
            if (value === email) {
              return false;
            }
          }
        }
      }
    }

    if (allowlist) {
      for (const [key, values] of Object.entries(allowlist)) {
        if (featureName === key) {
          for (let value of values as string[]) {
            if (value === email) {
              return true;
            }
          }
        }
      }
    }
  }

  /*
 * Check the general status of the feature in facData.
 * Use Boolean() to ensure we always return a boolean value even if the feature is not defined.
 */
  return facData[env][featureName] || false;

}
