import {
  Button,
  Header,
  Popover,
  SpaceBetween,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import { Dispatch, SetStateAction } from 'react';

import { ConditionalWrapper } from "../../helper/ConditionalWrapper";
import { GetEngagementResponse } from '../../../services/DAO/engagementsDAO';
import { InfoLink } from '../../../common/common-components';
import { UUID } from '../../../util/uuid';
import { copyToClipboard } from '../../../common/copy-to-clipboard';
import { queryClient } from '../../App';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

type EngagementDetailHeaderProps = {
  engagementName: string;
  updateTools: Dispatch<SetStateAction<string>>;
};

const EngagementDetailHeader = (props: EngagementDetailHeaderProps) => {

  const { engagementName, updateTools } = props;
  const { engagementId } = useParams();
  const { formatMessage } = useIntl();

  const data: GetEngagementResponse | undefined = queryClient.getQueryData(['getEngagement', engagementId]);
  const isProcess = data?.engagementStatus === "Processing"
  return (
    <>
      <Header
        variant='h1'
        info={
          <InfoLink
            id='form-main-info-link'
            onFollow={() => updateTools('engagementDetail')}
          />
        }
        actions={
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
              <ConditionalWrapper
                  condition={!isProcess}
                  wrapper={children =>
                      <Popover
                          size='medium'
                          position='top'
                          triggerType='custom'
                          dismissButton={false}
                          content={
                              <StatusIndicator type='success'>
                                  {data?.surveyDistributionLink}
                              </StatusIndicator>
                          }
                      >
                          {children}
                      </Popover>
                  }>
                  <Button disabled={isProcess}
                          onClick={() => {
                              copyToClipboard(data?.surveyDistributionLink || '')
                          }}
                  >
                      {formatMessage({id: 'copyEngagementLink'})}
                  </Button>
              </ConditionalWrapper>
            <Button
              href={data?.surveyPreviewLink +'&preview_sequence=' + UUID()}
              iconAlign='right'
              iconName='external'
              target='_blank'
              disabled={isProcess}
            >
              {formatMessage({ id: 'previewSurvey' })}
            </Button>
          </SpaceBetween>
        }
        description={formatMessage({ id: 'engagementHeaderDescription' })}
      >
        {engagementName}
      </Header>
    </>
  );
};

export default EngagementDetailHeader;
