import {
  Alert,
  Box,
  Button,
  Modal,
  SpaceBetween,
} from '@amzn/awsui-components-react';

import { useIntl } from 'react-intl';

type DeleteUserModalProps = {
  visible: boolean;
  onDismissHandler: () => void;
  onConfirmHandler: () => void;
  onCancelHandler: () => void;
  isLoading: boolean;
};

const DeleteUserModal = (props: DeleteUserModalProps) => {
  const { formatMessage } = useIntl();
  const { visible, onDismissHandler, onConfirmHandler, onCancelHandler, isLoading } = props;

  return (
    <Modal
      className='awsui-visual-refresh'
      onDismiss={onDismissHandler}
      visible={visible}
      closeAriaLabel={formatMessage({ id: 'closeModal' })}
      header={formatMessage({ id: 'deleteUser' })}
      footer={
        <ModalFooter
          onConfirmHandler={onConfirmHandler}
          onCancelHandler={onCancelHandler}
          isLoading={isLoading}
        />
      }
    >
      <Alert statusIconAriaLabel='Warning' type='warning'>
        {formatMessage({ id: 'deleteUserText' })}
      </Alert>
    </Modal>
  );
};

const ModalFooter = (props: {
  onConfirmHandler: () => void;
  onCancelHandler: () => void;
  isLoading: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { onConfirmHandler, onCancelHandler, isLoading } = props;
  return (
    <Box float='right'>
      <SpaceBetween
        direction='horizontal'
        size='xs'
      >
        <Button
          variant='link'
          onClick={onCancelHandler}
        >
          {formatMessage({ id: 'cancel' })}
        </Button>
        <Button
          variant='primary'
          onClick={onConfirmHandler}
          loading={isLoading}
        >
          {formatMessage({ id: 'confirmDelete' })}
        </Button>
      </SpaceBetween>
    </Box>
  );
};

export default DeleteUserModal;
