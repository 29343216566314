import { Button, CollectionPreferencesProps, TableProps } from '@amzn/awsui-components-react';

import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { UserListType } from './userListComponent';

export function getUserListTableConfig(formatMessage: any, openEditUserModal: (email?: string, partnerId?: string, partnerName?: string, role?: string[]) => void): any {
  const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    pageSize: 30,
    wrapLines: false,
    custom: { value: 'table', label: formatMessage({ id: 'tableLabel' }) },
    visibleContent: [
      'email',
      'partnerId',
      'partnerName',
      'roles'
    ]
  };

  const sortComparatorForPartnerId = (a: UserListType, b: UserListType) => {
    if (a.partnerId === null && b.partnerId === null) return 0;
    else if (a.partnerId === null) return -1;
    else if (b.partnerId === null) return 1;
    else return a.partnerId.localeCompare(b.partnerId);
  };

  const sortComparatorForPartnerName = (a: UserListType, b: UserListType) => {
    if (a.partnerName === null && b.partnerName === null) return 0;
    else if (a.partnerName === null) return -1;
    else if (b.partnerName === null) return 1;
    else return a.partnerName.localeCompare(b.partnerName);
  };

  const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<UserListType>[] = [
    {
      id: 'email',
      header: formatMessage({ id: 'email' }),
      cell: (item) => (<Button variant='link' onClick={() => openEditUserModal(item.userId, item.partnerId, item.partnerName, item.roles)}>{item.userId}</Button>),
      sortingField: 'email',
      sortingComparator: (a, b) => a.userId.localeCompare(b.userId),
    },
    {
      id: 'roles',
      header: formatMessage({ id: 'userRole' }),
      cell: item => {
        return item.roles.map((role, index) => {
          if (index === item.roles.length - 1) {
            return role;
          } else {
            return role + ', ';
          }
        })
      },
      sortingField: 'roles',
      sortingComparator: (a, b) => a.roles[0].localeCompare(b.roles[0]),
    },
    {
      id: 'partnerId',
      header: formatMessage({ id: 'partnerId' }),
      cell: (item) => item.partnerId,
      sortingField: 'partnerId',
      sortingComparator: sortComparatorForPartnerId
    },
    {
      id: 'partnerName',
      header: formatMessage({ id: 'partnerNameLabel' }),
      cell: (item) => item.partnerName,
      sortingField: 'partnerName',
      sortingComparator:  sortComparatorForPartnerName,
    }
  ];

  const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
    {
      propertyLabel: formatMessage({ id: 'email' }),
      key: 'userId',
      groupValuesLabel: formatMessage({ id: 'email' }),
      operators: ['=', '!=', ':', '!:']
    },
    {
      propertyLabel: formatMessage({ id: 'partnerId' }),
      key: 'partnerId',
      groupValuesLabel: formatMessage({ id: 'partnerId' }),
      operators: ['=', '!=', ':', '!:']
    },
    {
      propertyLabel: formatMessage({ id: 'partnerNameLabel' }),
      key: 'partnerName',
      groupValuesLabel: formatMessage({ id: 'partnerNameLabel' }),
      operators: ['=', '!=', ':', '!:']
    },
    {
      propertyLabel: formatMessage({ id: 'userRole' }),
      key: 'roles',
      groupValuesLabel: formatMessage({ id: 'userRole' }),
      operators: ['=', '!=', ':', '!:']
    }
  ];

  const PROPERTY_FILTERING_I18N_CONSTANTS = {
    filteringAriaLabel: formatMessage({id: 'yourChoice'}),
    dismissAriaLabel: formatMessage({id: 'dismiss'}),

    filteringPlaceholder: formatMessage({id: 'searchUser'}),
    groupValuesText: formatMessage({id: "groupValues"}),
    groupPropertiesText: formatMessage({id: 'properties'}),
    operatorsText: formatMessage({id: 'operators'}),

    operationAndText: formatMessage({id: "and"}),
    operationOrText: formatMessage({id: "or"}),

    operatorLessText: formatMessage({id: "lessThan"}),
    operatorLessOrEqualText: formatMessage({id: "lessThanOrEqual"}),
    operatorGreaterText: formatMessage({id: "greaterThan"}),
    operatorGreaterOrEqualText: formatMessage({id: "greaterThanOrEqual"}),
    operatorContainsText: formatMessage({id: "contains"}),
    operatorDoesNotContainText: formatMessage({id: "doesNotContain"}),
    operatorEqualsText: formatMessage({id: "equals"}),
    operatorDoesNotEqualText: formatMessage({id: "doesNoteEqual"}),

    editTokenHeader: formatMessage({id: "editFilter"}),
    propertyText: formatMessage({id: "property"}),
    operatorText: formatMessage({id: "operator"}),
    valueText: formatMessage({id: "value"}),
    cancelActionText: formatMessage({id: "cancel"}),
    applyActionText: formatMessage({id: "apply"}),
    allPropertiesLabel: formatMessage({id: "allProperties"}),

    tokenLimitShowMore: formatMessage({id: "showMore"}),
    tokenLimitShowFewer: formatMessage({id: "showFewer"}),
    clearFiltersText: formatMessage({id: "clearFilters"}),
    removeTokenButtonAriaLabel: () => formatMessage({id: "removeToken"}),
    enteredTextLabel: (text: any) => formatMessage({id: "useTextLabel"}, { text: `${text}`}),
  };

  const PAGINATION_LABELS = {
    nextPageLabel: formatMessage({id: "nextPage"}),
    pageLabel: (pageNumber: number) => formatMessage({id: "goToPage"}, { pageNumber: `${pageNumber}`}),
    previousPageLabel: formatMessage({id: "previousPage"}),
  };

  const CONTENT_SELECTOR_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] =
  [
    {
      label: formatMessage({id: "userListProperties"}),
      options: [
        { id: 'email', label: formatMessage({id: 'email' }), editable: false },
        { id: 'partnerId', label: formatMessage({id: 'partnerId' }), editable: true },
        { id: 'partnerName', label: formatMessage({id: 'partnerNameLabel' }), editable: true },
        { id: 'roles', label: formatMessage({id: 'userRole' }), editable: true }
      ],
    },
  ];

  const PAGE_SELECTOR_OPTIONS: CollectionPreferencesProps.PageSizeOption[] =
  [
    { value: 10, label: formatMessage({id: '10users'}) },
    { value: 30, label: formatMessage({id: '30users'}) },
    { value: 50, label: formatMessage({id: '50users'}) },
  ];

  return {
    contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
    defaultPreferences: DEFAULT_PREFERENCES,
    columnDefinitions: COLUMN_DEFINITIONS,
    filteringProperties: FILTERING_PROPERTIES,
    propertyFilteringI18nConstants: PROPERTY_FILTERING_I18N_CONSTANTS,
    paginationLabels: PAGINATION_LABELS,
    pageSelectorOptions: PAGE_SELECTOR_OPTIONS
  }
}