import { GetAccountDetailsRequest, GetAccountDetailsResponse, GetAccountsRequest, GetAccountsResponse, SortAccountsByPropertyRequest, SortAccountsByPropertyResponse } from '../../interfaces';

import Cache from '@aws-amplify/cache';
import { delay } from '../../util/delay';
import { isJsonString } from '../../util/isJsonString';
import store from '../../redux/store';

class AccountsDAO {
  authToken!: string;
  endpoint!: string;

  constructor () {
    this.init();
  }

  init = () => {
    const { api: { endpoint } } = store.getState();
    this.authToken = Cache.getItem('federatedInfo')?.token;
    this.endpoint = endpoint;
  };

  /**
 *
 * @param url {string} The URL endpoint that API will call to
 * @param config {object} https://developer.mozilla.org/en-US/docs/Web/API/fetch
 * @param delayTime {number} How long this API will wait before it call the endpoint, this is temporary, to wait to other config files finish loading
 * @param errorMessage {string} The default error message
 * @returns {JSON} The response, right now only expect all data returned as JSON
 */
  call = async (url: string, config?: RequestInit, delayTime?: number, errorMessage?: string): Promise<any> => {

    if (delayTime) {
      await delay(delayTime);
    }

    // Magic line, don't modify this block, if you don't know what you're doing, stop
    // Maximum retry attempts
    const maxRetries = 10;
    let retries = 0;
    while ((this.endpoint === '' || this.authToken === '') && retries < maxRetries) {
      await delay(500); // Wait for half second before retrying
      this.init();
      retries++;
    }
    if (retries === maxRetries) {
      throw new Error('Maximum retries hit, still no token.')
    }
    // Magic line, don't modify this block, if you don't know what you're doing, stop

    const configWithToken = { ...config, headers: { 'Authorization': this.authToken } }
    try {
      const response = await fetch(`${ this.endpoint }/${ url }`, configWithToken);
      if (response.ok) {
        return response.json();
      }
      return response.text().then(text => {
        if (isJsonString(text)) {
          const obj = JSON.parse(text);
          throw new Error(obj.Message || obj.message || errorMessage);
        } else {
          throw new Error(text);
        }
      });
    } catch (error) {
      console.error(error);
      throw new Error(errorMessage);
    }
  };

  getAccounts = async (req: GetAccountsRequest): Promise<GetAccountsResponse> => {
    return this.call(`sales-insight-management/sales-accounts`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    }, 0, 'Failed to get sales accounts, please try again later or contact admin');
  };

  getAccountDetails = async (req: GetAccountDetailsRequest): Promise<GetAccountDetailsResponse> => {
    return this.call(`sales-insight-management/sales-accounts/${ req.accountId }`, {
      method: 'GET'
    }, 0, 'Failed to get account details, please try again later or contact admin');
  };

  sortAccountsByProperty = async (req: SortAccountsByPropertyRequest): Promise<SortAccountsByPropertyResponse> => {
    return this.call(`sales-insight-management/sales-accounts`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    }, 0, `Failed to sort accounts, please try again later or contact admin`);
  };

}

export const accountsDAO = new AccountsDAO();
