import './index.scss';

import { AppLayout, Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';

import BreadCrumbs from '../../../common/breadcrumbs';
import { GlobalState } from '../../../redux/reducers';
import LNACreateFormHeader from './form-header';
import LnaFormContent from './form-content';
import { NotificationState } from '../../../redux/reducers/notificationReducer';
import { ServiceNavigation } from '../../navigation';
import { Type } from '../../../interfaces';
import { UUID } from '../../../util/uuid';
import { WaitFor } from '../../waitFor/waitFor';
import toolsTopics from '../../../common/tools-content';
import { useGetEngagement } from '../../../services/api/engagements-hook';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export type LnaFormProps = {
  isEditMode: boolean,
  isReopenMode: boolean
};

const LnaForm = ({ isEditMode, isReopenMode }: LnaFormProps) => {
  const [toolsTopic, setToolsTopic] = useState(isEditMode ? 'editEngagement' : isReopenMode ? 'reopenEngagement' : 'createLna');
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(false);
  const { engagementId = '' } = useParams();
  const getEngagementQuery = useGetEngagement({ engagementId });
  const { shouldShowNotification, notification } = useSelector<GlobalState, NotificationState>(state => state.notifications);
  const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const { formatMessage } = useIntl();

  const updateTools = (topic: string) => {
    setToolsTopic(topic);
    setToolsOpen(!toolsOpen);
  };

  useEffect(() => {
    if(shouldShowNotification && notification) {
      const object = [{
        type: notification.type as Type,
        dismissible: notification.dismissible,
        onDismiss: () => setItems([]),
        content: notification.content,
        id: notification.id || UUID(),
        header: notification.headerText
      }];
      setItems(object);
    } else if(!shouldShowNotification) {
      setItems([]);
    }
  }, [shouldShowNotification, notification]);

  const content = (engagementId !== '') ?
  <WaitFor data={getEngagementQuery} centerSpinner={true} spinnerSize='large'>
    {getEngagementQuery.data && <LnaFormContent isEditMode={isEditMode} isReopenMode={isReopenMode} value={getEngagementQuery.data} updateTools={updateTools} />}
  </WaitFor>
  :
  <LnaFormContent isEditMode={isEditMode} isReopenMode={isReopenMode} updateTools={updateTools} />

  return (
    <AppLayout
      contentHeader={<LNACreateFormHeader isEditMode={isEditMode} isReopenMode={isReopenMode} updateTools={updateTools} />}
      breadcrumbs={BreadCrumbs(isEditMode ? 'editEngagementBreadcrumbs' : isReopenMode ? 'reopenEngagementBreadcrumbs' : 'createEngagementsBreadcrumbs', formatMessage, engagementId)}
      navigation={<ServiceNavigation />}
      navigationOpen={navigationOpen}
      onNavigationChange={event => setNavigationOpen(event.detail.open)}
      toolsOpen={toolsOpen}
      tools={toolsTopics(toolsTopic, formatMessage)}
      onToolsChange={event => setToolsOpen(event.detail.open)}
      content={content}
      headerSelector='#h'
      notifications={<Flashbar items={items} />}
    />
  );
}

export const LnaCreationForm = () => <LnaForm isEditMode={false} isReopenMode={false} />;
export const LnaEditForm = () => <LnaForm isEditMode={true} isReopenMode={false} />;
export const LnaReopenForm = () => <LnaForm isEditMode={false} isReopenMode={true} />;
