import {
  Button,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { deleteNotification, showNotification } from '../../../redux/reducers/notificationReducer';
import { useEffect, useState } from 'react';

import { ApiHookStatuses } from '../../../interfaces';
import { CounterLink } from '../../../common/counterLink';
import { UUID } from '../../../util/uuid';
import { ValueWithLabel } from '../../../common/value-with-label';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';
import { useDownloadResponse } from '../../../services/api/engagements-hook';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

export interface IEngagementResponseCount {
  percentCompleted: string;
  inProgress: number;
  completed: number;
  participantsNumber: number;
  engagementStatus: string;
}

const EngagementResponsesComponent = (props: IEngagementResponseCount) => {
  const { percentCompleted, inProgress, participantsNumber, completed, engagementStatus } = props;

  const { mutate, status, data, error } = useDownloadResponse();
  const { engagementId = '' } = useParams();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();

  const downloadResponseHandler = () => {
    mutate({
      engagementId
    });
  };

  const isProcessing = engagementStatus === 'Processing'

  /**
   * Handle file download status
   */
  useEffect(() => {
    if (status === ApiHookStatuses.success && data) {
      const { engagementReportUrl } = data;
      setLoading(false);
      setDisabled(false);
      fetch(engagementReportUrl)
      .then(res => res.blob())
      .then((blob) => {
        const name = data.engagementName;
        const date = dayjs(new Date(), 'YYYY-MM-DD');
        saveAs(blob, `${name}_${date}.csv`);
        dispatch(deleteNotification());
        dispatch(showNotification({
          type: 'success',
          dismissible: true,
          content: formatMessage({ id: 'downloadSuccess' }),
          id: UUID()
        }));
      })
    } else if (status === ApiHookStatuses.loading) {
      setLoading(true);
      setDisabled(true);
    } else if (status === ApiHookStatuses.error && error) {
      setLoading(false);
      setDisabled(false);
      dispatch(deleteNotification());
      dispatch(showNotification({
        type: 'error',
        dismissible: true,
        headerText: formatMessage({ id: 'downloadFailed' }),
        content: error.message,
        id: UUID()
      }));
    }
  }, [status]);

  return (
    <Container
      header={
        <Header
          variant='h2'
          actions={
            <SpaceBetween
              direction='horizontal'
              size='xs'
            >
              <Button loading={loading} disabled={disabled || isProcessing} onClick={downloadResponseHandler}>{formatMessage({ id: 'downloadRes' })}</Button>
            </SpaceBetween>
          }
        >
          {formatMessage({ id: 'engagementResponse' })}
        </Header>
      }
    >
      <ColumnLayout
        columns={4}
        variant='text-grid'
      >
        <SpaceBetween size='l'>
          <ValueWithLabel label={formatMessage({ id: 'percentComplete'})}>
            <CounterLink isClickable={false}>{percentCompleted}</CounterLink>
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size='l'>
          <ValueWithLabel label={formatMessage({ id: 'inProgressRes' })}>
            <CounterLink isClickable={false}>{inProgress}</CounterLink>
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size='l'>
          <ValueWithLabel label={formatMessage({ id: 'completeRes' })}>
            <CounterLink isClickable={false}>{completed}</CounterLink>
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size='l'>
          <ValueWithLabel label={formatMessage({ id: 'estimatedNumber' })}>
            <CounterLink isClickable={false}>{participantsNumber}</CounterLink>
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

export default EngagementResponsesComponent;
