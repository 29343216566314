import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Main from './main';
import { Provider } from 'react-redux';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import store from '../redux/store';
import { useEffect } from 'react';

/**
 *
 * The defaultOptions object contains settings that will be applied to all queries managed by this QueryClient. Specifically, we're configuring the following settings within the queries object:
 *  refetchOnWindowFocus: When set to false, React Query won't automatically refetch data when the user switches back to the app's window after it was out of focus. By default, this option is true.
 *  refetchOnMount: When set to false, React Query won't automatically refetch data on component mount across all instances of the same query. By default, it only refetches if the data is stale (based on cache time).
 *  refetchOnReconnect: When set to false, React Query won't automatically refetch data when the browser regains a network connection. By default, this option is true.
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  }
});

// Class App is the 'output' generated on every build,
// it is what you will see on the webpage.
export default function App() {
  // Create a client


  useEffect(() => {
    const getFacData = async () => {
      const res = await fetch('/fac.json', {
        method: 'GET',
        mode: 'cors',
      });
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const data = await res.json();
      const meta = document.createElement('meta');
      meta.name = 'fac';
      meta.content = JSON.stringify(data);
      document.head.appendChild(meta);
      return data;
    };

    getFacData().catch(console.error);
  }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Main />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
}
