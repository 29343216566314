import { Accounts, ApiHookStatuses } from '../../../interfaces';
import { useEffect, useState } from 'react';

import { ParamsType } from './accountListServerContainer';
import { useGetAccounts } from '../../../services/api/account-hook';

export function useAccounts (params: ParamsType) {
  const { pageSize = 30, currentPageIndex: clientPageIndex, requestMore } = params.pagination;
  const { sortingColumn, sortingDescending } = params.sorting || {};
  const { query } = params.filtering;
  const { filter, must_not, match } = query as any;
  const [items, setItems] = useState<Accounts>([]);
  const [loading, setLoading] = useState(false);
  const initialPageSize = 150;
  const [totalSize, setTotalSize] = useState<number>(initialPageSize);

  const [currentPageIndex, setCurrentPageIndex] = useState(clientPageIndex);
  const [pagesCount, setPagesCount] = useState(1);
  console.log(match);

  useEffect(() => {
    setCurrentPageIndex(clientPageIndex);
  }, [clientPageIndex]);

  useEffect(() => {
    const totalPages = Math.round(items.length / pageSize);
    setPagesCount(totalPages);
  }, [items, pageSize]);

  useEffect(() => {
    if ( requestMore ) {
      setTotalSize(pageSize  + totalSize);
    }
  }, [requestMore]);


  const { status: getAccountsStatus, error: getAccountsError, data: getAccountsData } = useGetAccounts({
    filter,
    must_not,
    match,
    size: totalSize,
    sort: sortingColumn?.sortingField !== 'priorityBucket' ? [{ property: sortingColumn?.sortingField, direction: sortingDescending ? 'asc': 'desc' }] : [
      { property: sortingColumn?.sortingField, direction: sortingDescending ? 'asc': 'desc' },
      { property: 'propensityTandcScore', direction: sortingDescending ? 'asc': 'desc' }
    ],
  });

  useEffect(() => {
    if (getAccountsStatus === ApiHookStatuses.loading) { // Set requestArgs.token === '' to prevent the page from refresh list
      setLoading(true);
    }
    if (getAccountsStatus === ApiHookStatuses.error && getAccountsError) {
      setLoading(false);
    }
    if (getAccountsStatus === ApiHookStatuses.success && getAccountsData) {
      setLoading(false);
      setItems(getAccountsData);
    }
  }, [getAccountsStatus]);

  return {
    items,
    loading,
    pagesCount,
    currentPageIndex,
  };
}