import {
  Button,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  Table
} from '@amzn/awsui-components-react';

import EngagementResponsesComponent from './responseComponent';
import { IEngagementDetail } from '../../../interfaces';
import { ValueWithLabel } from '../../../common/value-with-label';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const EngagementDetailComponent = ({
  percentCompleted,
  inProgress,
  completed,
  total,
  accountName,
  sfdcID,
  closeDate,
  type,
  status,
  isIndividual,
  isTest,
  teams,
  engagementId,
  creatorEmail,
  collaborators,
  partnerId,
  partnerName
}: IEngagementDetail) => {

  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const goToEditPage = () => {
    navigate(`/edit-engagement/${engagementId}`)
  };
  const goToReopenPage = () => {
    navigate(`/reopen-engagement/${engagementId}`)
  };

  const Yes = formatMessage({ id: 'yes' });
  const No = formatMessage({ id: 'no' });

  const isActive = status === 'Active';
  const isClosed = status === 'Closed';
  const isProcessing = status === 'Processing'
  // collaborator exists and engagement is internal engagement
  const showCollaborators = sfdcID && collaborators && collaborators?.length > 0;

  return (
    <SpaceBetween size='l'>
      <EngagementResponsesComponent
        percentCompleted={percentCompleted}
        inProgress={inProgress}
        completed={completed}
        participantsNumber={total}
        engagementStatus={status}
      />
      <Container
        header={
          <Header
            variant='h2'
            actions={
              <SpaceBetween
                direction='horizontal'
                size='xs'
              >
                {isActive && (
                    <Button data-test='edit-btn' onClick={goToEditPage}>{formatMessage({ id: 'edit' })}</Button>
                )}
                {isProcessing && (
                    <Button data-test='edit-btn' disabled>{formatMessage({ id: 'edit' })}</Button>
                )}
                {isClosed && (
                    <Button data-test='edit-btn' onClick={goToReopenPage}>{formatMessage({ id: 'reopen' })}</Button>
                )}
              </SpaceBetween>
            }
          >
            {formatMessage({ id: 'engagementDetails' })}
          </Header>
        }
      >
        <ColumnLayout
          columns={4}
          variant='text-grid'
        >
          <SpaceBetween size='l'>
            <ValueWithLabel label={formatMessage({ id: 'accountNameLabel' })}>{accountName}</ValueWithLabel>
            <ValueWithLabel label={formatMessage({ id: 'engagementType' })}>{type}</ValueWithLabel>
            { engagementId && <ValueWithLabel label={formatMessage({ id: 'engagementId' })}>{engagementId}</ValueWithLabel> }
          </SpaceBetween>
          <SpaceBetween size='l'>
            { sfdcID && <ValueWithLabel label={formatMessage({ id: 'sfdcIdLabel' })}>{sfdcID}</ValueWithLabel> }
            { partnerId && <ValueWithLabel label={formatMessage({ id: 'partnerLabel' })}>{partnerId}</ValueWithLabel> }
            { partnerName && <ValueWithLabel label={formatMessage({ id: 'partnerNameLabel' })}>{partnerName}</ValueWithLabel> }
            <ValueWithLabel label={formatMessage({ id: 'individualLabel' })}>
              {isIndividual ? Yes : No}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size='l'>
            <ValueWithLabel label={formatMessage({ id: 'surveyCloseDate' })}>
              {closeDate}
            </ValueWithLabel>
            {
            creatorEmail && (
              <ValueWithLabel label={formatMessage({ id: 'creatorEmail' })}>{creatorEmail}</ValueWithLabel>)
            }
          </SpaceBetween>
          <SpaceBetween size='l'>
            <ValueWithLabel label={formatMessage({ id: 'surveyStatus' })}>{status}</ValueWithLabel>
            <ValueWithLabel label={formatMessage({ id: 'internalTestLabel' })}>
              {isTest ? Yes : No}
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
        <div className='border-top'>
        <ColumnLayout
          borders='horizontal'
          columns={1}
        >
          <Table
            columnDefinitions={[{
              id: 'teamName',
              header: formatMessage({ id: 'teamNames' }),
              cell: (item) => item
            }]}
            items={teams}
            variant='embedded'
            className='team-name-table'
          />
        </ColumnLayout>
        { showCollaborators && <ColumnLayout
          borders='horizontal'
          columns={1}
        >
          <Table
            columnDefinitions={[
              {
                id: 'collaborators',
                header: formatMessage({ id: 'collaborators' }),
                cell: (item) => item
              }
            ]}
            items={collaborators}
            variant='embedded'
          />
        </ColumnLayout>}
        </div>
      </Container>
    </SpaceBetween>
  );
};

export default EngagementDetailComponent;
