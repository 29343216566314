import { SegmentedControl } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { useState } from 'react';

const DashboardComponent = () => {
  const { formatMessage } = useIntl();
  const [selectedId, setSelectedId] = useState('dashboard1');
  return (
    <div className='dashboard-view'>
      <SegmentedControl
        selectedId={selectedId}
        onChange={({ detail }) => setSelectedId(detail.selectedId)}
        label='Dashboard view control'
        options={[
          { text: formatMessage({ id: 'dashboard1' }), id: 'dashboard1' },
          { text: formatMessage({ id: 'dashboard2' }), id: 'dashboard2' },
          { text: formatMessage({ id: 'dashboard5' }), id: 'dashboard5' },
          { text: formatMessage({ id: 'dashboard6' }), id: 'dashboard6' },
        ]}
      />
      {selectedId === 'dashboard1' && (
        <>
          <h2>{formatMessage({ id: 'dashboard1' })}</h2>
          <iframe src='https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/764946308314/dashboards/65af02c6-6c76-4fbb-a5b4-68415ec3b7cb?directory_alias=amazonbi'></iframe>
        </>
      )}
      {selectedId === 'dashboard2' && (
        <>
          <h2>{formatMessage({ id: 'dashboard2' })}</h2>
          <iframe src='https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/764946308314/dashboards/0a89a356-38f4-4db9-b25d-7cde68052d64?directory_alias=amazonbi'></iframe>
        </>
      )}
      {selectedId === 'dashboard5' && (
        <>
          <h2>{formatMessage({ id: 'dashboard5' })}</h2>
          <iframe src='https://tableau.atlas.training.aws.dev/t/prod/views/GoalTrackers/BDMPerformance?:showAppBanner=false&:showVizHome=no&:embed=true&:origin=viz_share_link&:showshareoptions=false&:toolbar=false'></iframe>
        </>
      )}
      {selectedId === 'dashboard6' && (
        <>
          <h2>{formatMessage({ id: 'dashboard6' })}</h2>
          <iframe src='https://tableau.atlas.training.aws.dev/t/prod/views/AWSTCUnscheduledClasses/Classeslist?:showAppBanner=false&:showVizHome=no&:embed=true&:origin=viz_share_link&:showshareoptions=false&:toolbar=false'></iframe>
        </>
      )}
    </div>
  );
};

export default DashboardComponent;
