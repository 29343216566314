import {
  TopNavigation,
  TopNavigationProps,
} from '@amzn/awsui-components-react';

import Auth from '@aws-amplify/auth';
import { Cache } from '@aws-amplify/cache';
import { changeLanguage } from '../../redux/reducers/languageReducer';
import { displaySip } from '../../util/displaySip';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useState } from 'react';

const identity = {
  href: '',
  logo: {
    src: 'https://a0.awsstatic.com/libra-css/images/logos/aws_smile-header-desktop-en-white_59x35@2x.png',
  },
  title: 'Learning Needs Analysis',
};

const i18nStrings = {
  searchIconAriaLabel: 'Search',
  searchDismissIconAriaLabel: 'Close search',
  overflowMenuTriggerText: 'More',
  overflowMenuTitleText: 'All',
  overflowMenuBackIconAriaLabel: 'Back',
  overflowMenuDismissIconAriaLabel: 'Close menu',
};
type ObjectKey = keyof typeof localMap;

const localMap = {
  en: 'English (US)',
  zh_CN: '中文 (简体)',
  zh_TW: '中文 (繁体)',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  ja: '日本語',
  ko: '한국인',
  pt_BR: 'Português',
  de: 'Deutsch'
};

const LNATopNavigation = () => {
  const dispatch = useDispatch();
  const [localeText, setLocaleText] = useState<string>('English (US)');
  const { formatMessage } = useIntl();
  const userProfile = Cache.getItem('userProfile') || {};
  const fullName = userProfile.fullName || '';
  const email = userProfile.email || '';

  const loggedInfo = formatMessage({id: 'userLoginText'}, { name: `${fullName}`});
  const logoutText = formatMessage({ id: 'signOut' });

  const displaySIP = displaySip();

  if (displaySIP) {
    identity.title = formatMessage({ id: 'sip-header' });
  } else {
    identity.title = formatMessage({ id: 'lna' })
  }

  const utilities: ReadonlyArray<TopNavigationProps.Utility> = [
    {
      type: 'button',
      iconName: 'status-info',
      title: 'Notification',
      ariaLabel: 'Notifications (unread)',
    },
    {
      type: 'menu-dropdown',
      text: localeText,
      description: 'Language selection',
      items: [
        { id: 'en', text: 'English (US)' },
        { id: 'zh_CN', text: '中文 (简体)' },
        { id: 'zh_TW', text: '中文 (繁体)' },
        { id: 'es', text: 'Español' },
        { id: 'de', text: 'Deutsch' },
        { id: 'fr', text: 'Français' },
        { id: 'it', text: 'Italiano' },
        { id: 'ja', text: '日本語' },
        { id: 'ko', text: '한국인' },
        { id: 'pt_BR', text: 'Português' }
      ],
      onItemClick: ({ detail }) => {
        const id = detail.id as ObjectKey;
        const text = localMap[id];
        setLocaleText(text);
        dispatch(changeLanguage(id));
      },
    },
    {
      type: 'menu-dropdown',
      text: loggedInfo,
      items: [
        { id: 'userName', text: fullName },
        { id: 'email', text: email },
        { id: 'logout', text: logoutText }
      ],
      onItemClick: ({ detail }) => {
        const id = detail.id;
        if (id === 'logout') {
          Auth.signOut();
        }
      }
    },
  ];
  return (
    <TopNavigation
      identity={identity}
      i18nStrings={i18nStrings}
      utilities={utilities}
    />
  );
};

export default LNATopNavigation;
