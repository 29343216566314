import {
  Alert,
  Button,
  ColumnLayout,
  ContentLayout,
  ExpandableSection,
  Header,
  Link,
  SpaceBetween,
} from '@amzn/awsui-components-react';

import { IAccount } from '../../../interfaces';
import { InfoLink } from '../../../common/common-components';
import SpinWhileComponent from '../../spinWhile';
import { ValueWithLabel } from '../../../common/value-with-label';
import { useIntl } from 'react-intl';

export type AccountDetailsProps = {
  loading: boolean;
  data?: IAccount;
  fetchError?: string;
  refetch: any;
};

const AccountDetailsComponent = (props: AccountDetailsProps) => {
  const { loading, data, fetchError, refetch } = props;
  const { formatMessage } = useIntl();

  const refetchHandler = () => refetch();

  const Yes = formatMessage({ id: 'yes' });
  const No = formatMessage({ id: 'no' });

  return (
    <>
      {fetchError && (
        <>
          <Alert
            type='error'
            action={
              <Button onClick={refetchHandler}>
                {formatMessage({ id: 'retry' })}
              </Button>
            }
          >
            {'' + fetchError}
          </Alert>
        </>
      )}
      {!fetchError && data && (
        <ContentLayout
          header={
            <SpinWhileComponent
              loading={loading}
              loadingText={formatMessage({ id: 'loadingAccountDetails' })}
              size='large'
              align='center'
            >
              <Header
                variant='h1'
                info={
                  <InfoLink
                    id='form-main-info-link'
                    onFollow={() => ({})}
                  />
                }
                description={formatMessage({
                  id: 'accountAccountDetails',
                })}
              >
                {data.salesforceAccountName}
              </Header>
            </SpinWhileComponent>
          }
        >
          <SpaceBetween size='l'>
            <ExpandableSection
              variant='container'
              headerText={formatMessage({ id: 'salesforceSectionHeader' })}
              defaultExpanded
            >
              <ColumnLayout
                columns={4}
                variant='text-grid'
              >
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'salesforceAccountId' })}
                  >
                    <Link target={"_blank"} href={`https://aws-crm.lightning.force.com/lightning/r/Account/${data.salesforceAccountId}/view`}>
                      {data.salesforceAccountId}
                    </Link>
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'salesforceAccountName' })}
                  >
                    {data.salesforceAccountName}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'sfUltimateParentId' })}
                  >
                    {data.sfUltimateParentId}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'gtmCustomerId' })}
                  >
                    {data.gtmCustomerId}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'gtmCustomerName' })}
                  >
                    {data.gtmCustomerName}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'awsAccountPhase' })}
                  >
                    {data.awsAccountPhase}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'awsGreenfieldStatus' })}
                  >
                    {data.awsGreenfieldStatus}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'tandcBdmBdm' })}
                  >
                    {data.tandcBdmBdm}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'tandcBdmBdmLeader' })}
                  >
                    {data.tandcBdmBdmLeader}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'awsAccountManagerAm' })}
                  >
                    {data.awsAccountManagerAm}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'tandcBdmContactEmail' })}
                  >
                    {data.tandcBdmContactEmail}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'tandcBdmLeaderContactEmail' })}
                  >
                    {data.tandcBdmLeaderContactEmail}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'priorityBucket' })}
                  >
                    {data.priorityBucket}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'accountPriScoreDescription' })}>
                    {data.priorityBucketDescription}
                  </ValueWithLabel>
                </SpaceBetween>
              </ColumnLayout>
            </ExpandableSection>
            <ExpandableSection
              variant='container'
              headerText={formatMessage({ id: 'territory' })}
              defaultExpanded
            >
              <ColumnLayout
                columns={4}
                variant='text-grid'
              >
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'businessUnit' })}>
                    {data.businessUnit}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'segment' })}>
                    {data.segment}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'subSegment' })}>
                    {data.subSegment}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'industry' })}>
                    {data.industry}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'geo' })}>
                    {data.geo}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'region' })}>
                    {data.region}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'subRegion' })}>
                    {data.subRegion}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'firstBillingCountry' })}>
                    {data.firstBillingCountry}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'territory' })}>
                    {data.territory}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'territoryId' })}>
                    {data.territoryId}
                  </ValueWithLabel>
                </SpaceBetween>
              </ColumnLayout>
            </ExpandableSection>
            <ExpandableSection
              variant='container'
              headerText={formatMessage({ id: 'salesInfo' })}
              defaultExpanded
            >
              <ColumnLayout
                columns={4}
                variant='text-grid'
              >
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'revBandAwsSalesT12M' })}>
                    {data.revBandAwsSalesT12M}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'tandcPhase' })}>
                    {data.tandcPhase}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'isEdp' })}>
                    {data.isEdp ? Yes : No}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'isMap' })}>
                    {data.isMap ? Yes : No}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'isHighPropensityPrivateIlt' })}>
                    {data.isHighPropensityPrivateIlt ? Yes : No}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'isPenetrated' })}>
                    {data.isPenetrated ? Yes : No}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'isApjDeep' })}>
                    {data.isApjDeep ? Yes : No}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'isLatamDeep' })}>
                    {data.isLatamDeep}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'isProservePriority' })}>
                    {data.isProservePriority ? Yes : No}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'isAwsEngagedCustomer' })}>
                    {data.isAwsEngagedCustomer ? Yes : No}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'isAttachedByAnyTMode' })}>
                    {data.isAttachedByAnyTMode ? Yes : No}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'penetrationTarget' })}>
                    {data.penetrationTarget}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'attTotalAddrSpendTandC' })}>
                    {data.attTotalAddrSpendTandC}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'revAwsSales12M' })}>
                    {data.revAwsSales12M}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'revTandcBilledT12M' })}>
                    {data.revTandcBilledT12M}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'totalAddressableSpend' })}>
                    {data.totalAddressableSpend}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'attachmentExclPartnerT' })}>
                    {data.attachmentExclPartnerT}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'attachment' })}>
                    {data.attachment}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'propensityCtfRank' })}>
                    {data.propensityCtfRank}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'propensityTandcRank' })}>
                    {data.propensityTandcRank}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'propensitySbtsRank' })}>
                    {data.propensitySbtsRank}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel label={formatMessage({ id: 'propensityPrivateIltScoreRank' })}>
                    {data.propensityPrivateIltScoreRank}
                  </ValueWithLabel>
                </SpaceBetween>
              </ColumnLayout>
            </ExpandableSection>
            <ExpandableSection
              variant='container'
              headerText={formatMessage({ id: 'learners' })}
              defaultExpanded
            >
              <ColumnLayout
                columns={4}
                variant='text-grid'
              >
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'totalAddrLearnersEstimate' })}
                  >
                    {data.totalAddrLearnersEstimate}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'uniqueIndCertifications' })}
                  >
                    {data.uniqueIndCertifications}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'uniqueIndPrivateIlt' })}
                  >
                    {data.uniqueIndPrivateIlt}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'uniqueIndPublicIlt' })}
                  >
                    {data.uniqueIndPublicIlt}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'uniqueIndChannelIlt' })}
                  >
                    {data.uniqueIndChannelIlt}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'uniqueIndDigital' })}
                  >
                    {data.uniqueIndDigital}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'uniqueIndTOrC' })}
                  >
                    {data.uniqueIndTOrC}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'uniqueIndExclPartnerT' })}
                  >
                    {data.uniqueIndExclPartnerT}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'uniqueIndAnyIlt' })}
                  >
                    {data.uniqueIndAnyIlt}
                  </ValueWithLabel>
                </SpaceBetween>
              </ColumnLayout>
            </ExpandableSection>
            <ExpandableSection
              variant='container'
              headerText={formatMessage({ id: 'tNCConsumption' })}
              defaultExpanded
            >
              <ColumnLayout
                columns={4}
                variant='text-grid'
              >
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'totalConsumptionTOrC' })}
                  >
                    {data.totalConsumptionTOrC}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'trainingsDigital' })}
                  >
                    {data.trainingsDigital}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'trainingsAnyIlt' })}
                  >
                    {data.trainingsAnyIlt}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'trainingsPrivateIlt' })}
                  >
                    {data.trainingsPrivateIlt}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'trainingsPublicIlt' })}
                  >
                    {data.trainingsPublicIlt}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'trainingsChannelIlt' })}
                  >
                    {data.trainingsChannelIlt}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size='l'>
                  <ValueWithLabel
                    label={formatMessage({ id: 'certificationsAwarded' })}
                  >
                    {data.certificationsAwarded}
                  </ValueWithLabel>
                </SpaceBetween>
              </ColumnLayout>
            </ExpandableSection>
          </SpaceBetween>
        </ContentLayout>
      )}
    </>
  );
};

export default AccountDetailsComponent;
