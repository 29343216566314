import { Accounts, IAccount } from '../../../interfaces';
import {
  Button,
  CollectionPreferencesProps,
  Pagination,
  PaginationProps,
  PropertyFilter,
  PropertyFilterProps,
  Table,
  TableProps,
} from '@amzn/awsui-components-react';
import {
  DEFAULT_PREFERENCES,
  Preferences,
  getTableConfig,
} from './table-config';
import convertTokensToQuery, { Token } from '../../../util/query-convert';
import { useEffect, useState } from 'react';

import AccountsHeader from './accountsHeader';
import { EmptyState } from '../../../common/table-config';
import { NonCancelableCustomEvent } from '@amzn/awsui-components-react/polaris/internal/events';
import { queryClient } from '../../App';
import { useAccounts } from './useAccounts';
import { useIntl } from 'react-intl';
import { useLocalStorage } from '../../../common/use-local-storage';

export type ServerSideTableProps<T = any> = {
  columnDefinitions: TableProps.ColumnDefinition<T>[];
};

export type ParamsType = {
  pagination: {
    currentPageIndex: number;
    pageSize: number | undefined;
    requestMore: number;
  };
  sorting: {
    sortingColumn: TableProps.ColumnDefinition<IAccount>;
    sortingDescending: boolean;
  };
  filtering: {
    query: PropertyFilterProps.Query | any;
  };
};

const ServerSideAccountTable = (props: ServerSideTableProps) => {
  const { columnDefinitions } = props;
  const { formatMessage } = useIntl();

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [preferences, setPreferences] =
    useLocalStorage<CollectionPreferencesProps.Preferences>(
      'React-ServerSideTable-Preferences',
      DEFAULT_PREFERENCES
    );
  const [requestMore, setRequestMore] = useState(0);
  const { pageSize } = preferences;
  const tableConfig = getTableConfig(formatMessage);
  const [sortingColumn, setSortingColumn] = useState<
    TableProps.ColumnDefinition<IAccount>
  >(columnDefinitions[1]); // Set the current sorting column, default to sort by priority score
  const [descendingSorting, setDescendingSorting] = useState<boolean>(false);
  const [query, setQuery] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: 'and',
  });
  const [queryRequest, setQueryRequest] = useState({
    filter: [],
    must_not: [],
    match: []
  });
  const userData = queryClient.getQueryData(['getUserRole']) as any;
  const roles = userData.roles;
  const loginCacheUserProfile = localStorage.getItem('aws-amplify-cacheuserProfile');
  let email: string = '';
  if (loginCacheUserProfile) {
    email = JSON.parse(loginCacheUserProfile).data.email;
  }
  let defaultAssignToMe = true;
  let properTyKey = '';
    if (roles.indexOf('sip-user') >= 0) {
      properTyKey = 'tandcBdmAlias';
    } else if (roles.indexOf('sip-manager') >= 0 || roles.indexOf('sip-admin') >= 0) {
      properTyKey = 'tandcBdmLeaderAlias';
      if (roles.indexOf('sip-admin') >= 0) {
        defaultAssignToMe = false;
      }
    }
  const alias = email.split('@')[0];
  const [assignToMe, setAssignToMe] = useState<boolean>(defaultAssignToMe);

  const params: ParamsType = {
    pagination: {
      currentPageIndex,
      pageSize,
      requestMore,
    },
    sorting: {
      sortingColumn,
      sortingDescending: descendingSorting,
    },
    filtering: {
      query: queryRequest
    },
  };

  const {
    items,
    loading,
    pagesCount,
    currentPageIndex: serverPageIndex,
  } = useAccounts(params);

  const [currentPageItems, setCurrentPageItems] = useState<Accounts>([]);

  /**
   * @todo There is a small bug that when you switch pageSize from 30 to 50 or 10 to 50, the slice array item will have error
   * Because the currentPageIndex remains the old value which is larger
   */
  useEffect(() => {
    if (pageSize) {
      const startIndex = (currentPageIndex - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      setCurrentPageItems(items.slice(startIndex, endIndex));
    }
  }, [currentPageIndex, pageSize, items]);

  const onSortingChange = (event: NonCancelableCustomEvent<any>) => {
    setDescendingSorting(event.detail.isDescending);
    setSortingColumn(event.detail.sortingColumn);
  };

  const onPaginationChange = (
    event: NonCancelableCustomEvent<PaginationProps.ChangeDetail>
  ) => {
    setCurrentPageIndex(event.detail.currentPageIndex);
  };

  const onPropertyFilterChange = (
    event: NonCancelableCustomEvent<PropertyFilterProps.Query>
  ) => {
    if (event.detail.tokens.some(token => token.operator === '=' && token.propertyKey === properTyKey && token.value === alias)) {
      setAssignToMe(true);
    } else {
      setAssignToMe(false);
    }
    setQuery(event.detail);
  };

  const clearFilterChange = () => {
    setAssignToMe(false);
    setQuery({
      tokens: [],
      operation: 'and'
    });
  }

  useEffect(() => {
    const tokens = query.tokens as Token[];
    const complexMixedQuery = convertTokensToQuery(tokens) as any;
    setQueryRequest(complexMixedQuery);
  }, [query]);

  useEffect(() => {
    let tokens = query.tokens as Token[];

    if (assignToMe && email && properTyKey) {
      tokens.push({
        operator: '=',
        propertyKey: properTyKey,
        value: alias
      })
    } else {
      tokens = tokens.filter(token => !(token.operator === '=' && token.propertyKey === properTyKey && token.value === alias));
    }
    setQuery({tokens: tokens, operation: 'and'});
  }, [assignToMe]);



  return (
    <Table
      loading={loading}
      loadingText={formatMessage({ id: 'loadingResources' })}
      items={currentPageItems}
      columnDefinitions={columnDefinitions}
      columnDisplay={preferences.contentDisplay}
      variant='full-page'
      sortingColumn={sortingColumn}
      sortingDescending={descendingSorting}
      onSortingChange={onSortingChange}
      header={<AccountsHeader count={items.length} assignedToMe={assignToMe} callbackToParent={setAssignToMe} />}
      empty={
        <EmptyState
          title={formatMessage({ id: 'noMatch' })}
          subtitle={formatMessage({ id: 'noMatchSub' })}
          action={<Button onClick={clearFilterChange}>{formatMessage({ id: 'clearFilter' })}</Button>}
        />
      }
      filter={
        <PropertyFilter
          i18nStrings={tableConfig.propertyFilteringI18nConstants}
          query={query}
          onChange={onPropertyFilterChange}
          filteringProperties={tableConfig.filteringProperties}
        />
      }
      pagination={
        <Pagination
          pagesCount={pagesCount}
          currentPageIndex={serverPageIndex}
          onChange={onPaginationChange}
          onNextPageClick={(event) => {
            if (event.detail.requestedPageAvailable === false) {
              setRequestMore(requestMore + 1);
            } else {
              setRequestMore(0);
            }
          }}
          disabled={loading}
          openEnd={true}
        />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          pageSizeOptions={tableConfig.pageSizeOptions}
          contentDisplayOptions={tableConfig.contentDisplayOptions}
        />
      }
    />
  );
};

export default ServerSideAccountTable;
