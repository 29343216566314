import {
  Alert,
  Button,
  CollectionPreferences,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import { deleteNotification, showNotification } from '../../../../redux/reducers/notificationReducer';
import { useEffect, useState } from 'react';

import { ApiHookStatuses } from '../../../../interfaces';
import DeleteUserModal from '../../../../modals/deleteUserModal';
import EditUserModal from '../../../../modals/editUserModal';
import { EmptyState } from '../../../../common/table-config';
import { UUID } from '../../../../util/uuid';
import UserListHeader from './userListHeader';
import { getMatchesCountText } from '../../../../util/get-match-count';
import { getUserListTableConfig } from './table-config';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useRemovePermission } from '../../../../services/api/user-hook';

export type UserListType = { partnerId: string, partnerName: string, userId: string, roles: string[] };

export interface IUserListProps {
  users: Array<UserListType>;
  loading: boolean;
  fetchError?: any;
}

const UserListComponent = ({ users, loading, fetchError }: IUserListProps) => {
  const { formatMessage } = useIntl();
  const count = users.length || 0;
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const [partnerId, setPartnerId] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const [userRole, setUserRole] = useState<string[]>([]);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const { data: deleteData, status: deleteStatus, error: deleteError, mutate: deleteUser } = useRemovePermission();

  useEffect(() => {
    if (deleteStatus === ApiHookStatuses.loading) {
      setDeleteLoading(true);
    } else if (deleteStatus === ApiHookStatuses.error) {
      setDeleteLoading(false);
      setModalVisibility(false);
      setDeleteModalVisibility(false);
      dispatch(deleteNotification());
      dispatch(showNotification({
        type: 'error',
        dismissible: true,
        content: deleteError.message,
        id: UUID()
      }));
    } else if (deleteStatus === ApiHookStatuses.success) {
      setDeleteLoading(false);
      setModalVisibility(false);
      setDeleteModalVisibility(false);
      if (deleteData) { //  In Incoginto mode in Chrome, the backend will send back undefined response and will consider a 400 error as success, so to be safe, we want to only show delete success message when there is a deleteData
        dispatch(showNotification({
          type: 'success',
          dismissible: true,
          content: formatMessage({ id: 'deleteUserSuccess' }, { userId: deleteData.userId }),
          id: UUID()
        }));
      } else if (!deleteData) { // If the deleteStatus is succeed and there is no deleteData, it means backend send back undefined and status as succeed, we need to treate this as failed
        dispatch(showNotification({
          type: 'error',
          dismissible: true,
          content: formatMessage({ id: 'deleteUserFailed' }),
          id: UUID()
        }));
      }
    }
  }, [deleteStatus]);

  const closeModal = () => {
    setEmail('');
    setPartnerId('');
    setPartnerName('')
    setUserRole([]);
    setModalVisibility(false);
  };

  const deleteModalDismissHandler = () => {
    setModalVisibility(true);
    setDeleteModalVisibility(false);
  };

  const openDeleteModal = () => {
    setModalVisibility(false);
    setDeleteModalVisibility(true);
  };

  const deleteUserHandler = () => {
    deleteUser({ userId: email });
  };

  const openEditUserModal = (email?: string, partnerId?: string, partnerName?: string, roles?: string[]) => {
    if (email) {
      setEmail(email);
    } else {
      setEmail('');
    }
    if (partnerId) {
      setPartnerId(partnerId);
    } else {
      setPartnerId('');
    }
    if (partnerName) {
      setPartnerName(partnerName);
    } else {
      setPartnerName('');
    }
    if (roles) {
      setUserRole(roles);
    } else {
      setUserRole([]);
    }
    setModalVisibility(true);
  };

  const tableConfigs = getUserListTableConfig(formatMessage, openEditUserModal);
  const [preferences, setPreferences] = useState(
    tableConfigs.defaultPreferences
  );

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(users || [], {
    pagination: {
      pageSize: preferences.pageSize,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'email',
        },
        isDescending: true,
      },
    },
    propertyFiltering: {
      filteringProperties: tableConfigs.filteringProperties,
      empty: (
        <EmptyState
          title={formatMessage({ id: 'noUsers' })}
          subtitle={formatMessage({ id: 'noUsersSub' })}
          action={
            <Button
              variant='primary'
              onClick={() => openEditUserModal()}
            >
              {formatMessage({ id: 'addUser' })}
            </Button>
          }
        />
      ),
      noMatch: (
        <EmptyState
          title={formatMessage({ id: 'noMatch' })}
          subtitle={formatMessage({ id: 'noMatchSub' })}
          action={
            <Button
              onClick={() =>
                actions.setPropertyFiltering({ tokens: [], operation: 'and' })
              }
            >
              {formatMessage({ id: 'clearFilter' })}
            </Button>
          }
        />
      ),
    },
  });

  return (
    <>
      <SpaceBetween size='m'>
        {fetchError && <Alert type='error'>{fetchError}</Alert>}
        {!fetchError && (
          <Table
            {...collectionProps}
            visibleColumns={preferences.visibleContent}
            wrapLines={preferences.wrapLines}
            loading={loading}
            loadingText={formatMessage({ id: 'loadingResources' })}
            resizableColumns
            items={items}
            variant='full-page'
            columnDefinitions={tableConfigs.columnDefinitions}
            header={<UserListHeader count={count} onAddUserHandler={() => openEditUserModal()} />}
            pagination={
              <Pagination
                {...paginationProps}
                ariaLabels={tableConfigs.paginationLabels}
              />
            }
            filter={
              <PropertyFilter
                {...propertyFilterProps}
                i18nStrings={tableConfigs.propertyFilteringI18nConstants}
                countText={getMatchesCountText(
                  filteredItemsCount,
                  formatMessage
                )}
                expandToViewport={true}
              />
            }
            preferences={
              <CollectionPreferences
                preferences={preferences}
                onConfirm={(event) => setPreferences(event.detail)}
                title={formatMessage({ id: 'preferences' })}
                confirmLabel={formatMessage({ id: 'confirm' })}
                cancelLabel={formatMessage({ id: 'cancel' })}
                visibleContentPreference={{
                  title: formatMessage({ id: 'selectVisibleColumns' }),
                  options: tableConfigs.contentSelectorOptions,
                }}
                pageSizePreference={{
                  title: formatMessage({ id: 'pageSize' }),
                  options: tableConfigs.pageSelectorOptions,
                }}
              />
            }
          ></Table>
        )}
      </SpaceBetween>
      <EditUserModal
        visible={modalVisibility}
        closeModal={closeModal}
        email={email}
        partnerId={partnerId}
        partnerName={partnerName}
        roles={userRole}
        onDeleteUserHandler={openDeleteModal}
      />
      <DeleteUserModal
        visible={deleteModalVisibility}
        onDismissHandler={deleteModalDismissHandler}
        onCancelHandler={deleteModalDismissHandler}
        onConfirmHandler={deleteUserHandler}
        isLoading={deleteLoading}
      />
    </>
  );
};

export default UserListComponent;
