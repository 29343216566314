import {
  AddPermissionsRequest,
  UpdateUserPermissionsRequest,
} from '../services/DAO/userDAO';
import {
  Alert,
  Box,
  Button,
  FormField,
  Input,
  Modal,
  NonCancelableCustomEvent,
  RadioGroup,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import {
  deleteNotification,
  showNotification,
} from '../redux/reducers/notificationReducer';
import {
  useAddPermission,
  useUpdatePermission,
} from '../services/api/user-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ApiHookStatuses } from '../interfaces';
import { BaseChangeDetail } from '@amzn/awsui-components-react/polaris/input/interfaces';
import { GlobalState } from '../redux/reducers';
import { UUID } from '../util/uuid';
import { displaySip } from '../util/displaySip';
import { encodedStr } from '../util/html-encoding';
import { isEmailValid } from '../util/isValidEmail';
import { isValidPartnerId } from '../util/isPartnerIdValid';
import { isValidPartnerName } from '../util/isPartnerNameValid';
import { useIntl } from 'react-intl';

type EditUserModalProps = {
  visible: boolean;
  closeModal: () => void;
  onDeleteUserHandler: () => void;
  email?: string;
  roles?: string[];
  partnerId?: string;
  partnerName?: string;
};

const EditUserModal = (props: EditUserModalProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const displaySIP = displaySip();
  const initFormError = {
    email: '',
    partnerId: '',
    partnerName: '',
    userRole: '',
  };
  const {
    visible,
    closeModal,
    onDeleteUserHandler,
    email = '',
    roles = [],
    partnerId = '',
    partnerName = '',
  } = props;
  const [emailAddress, setEmailAddress] = useState<string>(email);
  const [selectedLnaRole, setSelectedLnaRole] = useState<string[]>(roles.filter(( val) => val.indexOf('lna') >= 0));
  const [selectedSipRole, setSelectedSipRole] = useState<string[]>(roles.filter(( val) => val.indexOf('sip') >= 0));
  const [partner, setPartner] = useState<string>(partnerId);
  const [partnerNameVal, setPartnerName] = useState<string>(partnerName);

  const {
    mutate: updateUser,
    data: updateData,
    status: updateStatus,
    error: updateError,
  } = useUpdatePermission();
  const {
    mutate: addUser,
    data: addData,
    status: addStatus,
    error: addError,
  } = useAddPermission();
  const [formError, setFormError] = useState(initFormError);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<any>();
  const isEditMode = email !== ''; // If we pass email as props, it means it's edit Modal, otherwise it's add

  const loginUser = useSelector<GlobalState, string>(
    (state) => state.user.email || ''
  );
  const displayRemoveButton = isEditMode && email !== loginUser;
  const footConfirmBtnText = isEditMode
    ? formatMessage({ id: 'updateUser' })
    : formatMessage({ id: 'addUser' });

  useEffect(() => {
    setEmailAddress(email);
  }, [email]);

  useEffect(() => {
    setPartner(partnerId);
  }, [partnerId]);

  useEffect(() => {
    setPartnerName(partnerName);
  }, [partnerName]);

  useEffect(() => {
    setSelectedLnaRole(roles.filter(val => val.indexOf('lna') >= 0));
    setSelectedSipRole(roles.filter(val => val.indexOf('sip') >= 0));
  }, [roles]);

  // We need to separate update status and add Status
  // because it will give false condition when we try to display update error, however if we previously have addStatus to be true, the update error won't show
  useEffect(() => {
    if (addStatus === ApiHookStatuses.loading) {
      setIsLoading(true);
      setApiError(undefined);
    }
    if (addStatus === ApiHookStatuses.error) {
      setIsLoading(false);
      dispatch(deleteNotification());
      if (addError) setApiError(addError);
    }
    if (addStatus === ApiHookStatuses.success && addData) {
      setIsLoading(false);
      setApiError(undefined);
      dispatch(
        showNotification({
          type: 'success',
          dismissible: true,
          content: formatMessage(
            { id: 'addUserSuccess' },
            { userId: addData.userId }
          ),
          id: UUID(),
        })
      );
      resetForm();
    }
  }, [addStatus]);

  useEffect(() => {
    if (updateStatus === ApiHookStatuses.loading) {
      setIsLoading(true);
      setApiError(undefined);
    }
    if (updateStatus === ApiHookStatuses.error) {
      setIsLoading(false);
      dispatch(deleteNotification());
      if (updateError) setApiError(updateError);
    }
    if (updateStatus === ApiHookStatuses.success && updateData) {
      setIsLoading(false);
      setApiError(undefined);
      dispatch(
        showNotification({
          type: 'success',
          dismissible: true,
          content: formatMessage(
            { id: 'updateUserSuccess' },
            { userId: updateData.userId }
          ),
          id: UUID(),
        })
      );
      resetForm();
    }
  }, [updateStatus]);

  const emailAddressOnChange = (
    event: NonCancelableCustomEvent<BaseChangeDetail>
  ) => {
    const inputValue = event.detail.value;
    setEmailAddress(inputValue);
    if (isEmailValid(inputValue)) {
      setFormError({ ...formError, email: '' });
    } else {
      setFormError({
        ...formError,
        email: formatMessage({ id: 'notValidEmail' }),
      });
    }
  };

  const partnerOnChange = (
    event: NonCancelableCustomEvent<BaseChangeDetail>
  ) => {
    setPartner(event.detail.value);
    if (isValidPartnerId(event.detail.value)) {
      setFormError({ ...formError, partnerId: '' });
    } else {
      setFormError({
        ...formError,
        partnerId: formatMessage({ id: 'invalidPartnerId' }),
      });
    }
  };

  const partnerNameOnChange = (
    event: NonCancelableCustomEvent<BaseChangeDetail>
  ) => {
    setPartnerName(event.detail.value);
    if (isValidPartnerName(event.detail.value)) {
      setFormError({ ...formError, partnerName: '' });
    } else {
      setFormError({
        ...formError,
        partnerName: formatMessage({ id: 'partnerNameFormatError' }),
      });
    }
  };

  const lnaRoleOnChange = ({ detail }: any) => {
    if (detail.value !== 'lna-atp-user') {
      setPartner('');
      setPartnerName('');
    }
    setSelectedLnaRole([detail.value]);
    setFormError({ ...formError, userRole: '' });
  };

  const sipRoleOnChange = ({ detail}: any) => {
    setSelectedSipRole([detail.value]);
    setFormError({ ...formError, userRole: '' });
  }

  const headerText =
    email === ''
      ? formatMessage({ id: 'addUser' })
      : formatMessage({ id: 'editUser' });
  const displayPartnerInfo =
    selectedLnaRole.some((el) => el === 'lna-atp-user') || partner !== '';

  const submitHandler = () => {
    let flag = false;
    const errorObj: any = {};
    if (emailAddress === '') {
      errorObj.email = formatMessage({ id: 'userEmailEmpty' });
      flag = true;
    } else if (!isEmailValid(emailAddress)) {
      errorObj.email = formatMessage({ id: 'notValidEmail' });
      flag = true;
    }
    if (selectedLnaRole.length === 0) {
      errorObj.userRole = formatMessage({ id: 'userRoleEmpty' });
      flag = true;
    }
    if (displayPartnerInfo && !isValidPartnerId(partner)) {
      errorObj.partnerId = formatMessage({ id: 'invalidPartnerId' });
      flag = true;
    }
    if (displayPartnerInfo && !isValidPartnerName(partnerNameVal)) {
      errorObj.partnerName = formatMessage({ id: 'partnerNameFormatError' });
      flag = true;
    }
    if (flag) {
      setFormError(errorObj);
      return;
    }
    const requestBody: UpdateUserPermissionsRequest | AddPermissionsRequest = {
      userId: encodedStr(emailAddress),
      roles: [...selectedLnaRole.map((item) => encodedStr(item)), ...selectedSipRole.map((item) => encodedStr(item))],
    };
    if (partner !== '') requestBody['partnerId'] = partner;
    if (partnerNameVal !== '')
      requestBody['partnerName'] = encodedStr(partnerNameVal);
    email !== '' ? updateUser(requestBody) : addUser(requestBody);
  };

  const resetForm = () => {
    setSelectedLnaRole([]);
    setSelectedSipRole([]);
    setPartner('');
    setPartnerName('');
    setEmailAddress('');
    setFormError(initFormError);
    setApiError(undefined);
    closeModal();
  };

  const deleteUserHandler = () => {
    onDeleteUserHandler();
  };

  return (
    <Modal
      className='awsui-visual-refresh'
      onDismiss={resetForm}
      visible={visible}
      closeAriaLabel={formatMessage({ id: 'closeModal' })}
      header={headerText}
      footer={
        <ModalFooter
          onConfirmHandler={submitHandler}
          onCancelHandler={resetForm}
          buttonText={footConfirmBtnText}
          isLoading={isLoading}
        />
      }
    >
      <SpaceBetween
        direction='vertical'
        size='l'
      >
        {apiError && (
          <Alert
            statusIconAriaLabel='warning'
            type='warning'
          >
            {apiError.message}
          </Alert>
        )}
        <FormField
          label={formatMessage({ id: 'emailAddress' })}
          description={formatMessage({ id: 'emailAddressDesc' })}
          errorText={formError.email}
        >
          <Input
            value={emailAddress}
            placeholder={formatMessage({ id: 'emailAddressPlaceholder' })}
            onChange={emailAddressOnChange}
            disabled={isEditMode}
          />
        </FormField>
        <FormField
          label={formatMessage({ id: 'userRole' })}
          errorText={formError.userRole}
        >
          {displaySIP && (
            <span>{formatMessage({ id: 'setLnaRoleCheckbox' })}</span>
          )}
          <RadioGroup
            id='lnaRadioGroup'
            data-test='lnaRadioGroup'
            onChange={lnaRoleOnChange}
            value={selectedLnaRole[0]}
            items={[
              {
                value: 'lna-user',
                label: formatMessage({ id: 'lnaUser' }),
                description: formatMessage({ id: 'lnaUserDesc' }),
              },
              {
                value: 'lna-admin',
                label: formatMessage({ id: 'lnaAdmin' }),
                description: formatMessage({ id: 'lnaAdminDesc' }),
              },
              {
                value: 'lna-atp-user',
                label: formatMessage({ id: 'atpUser' }),
                description: formatMessage({ id: 'atpUserDesc' }),
              },
              {
                value: 'lna-atp-admin',
                label: formatMessage({ id: 'atpAdmin' }),
                description: formatMessage({ id: 'atpAdminDesc' }),
              },
            ]}
          />
        </FormField>
        {displayPartnerInfo && (
          <FormField
            label={formatMessage({ id: 'partnerId' })}
            description={formatMessage({ id: 'partnerIdDesc' })}
            errorText={formError.partnerId}
          >
            <Input
              value={partner}
              placeholder={formatMessage({ id: 'partnerIdPlaceholder' })}
              onChange={partnerOnChange}
            />
          </FormField>
        )}
        {displayPartnerInfo && (
          <FormField
            label={formatMessage({ id: 'partnerNameLabel' })}
            description={formatMessage({ id: 'partnerNameDesc' })}
            errorText={formError.partnerName}
          >
            <Input
              value={partnerNameVal}
              placeholder={formatMessage({ id: 'partnerNamePlaceholder' })}
              onChange={partnerNameOnChange}
            />
          </FormField>
        )}
        {displaySIP && (
          <>
            <span>{formatMessage({ id: 'setSipRoleCheckbox' })}</span>
            <RadioGroup
              id='sipRadioGroup'
              data-test='sipRadioGroup'
              onChange={sipRoleOnChange}
              value={selectedSipRole[0]}
              items={[
                {
                  value: 'sip-user',
                  label: formatMessage({ id: 'sipUser' }),
                  description: formatMessage({ id: 'sipUserDesc' }),
                },
                {
                  value: 'sip-manager',
                  label: formatMessage({ id: 'sipManager' }),
                  description: formatMessage({ id: 'sipManagerDesc' }),
                },
                {
                  value: 'sip-admin',
                  label: formatMessage({ id: 'sipAdmin' }),
                  description: formatMessage({ id: 'sipAdminDesc' }),
                }
              ]}
            />
          </>
        )}
        {displayRemoveButton && (
          <Button onClick={deleteUserHandler}>
            {formatMessage({ id: 'deleteUser' })}
          </Button>
        )}
      </SpaceBetween>
    </Modal>
  );
};

const ModalFooter = (props: {
  onConfirmHandler: () => void;
  onCancelHandler: () => void;
  buttonText: string;
  isLoading: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { onConfirmHandler, onCancelHandler, buttonText, isLoading } = props;
  return (
    <Box float='right'>
      <SpaceBetween
        direction='horizontal'
        size='xs'
      >
        <Button
          variant='link'
          onClick={onCancelHandler}
        >
          {formatMessage({ id: 'cancel' })}
        </Button>
        <Button
          variant='primary'
          onClick={onConfirmHandler}
          loading={isLoading}
        >
          {buttonText}
        </Button>
      </SpaceBetween>
    </Box>
  );
};

export default EditUserModal;
