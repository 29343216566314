import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Link,
  TableProps,
} from '@amzn/awsui-components-react';

import { IAccount } from '../../../interfaces';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { useIntl } from 'react-intl';

export type PreferenceType = {
  defaultPreferences: CollectionPreferencesProps.Preferences<any>;
  columnDefinitions: TableProps.ColumnDefinition<IAccount>[];
  filteringProperties: readonly PropertyFilterProperty<any>[];
  propertyFilteringI18nConstants: any;
  paginationLabels: {
    nextPageLabel: any;
    pageLabel: (pageNumber: number) => any;
    previousPageLabel: any;
  };
  pageSizeOptions: CollectionPreferencesProps.PageSizeOption[];
  contentDisplayOptions: CollectionPreferencesProps.ContentDisplayPreference;
};

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 30,
  contentDisplay: [
    { id: 'salesforceAccountId', visible: true },
    { id: 'salesforceAccountName', visible: true },
    { id: 'sfUltimateParentId', visible: true },
    { id: 'tandcBdmBdm', visible: true },
    { id: 'priorityBucket', visible: true },
    { id: 'propensityTandcRank', visible: true },
    { id: 'awsAccountManagerAm', visible: true },
    { id: 'revAwsSales12M', visible: true },
    { id: 'revTandcBilledT12M', visible: true },
    { id: 'totalAddressableSpend', visible: true },
    { id: 'propensityTandcScore', visible: false },
    { id: 'propensityPrivateIltScore', visible: false },
    { id: 'propensityPrivateIltScoreRank', visible: false },
    { id: 'propensitySbtsRank', visible: false },
    { id: 'propensitySbtsScore', visible: false },
    { id: 'propensityCtfRank', visible: false },
    { id: 'propensityCtfScore', visible: false },
    { id: 'tandcBdmContactEmail', visible: false },
    { id: 'segment', visible: false },
    { id: 'geo', visible: false },
    { id: 'industry', visible: false },
    { id: 'estimationMethod', visible: false },
    { id: 'region', visible: false },
    { id: 'subRegion', visible: false },
    { id: 'firstBillingCountry', visible: false },
    { id: 'territory', visible: false },
    { id: 'awsAccountPhase', visible: false },
    { id: 'isAttachedByAnyTMode', visible: false },
    { id: 'awsGreenfieldStatus', visible: false },
    { id: 'isAwsEngagedCustomer', visible: false },
    { id: 'tandcBdmLeaderContactEmail', visible: false },
    { id: 'tandcBdmAlias', visible: false },
    { id: 'tandcBdmLeaderAlias', visible: false },
    { id: 'subSegment', visible: false },
    { id: 'tandcBdmBdmLeader', visible: false },
    { id: 'isMap', visible: false },
    { id: 'uniqueIndCertifications', visible: false },
    { id: 'uniqueIndAnyIlt', visible: false },
    { id: 'uniqueIndPrivateIlt', visible: false },
    { id: 'uniqueIndPublicIlt', visible: false },
    { id: 'uniqueIndChannelIlt', visible: false },
    { id: 'uniqueIndDigital', visible: false },
    { id: 'totalConsumptionTOrC', visible: false },
    { id: 'certificationsAwarded', visible: false },
    { id: 'trainingsAnyIlt', visible: false },
    { id: 'trainingsPrivateIlt', visible: false },
    { id: 'trainingsPublicIlt', visible: false },
    { id: 'trainingsChannelIlt', visible: false },
    { id: 'trainingsDigital', visible: false },
    { id: 'attTotalAddrSpendTandC', visible: false },
    { id: 'attachment', visible: false },
    { id: 'attachmentExclPartnerT', visible: false },
    { id: 'tandcPhase', visible: false },
    { id: 'accountPriScoreDescription', visible: false },
    { id: 'updatedWhen', visible: false },
    { id: 'tableDescription', visible: false },
    { id: 'isEdp', visible: false },
    { id: 'isApjIcon', visible: false },
    { id: 'isApjDeep', visible: false },
    { id: 'isHighPropensityPrivateIlt', visible: false },
    { id: 'isLatamDeep', visible: false },
    { id: 'isProservePriority', visible: false },
    { id: 'estimationMethodRank', visible: false },
    { id: 'revBandAwsSalesT12M', visible: false },
    { id: 'penetrationTarget', visible: false },
    { id: 'isPenetrated', visible: false },
    { id: 'cAndTConsumption', visible: false },
    { id: 'totalAddrLearnersEstimate', visible: false },
    { id: 'uniqueIndTOrC', visible: false },
    { id: 'uniqueIndExclPartnerT', visible: false },
  ],
};

export function getTableConfig(
  formatMessage?: any
): PreferenceType {
  const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<IAccount>[] = [
    {
      id: 'salesforceAccountId',
      header: formatMessage({ id: 'salesforceAccountId' }),
      cell: (item) => (
        <Link
          href={`#/sales-accounts/account-details/${item.salesforceAccountId}`}
        >
          {item.salesforceAccountId}
        </Link>
      ),
      sortingField: 'salesforceAccountId',
    },
    {
      id: 'sfUltimateParentId',
      header: formatMessage({ id: 'sfUltimateParentId' }),
      cell: item => item.sfUltimateParentId,
      sortingField: 'sfUltimateParentId'
    },
    {
      id: 'salesforceAccountName',
      header: formatMessage({ id: 'salesforceAccountName' }),
      cell: (item) => item.salesforceAccountName,
      sortingField: 'salesforceAccountName',
    },
    {
      id: 'priorityBucket',
      header: formatMessage({ id: 'priorityBucket' }),
      cell: (item) => item.priorityBucket,
      sortingField: 'priorityBucket',
    },
    {
      id: 'propensityTandcRank',
      header: formatMessage({ id: 'propensityTandcRank' }),
      cell: item => item.propensityTandcRank,
      sortingField: 'propensityTandcRank'
    },
    {
      id: 'propensityTandcScore',
      header: formatMessage({ id: 'propensityTandcScore' }),
      cell: (item) => item.propensityTandcScore,
      sortingField: 'propensityTandcScore',
    },
    {
      id: 'propensityPrivateIltScore',
      header: formatMessage({ id: 'propensityPrivateIltScore' }),
      cell: item => item.propensityPrivateIltScore,
      sortingField: 'propensityPrivateIltScore'
    },
    {
      id: 'propensityPrivateIltScoreRank',
      header: formatMessage({ id: 'propensityPrivateIltScoreRank' }),
      cell: (item) => item.propensityPrivateIltScoreRank,
      sortingField: 'propensityPrivateIltScoreRank',
    },
    {
      id: 'propensitySbtsRank',
      header: formatMessage({ id: 'propensitySbtsRank' }),
      cell: item => item.propensitySbtsRank,
      sortingField: 'propensitySbtsRank'
    },
    {
      id: 'propensitySbtsScore',
      header: formatMessage({ id: 'propensitySbtsScore' }),
      cell: (item) => item.propensitySbtsScore,
      sortingField: 'propensitySbtsScore',
    },
    {
      id: 'propensityCtfRank',
      header: formatMessage({ id: 'propensityCtfRank' }),
      cell: item => item.propensityCtfRank,
      sortingField: 'propensityCtfRank'
    },
    {
      id: 'propensityCtfScore',
      header: formatMessage({ id: 'propensityCtfScore' }),
      cell: item => item.propensityCtfScore,
      sortingField: 'propensityCtfScore'
    },
    {
      id: 'businessUnit',
      header: formatMessage({ id: 'businessUnit' }),
      cell: (item) => item.businessUnit,
      sortingField: 'businessUnit',
    },
    {
      id: 'segment',
      header: formatMessage({ id: 'segment' }),
      cell: (item) => item.segment,
      sortingField: 'segment',
    },
    {
      id: 'industry',
      header: formatMessage({ id: 'industry' }),
      cell: (item) => item.segment,
      sortingField: 'industry',
    },
    {
      id: 'accountPriScoreDescription',
      header: formatMessage({ id: 'accountPriScoreDescription' }),
      cell: (item) => item.accountPriScoreDescription,
      sortingField: 'accountPriScoreDescription',
    },
    {
      id: 'subSegment',
      header: formatMessage({ id: 'subSegment' }),
      cell: (item) => item.subSegment,
      sortingField: 'subSegment',
    },
    {
      id: 'geo',
      header: formatMessage({ id: 'geo' }),
      cell: (item) => item.geo,
      sortingField: 'geo',
    },
    {
      id: 'region',
      header: formatMessage({ id: 'region' }),
      cell: (item) => item.region,
      sortingField: 'region',
    },
    {
      id: 'subRegion',
      header: formatMessage({ id: 'subRegion' }),
      cell: (item) => item.subRegion,
      sortingField: 'subRegion',
    },
    {
      id: 'firstBillingCountry',
      header: formatMessage({ id: 'firstBillingCountry' }),
      cell: (item) => item.firstBillingCountry,
      sortingField: 'firstBillingCountry',
    },
    {
      id: 'territory',
      header: formatMessage({ id: 'territory' }),
      cell: (item) => item.territory,
      sortingField: 'territory',
    },
    {
      id: 'awsAccountPhase',
      header: formatMessage({ id: 'awsAccountPhase' }),
      cell: (item) => item.awsAccountPhase,
      sortingField: 'awsAccountPhase',
    },
    {
      id: 'isAttachedByAnyTMode',
      header: formatMessage({ id: 'isAttachedByAnyTMode' }),
      cell: (item) => `${item.isAttachedByAnyTMode}`,
      sortingField: 'isAttachedByAnyTMode',
    },
    {
      id: 'awsGreenfieldStatus',
      header: formatMessage({ id: 'awsGreenfieldStatus' }),
      cell: (item) => `${item.awsGreenfieldStatus}`,
      sortingField: 'awsGreenfieldStatus',
    },
    {
      id: 'isAwsEngagedCustomer',
      header: formatMessage({ id: 'isAwsEngagedCustomer' }),
      cell: (item) => `${item.isAwsEngagedCustomer}`,
      sortingField: 'isAwsEngagedCustomer',
    },
    {
      id: 'estimationMethod',
      header: formatMessage({ id: 'estimationMethod' }),
      cell: (item) => `${item.estimationMethod}`,
      sortingField: 'estimationMethod',
    },
    {
      id: 'tandcBdmContactEmail',
      header: formatMessage({ id: 'tandcBdmContactEmail' }),
      cell: (item) => item.tandcBdmContactEmail,
      sortingField: 'tandcBdmContactEmail',
    },
    {
      id: 'tandcBdmLeaderContactEmail',
      header: formatMessage({ id: 'tandcBdmLeaderContactEmail' }),
      cell: (item) => item.tandcBdmLeaderContactEmail,
      sortingField: 'tandcBdmLeaderContactEmail',
    },
    {
      id: 'tandcBdmAlias',
      header: formatMessage({ id: 'tandcBdmAlias' }),
      cell: (item) => item.tandcBdmAlias,
      sortingField: 'tandcBdmAlias',
    },
    {
      id: 'tandcBdmLeaderAlias',
      header: formatMessage({ id: 'tandcBdmLeaderAlias' }),
      cell: (item) => item.tandcBdmLeaderAlias,
      sortingField: 'tandcBdmLeaderAlias',
    },
    {
      id: 'tandcBdmBdm',
      header: formatMessage({ id: 'tandcBdmBdm' }),
      cell: (item) => item.tandcBdmBdm,
      sortingField: 'tandcBdmBdm',
    },
    {
      id: 'tandcBdmBdmLeader',
      header: formatMessage({ id: 'tandcBdmBdmLeader' }),
      cell: (item) => item.tandcBdmBdmLeader,
      sortingField: 'tandcBdmBdmLeader',
    },
    {
      id: 'awsAccountManagerAm',
      header: formatMessage({ id: 'awsAccountManagerAm' }),
      cell: (item) => item.awsAccountManagerAm,
      sortingField: 'awsAccountManagerAm',
    },
    {
      id: 'isEdp',
      header: formatMessage({ id: 'isEdp' }),
      cell: (item) => `${item.isEdp}`,
      sortingField: 'isEdp',
    },
    {
      id: 'isMap',
      header: formatMessage({ id: 'isMap' }),
      cell: (item) => `${item.isMap}`,
      sortingField: 'isMap',
    },
    {
      id: 'isHighPropensityPrivateIlt',
      header: formatMessage({ id: 'isHighPropensityPrivateIlt' }),
      cell: (item) => `${item.isHighPropensityPrivateIlt}`,
      sortingField: 'isHighPropensityPrivateIlt',
    },
    {
      id: 'isApjIcon',
      header: formatMessage({ id: 'isApjIcon' }),
      cell: (item) => item.isApjIcon,
      sortingField: 'isApjIcon',
    },
    {
      id: 'isLatamDeep',
      header: formatMessage({ id: 'isLatamDeep' }),
      cell: (item) => item.isLatamDeep,
      sortingField: 'isLatamDeep',
    },
    {
      id: 'isProservePriority',
      header: formatMessage({ id: 'isProservePriority' }),
      cell: (item) => `${item.isProservePriority}`,
      sortingField: 'isProservePriority',
    },
    {
      id: 'estimationMethodRank',
      header: formatMessage({ id: 'estimationMethodRank' }),
      cell: (item) => item.estimationMethodRank,
      sortingField: 'estimationMethodRank',
    },
    {
      id: 'revBandAwsSalesT12M',
      header: formatMessage({ id: 'revBandAwsSalesT12M' }),
      cell: (item) => item.revBandAwsSalesT12M,
      sortingField: 'revBandAwsSalesT12M',
    },
    {
      id: 'penetrationTarget',
      header: formatMessage({ id: 'penetrationTarget' }),
      cell: (item) => item.penetrationTarget,
      sortingField: 'penetrationTarget',
    },
    {
      id: 'isPenetrated',
      header: formatMessage({ id: 'isPenetrated' }),
      cell: (item) => `${item.isPenetrated}`,
      sortingField: 'isPenetrated',
    },
    {
      id: 'isApjDeep',
      header: formatMessage({ id: 'isApjDeep' }),
      cell: (item) => `${item.isApjDeep}`,
      sortingField: 'isApjDeep',
    },
    {
      id: 'cAndTConsumption',
      header: formatMessage({ id: 'cAndTConsumption' }),
      cell: (item) => item.cAndTConsumption,
      sortingField: 'cAndTConsumption',
    },
    {
      id: 'revTandcBilledT12M',
      header: formatMessage({ id: 'revTandcBilledT12M' }),
      cell: (item) => item.revTandcBilledT12M,
      sortingField: 'revTandcBilledT12M',
    },
    {
      id: 'revAwsSales12M',
      header: formatMessage({ id: 'revAwsSales12M' }),
      cell: (item) => item.revAwsSales12M,
      sortingField: 'revAwsSales12M',
    },
    {
      id: 'totalAddrLearnersEstimate',
      header: formatMessage({ id: 'totalAddrLearnersEstimate' }),
      cell: (item) => item.totalAddrLearnersEstimate,
      sortingField: 'totalAddrLearnersEstimate',
    },
    {
      id: 'uniqueIndTOrC',
      header: formatMessage({ id: 'uniqueIndTOrC' }),
      cell: (item) => item.uniqueIndTOrC,
      sortingField: 'uniqueIndTOrC',
    },
    {
      id: 'uniqueIndExclPartnerT',
      header: formatMessage({ id: 'uniqueIndExclPartnerT' }),
      cell: (item) => item.uniqueIndExclPartnerT,
      sortingField: 'uniqueIndExclPartnerT',
    },
    {
      id: 'uniqueIndCertifications',
      header: formatMessage({ id: 'uniqueIndCertifications' }),
      cell: (item) => item.uniqueIndCertifications,
      sortingField: 'uniqueIndCertifications',
    },
    {
      id: 'uniqueIndAnyIlt',
      header: formatMessage({ id: 'uniqueIndAnyIlt' }),
      cell: (item) => item.uniqueIndAnyIlt,
      sortingField: 'uniqueIndAnyIlt',
    },
    {
      id: 'uniqueIndPrivateIlt',
      header: formatMessage({ id: 'uniqueIndPrivateIlt' }),
      cell: (item) => item.uniqueIndPrivateIlt,
      sortingField: 'uniqueIndPrivateIlt',
    },
    {
      id: 'uniqueIndPublicIlt',
      header: formatMessage({ id: 'uniqueIndPublicIlt' }),
      cell: (item) => item.uniqueIndPublicIlt,
      sortingField: 'uniqueIndPublicIlt',
    },
    {
      id: 'uniqueIndChannelIlt',
      header: formatMessage({ id: 'uniqueIndChannelIlt' }),
      cell: (item) => item.uniqueIndChannelIlt,
      sortingField: 'uniqueIndChannelIlt',
    },
    {
      id: 'uniqueIndDigital',
      header: formatMessage({ id: 'uniqueIndDigital' }),
      cell: (item) => item.uniqueIndDigital,
      sortingField: 'uniqueIndDigital',
    },
    {
      id: 'totalConsumptionTOrC',
      header: formatMessage({ id: 'totalConsumptionTOrC' }),
      cell: (item) => item.totalConsumptionTOrC,
      sortingField: 'totalConsumptionTOrC',
    },
    {
      id: 'certificationsAwarded',
      header: formatMessage({ id: 'certificationsAwarded' }),
      cell: (item) => item.certificationsAwarded,
      sortingField: 'certificationsAwarded',
    },
    {
      id: 'trainingsPrivateIlt',
      header: formatMessage({ id: 'trainingsPrivateIlt' }),
      cell: (item) => item.trainingsPrivateIlt,
      sortingField: 'trainingsPrivateIlt',
    },
    {
      id: 'trainingsPublicIlt',
      header: formatMessage({ id: 'trainingsPublicIlt' }),
      cell: (item) => item.trainingsPublicIlt,
      sortingField: 'trainingsPublicIlt',
    },
    {
      id: 'trainingsChannelIlt',
      header: formatMessage({ id: 'trainingsChannelIlt' }),
      cell: (item) => item.trainingsChannelIlt,
      sortingField: 'trainingsChannelIlt',
    },
    {
      id: 'trainingsDigital',
      header: formatMessage({ id: 'trainingsDigital' }),
      cell: (item) => item.trainingsDigital,
      sortingField: 'trainingsDigital',
    },
    {
      id: 'trainingsAnyIlt',
      header: formatMessage({ id: 'trainingsAnyIlt' }),
      cell: (item) => item.trainingsAnyIlt,
      sortingField: 'trainingsAnyIlt',
    },
    {
      id: 'attTotalAddrSpendTandC',
      header: formatMessage({ id: 'attTotalAddrSpendTandC' }),
      cell: (item) => item.attTotalAddrSpendTandC,
      sortingField: 'attTotalAddrSpendTandC',
    },
    {
      id: 'attachment',
      header: formatMessage({ id: 'attachment' }),
      cell: (item) => item.attachment,
      sortingField: 'attachment',
    },
    {
      id: 'totalAddressableSpend',
      header: formatMessage({ id: 'totalAddressableSpend' }),
      cell: (item) => item.totalAddressableSpend,
      sortingField: 'totalAddressableSpend',
    },
    {
      id: 'attachmentExclPartnerT',
      header: formatMessage({ id: 'attachmentExclPartnerT' }),
      cell: (item) => item.attachmentExclPartnerT,
      sortingField: 'attachmentExclPartnerT',
    },
    {
      id: 'tandcPhase',
      header: formatMessage({ id: 'tandcPhase' }),
      cell: (item) => item.tandcPhase,
      sortingField: 'tandcPhase',
    },
    {
      id: 'updatedWhen',
      header: formatMessage({ id: 'updatedWhen' }),
      cell: (item) => item.updatedWhen,
      sortingField: 'updatedWhen',
    },
    {
      id: 'tableDescription',
      header: formatMessage({ id: 'tableDescription' }),
      cell: (item) => item.tableDescription,
      sortingField: 'tableDescription',
    },
  ];

  const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
    {
      propertyLabel: formatMessage({ id: 'salesforceAccountName' }),
      key: 'salesforceAccountName',
      groupValuesLabel: formatMessage({ id: 'salesforceAccountName' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'salesforceAccountId' }),
      key: 'salesforceAccountId',
      groupValuesLabel: formatMessage({ id: 'salesforceAccountId' }),
      operators: ['=', '!=']
    },
    {
      propertyLabel: formatMessage({ id: 'priorityBucket' }),
      key: 'priorityBucket',
      groupValuesLabel: formatMessage({ id: 'priorityBucket' }),
      operators: ['=', '!=']
    },
    {
      propertyLabel: formatMessage({ id: 'propensityTandcScore' }),
      key: 'propensityTandcScore',
      groupValuesLabel: formatMessage({ id: 'propensityTandcScore' }),
      operators: ['=', '!=', '>', '>=', '<', '<=']
    },
    {
      propertyLabel: formatMessage({ id: 'propensitySbtsScore' }),
      key: 'propensitySbtsScore',
      groupValuesLabel: formatMessage({ id: 'propensitySbtsScore' }),
      operators: ['=', '!=', '>', '>=', '<', '<=']
    },
    {
      propertyLabel: formatMessage({ id: 'propensityPrivateIltScore' }),
      key: 'propensityPrivateIltScore',
      groupValuesLabel: formatMessage({ id: 'propensityPrivateIltScore' }),
      operators: ['=', '!=', '>', '>=', '<', '<=']
    },
    {
      propertyLabel: formatMessage({ id: 'propensityCtfScore' }),
      key: 'propensityCtfScore',
      groupValuesLabel: formatMessage({ id: 'propensityCtfScore' }),
      operators: ['=', '!=', '>', '>=', '<', '<=']
    },
    {
      propertyLabel: formatMessage({ id: 'tandcBdmAlias' }),
      key: 'tandcBdmAlias',
      groupValuesLabel: formatMessage({ id: 'tandcBdmAlias' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'tandcBdmLeaderAlias' }),
      key: 'tandcBdmLeaderAlias',
      groupValuesLabel: formatMessage({ id: 'tandcBdmLeaderAlias' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'subRegion' }),
      key: 'subRegion',
      groupValuesLabel: formatMessage({ id: 'subRegion' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'territory' }),
      key: 'territory',
      groupValuesLabel: formatMessage({ id: 'territory' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'tandcPhase' }),
      key: 'tandcPhase',
      groupValuesLabel: formatMessage({ id: 'tandcPhase' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'isMap' }),
      key: 'isMap',
      groupValuesLabel: formatMessage({ id: 'isMap' }),
      operators: ['=', '!='],
    },
    {
      propertyLabel: formatMessage({ id: 'isEdp' }),
      key: 'isEdp',
      groupValuesLabel: formatMessage({ id: 'isEdp' }),
      operators: ['=', '!='],
    },
    {
      propertyLabel: formatMessage({ id: 'isHighPropensityPrivateIlt' }),
      key: 'isHighPropensityPrivateIlt',
      groupValuesLabel: formatMessage({ id: 'isHighPropensityPrivateIlt' }),
      operators: ['=', '!='],
    },
    {
      propertyLabel: formatMessage({ id: 'isProservePriority' }),
      key: 'isProservePriority',
      groupValuesLabel: formatMessage({ id: 'isProservePriority' }),
      operators: ['=', '!='],
    },
    {
      propertyLabel: formatMessage({ id: 'isAwsEngagedCustomer' }),
      key: 'isAwsEngagedCustomer',
      groupValuesLabel: formatMessage({ id: 'isAwsEngagedCustomer' }),
      operators: ['=', '!='],
    },
    {
      propertyLabel: formatMessage({ id: 'firstBillingCountry' }),
      key: 'firstBillingCountry',
      groupValuesLabel: formatMessage({ id: 'firstBillingCountry' }),
      operators: ['=', '!=', ':'],
    },
    {
      propertyLabel: formatMessage({ id: 'isApjDeep' }),
      key: 'isApjDeep',
      groupValuesLabel: formatMessage({ id: 'isApjDeep' }),
      operators: ['=', '!='],
    },
    {
      propertyLabel: formatMessage({ id: 'isLatamDeep' }),
      key: 'isLatamDeep',
      groupValuesLabel: formatMessage({ id: 'isLatamDeep' }),
      operators: ['=', '!='],
    },
    {
      propertyLabel: formatMessage({ id: 'isPenetrated' }),
      key: 'isPenetrated',
      groupValuesLabel: formatMessage({ id: 'isPenetrated' }),
      operators: ['=', '!='],
    },
    {
      propertyLabel: formatMessage({ id: 'sfUltimateParentId' }),
      key: 'sfUltimateParentId',
      groupValuesLabel: formatMessage({ id: 'sfUltimateParentId' }),
      operators: ['=', '!=', ':', '!:']
    },
    {
      propertyLabel: formatMessage({ id: 'gtmCustomerName' }),
      key: 'gtmCustomerName',
      groupValuesLabel: formatMessage({ id: 'gtmCustomerName' }),
      operators: ['=', '!=', ':', '!:']
    },
    {
      propertyLabel: formatMessage({ id: 'awsAccountPhase' }),
      key: 'awsAccountPhase',
      groupValuesLabel: formatMessage({ id: 'awsAccountPhase' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'tandcBdmBdm' }),
      key: 'tandcBdmBdm',
      groupValuesLabel: formatMessage({ id: 'tandcBdmBdm' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'tandcBdmBdmLeader' }),
      key: 'tandcBdmBdmLeader',
      groupValuesLabel: formatMessage({ id: 'tandcBdmBdmLeader' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'awsAccountManagerAm' }),
      key: 'awsAccountManagerAm',
      groupValuesLabel: formatMessage({ id: 'awsAccountManagerAm' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'businessUnit' }),
      key: 'businessUnit',
      groupValuesLabel: formatMessage({ id: 'businessUnit' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'segment' }),
      key: 'segment',
      groupValuesLabel: formatMessage({ id: 'segment' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'subSegment' }),
      key: 'subSegment',
      groupValuesLabel: formatMessage({ id: 'subSegment' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'industry' }),
      key: 'industry',
      groupValuesLabel: formatMessage({ id: 'industry' }),
      operators: ['=', '!=', ':']
    },
    {
      propertyLabel: formatMessage({ id: 'geo' }),
      key: 'geo',
      groupValuesLabel: formatMessage({ id: 'geo' }),
      operators: ['=', '!=']
    },
    {
      propertyLabel: formatMessage({ id: 'region' }),
      key: 'region',
      groupValuesLabel: formatMessage({ id: 'region' }),
      operators: ['=', '!=', ':'],
    },
    {
      propertyLabel: formatMessage({ id: 'revBandAwsSalesT12M' }),
      key: 'region',
      groupValuesLabel: formatMessage({ id: 'revBandAwsSalesT12M' }),
      operators: ['=', '!=', '>', '<', '<=', '>='],
    }
  ];

  const PROPERTY_FILTERING_I18N_CONSTANTS = {
    filteringAriaLabel: formatMessage({ id: 'yourChoice' }),
    dismissAriaLabel: formatMessage({ id: 'dismiss' }),

    filteringPlaceholder: formatMessage({ id: 'searchAccountsText' }),
    groupValuesText: formatMessage({ id: 'groupValues' }),
    groupPropertiesText: formatMessage({ id: 'properties' }),
    operatorsText: formatMessage({ id: 'operators' }),

    operationAndText: formatMessage({ id: 'and' }),
    operationOrText: formatMessage({ id: 'or' }),

    operatorLessText: formatMessage({ id: 'lessThan' }),
    operatorLessOrEqualText: formatMessage({ id: 'lessThanOrEqual' }),
    operatorGreaterText: formatMessage({ id: 'greaterThan' }),
    operatorGreaterOrEqualText: formatMessage({ id: 'greaterThanOrEqual' }),
    operatorContainsText: formatMessage({ id: 'contains' }),
    operatorDoesNotContainText: formatMessage({ id: 'doesNotContain' }),
    operatorEqualsText: formatMessage({ id: 'equals' }),
    operatorDoesNotEqualText: formatMessage({ id: 'doesNoteEqual' }),

    editTokenHeader: formatMessage({ id: 'editFilter' }),
    propertyText: formatMessage({ id: 'property' }),
    operatorText: formatMessage({ id: 'operator' }),
    valueText: formatMessage({ id: 'value' }),
    cancelActionText: formatMessage({ id: 'cancel' }),
    applyActionText: formatMessage({ id: 'apply' }),
    allPropertiesLabel: formatMessage({ id: 'allProperties' }),

    tokenLimitShowMore: formatMessage({ id: 'showMore' }),
    tokenLimitShowFewer: formatMessage({ id: 'showFewer' }),
    clearFiltersText: formatMessage({ id: 'clearFilters' }),
    removeTokenButtonAriaLabel: () => formatMessage({ id: 'removeToken' }),
    enteredTextLabel: (text: any) =>
      formatMessage({ id: 'useTextLabel' }, { text: `${text}` }),
  };

  const PAGINATION_LABELS = {
    nextPageLabel: formatMessage({ id: 'nextPage' }),
    pageLabel: (pageNumber: number) =>
      formatMessage({ id: 'goToPage' }, { pageNumber: `${pageNumber}` }),
    previousPageLabel: formatMessage({ id: 'previousPage' }),
  };

  const PAGE_SIZE_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
    { value: 10, label: formatMessage({ id: '10Accounts' }) },
    { value: 30, label: formatMessage({ id: '30Accounts' }) },
    { value: 50, label: formatMessage({ id: '50Accounts' }) },
  ];

  const CONTENT_DISPLAY_OPTIONS = {
    title: formatMessage({ id: 'setColumnsPref' }),
    description: formatMessage({ id: 'setColumnsDesc' }),
    options: [
      {
        id: 'salesforceAccountId',
        label: formatMessage({ id: 'salesforceAccountId' }),
      },
      {
        id: 'sfUltimateParentId',
        label: formatMessage({ id: 'sfUltimateParentId' }),
      },
      {
        id: 'salesforceAccountName',
        label: formatMessage({ id: 'salesforceAccountName' }),
      },
      {
        id: 'priorityBucket',
        label: formatMessage({ id: 'priorityBucket' })
      },
      {
        id: 'propensityTandcRank',
        label: formatMessage({ id: 'propensityTandcRank' })
      },
      {
        id: 'propensityTandcScore',
        label: formatMessage({ id: 'propensityTandcScore' })
      },
      {
        id: 'propensityPrivateIltScore',
        label: formatMessage({ id: 'propensityPrivateIltScore' })
      },
      {
        id: 'propensityPrivateIltScoreRank',
        label: formatMessage({ id: 'propensityPrivateIltScoreRank' })
      },
      {
        id: 'propensitySbtsRank',
        label: formatMessage({ id: 'propensitySbtsRank' })
      },
      {
        id: 'propensitySbtsScore',
        label: formatMessage({ id: 'propensitySbtsScore' })
      },
      {
        id: 'propensityCtfRank',
        label: formatMessage({ id: 'propensityCtfRank' })
      },
      {
        id: 'propensityCtfScore',
        label: formatMessage({ id: 'propensityCtfScore' })
      },
      {
        id: 'businessUnit',
        label: formatMessage({ id: 'businessUnit' }),
      },
      {
        id: 'segment',
        label: formatMessage({ id: 'segment' }),
      },
      {
        id: 'geo',
        label: formatMessage({ id: 'geo' }),
      },
      {
        id: 'industry',
        label: formatMessage({ id: 'industry' }),
      },
      {
        id: 'estimationMethod',
        label: formatMessage({ id: 'estimationMethod' }),
      },
      {
        id: 'region',
        label: formatMessage({ id: 'region' }),
      },
      {
        id: 'subRegion',
        label: formatMessage({ id: 'subRegion' }),
      },
      {
        id: 'firstBillingCountry',
        label: formatMessage({ id: 'firstBillingCountry' }),
      },
      {
        id: 'territory',
        label: formatMessage({ id: 'territory' }),
      },
      {
        id: 'awsAccountPhase',
        label: formatMessage({ id: 'awsAccountPhase' }),
      },
      {
        id: 'isAttachedByAnyTMode',
        label: formatMessage({ id: 'isAttachedByAnyTMode' }),
      },
      {
        id: 'awsGreenfieldStatus',
        label: formatMessage({ id: 'awsGreenfieldStatus' }),
      },
      {
        id: 'isAwsEngagedCustomer',
        label: formatMessage({ id: 'isAwsEngagedCustomer' }),
      },
      {
        id: 'tandcBdmContactEmail',
        label: formatMessage({ id: 'tandcBdmContactEmail' }),
      },
      {
        id: 'tandcBdmLeaderContactEmail',
        label: formatMessage({ id: 'tandcBdmLeaderContactEmail' }),
      },
      {
        id: 'tandcBdmAlias',
        label: formatMessage({ id: 'tandcBdmAlias' }),
      },
      {
        id: 'tandcBdmLeaderAlias',
        label: formatMessage({ id: 'tandcBdmLeaderAlias' }),
      },
      {
        id: 'tandcBdmBdm',
        label: formatMessage({ id: 'tandcBdmBdm' }),
      },
      {
        id: 'awsAccountManagerAm',
        label: formatMessage({ id: 'awsAccountManagerAm' }),
      },
      {
        id: 'subSegment',
        label: formatMessage({ id: 'subSegment' }),
      },
      {
        id: 'tandcBdmBdmLeader',
        label: formatMessage({ id: 'tandcBdmBdmLeader' }),
      },
      {
        id: 'isMap',
        label: formatMessage({ id: 'isMap' }),
      },
      {
        id: 'uniqueIndCertifications',
        label: formatMessage({ id: 'uniqueIndCertifications' }),
      },
      {
        id: 'uniqueIndAnyIlt',
        label: formatMessage({ id: 'uniqueIndAnyIlt' }),
      },
      {
        id: 'uniqueIndPrivateIlt',
        label: formatMessage({ id: 'uniqueIndPrivateIlt' }),
      },
      {
        id: 'uniqueIndPublicIlt',
        label: formatMessage({ id: 'uniqueIndPublicIlt' }),
      },
      {
        id: 'uniqueIndChannelIlt',
        label: formatMessage({ id: 'uniqueIndChannelIlt' }),
      },
      {
        id: 'uniqueIndDigital',
        label: formatMessage({ id: 'uniqueIndDigital' }),
      },
      {
        id: 'totalConsumptionTOrC',
        label: formatMessage({ id: 'totalConsumptionTOrC' }),
      },
      {
        id: 'certificationsAwarded',
        label: formatMessage({ id: 'certificationsAwarded' }),
      },
      {
        id: 'trainingsAnyIlt',
        label: formatMessage({ id: 'trainingsAnyIlt' }),
      },
      {
        id: 'trainingsPrivateIlt',
        label: formatMessage({ id: 'trainingsPrivateIlt' }),
      },
      {
        id: 'trainingsPublicIlt',
        label: formatMessage({ id: 'trainingsPublicIlt' }),
      },
      {
        id: 'trainingsChannelIlt',
        label: formatMessage({ id: 'trainingsChannelIlt' }),
      },
      {
        id: 'trainingsDigital',
        label: formatMessage({ id: 'trainingsDigital' }),
      },
      {
        id: 'attTotalAddrSpendTandC',
        label: formatMessage({ id: 'attTotalAddrSpendTandC' }),
      },
      {
        id: 'attachment',
        label: formatMessage({ id: 'attachment' }),
      },
      {
        id: 'attachmentExclPartnerT',
        label: formatMessage({ id: 'attachmentExclPartnerT' }),
      },
      {
        id: 'tandcPhase',
        label: formatMessage({ id: 'tandcPhase' }),
      },
      {
        id: 'accountPriScoreDescription',
        label: formatMessage({ id: 'accountPriScoreDescription' }),
      },
      {
        id: 'updatedWhen',
        label: formatMessage({ id: 'updatedWhen' }),
      },
      {
        id: 'tableDescription',
        label: formatMessage({ id: 'tableDescription' }),
      },
      {
        id: 'isEdp',
        label: formatMessage({ id: 'isEdp' }),
      },
      {
        id: 'isApjIcon',
        label: formatMessage({ id: 'isApjIcon' }),
      },
      {
        id: 'isApjDeep',
        label: formatMessage({ id: 'isApjDeep' }),
      },
      {
        id: 'isHighPropensityPrivateIlt',
        label: formatMessage({ id: 'isHighPropensityPrivateIlt' }),
      },
      {
        id: 'isLatamDeep',
        label: formatMessage({ id: 'isLatamDeep' }),
      },
      {
        id: 'isProservePriority',
        label: formatMessage({ id: 'isProservePriority' }),
      },
      {
        id: 'estimationMethodRank',
        label: formatMessage({ id: 'estimationMethodRank' }),
      },
      {
        id: 'revBandAwsSalesT12M',
        label: formatMessage({ id: 'revBandAwsSalesT12M' }),
      },
      {
        id: 'penetrationTarget',
        label: formatMessage({ id: 'penetrationTarget' }),
      },
      {
        id: 'isPenetrated',
        label: formatMessage({ id: 'isPenetrated' }),
      },
      {
        id: 'cAndTConsumption',
        label: formatMessage({ id: 'cAndTConsumption' }),
      },
      {
        id: 'revTandcBilledT12M',
        label: formatMessage({ id: 'revTandcBilledT12M' }),
      },
      {
        id: 'totalAddressableSpend',
        label: formatMessage({ id: 'totalAddressableSpend' }),
      },
      {
        id: 'revAwsSales12M',
        label: formatMessage({ id: 'revAwsSales12M' }),
      },
      {
        id: 'totalAddrLearnersEstimate',
        label: formatMessage({ id: 'totalAddrLearnersEstimate' }),
      },
      {
        id: 'uniqueIndTOrC',
        label: formatMessage({ id: 'uniqueIndTOrC' }),
      },
      {
        id: 'uniqueIndExclPartnerT',
        label: formatMessage({ id: 'uniqueIndExclPartnerT' }),
      },
    ],
  };

  return {
    defaultPreferences: DEFAULT_PREFERENCES,
    columnDefinitions: COLUMN_DEFINITIONS,
    filteringProperties: FILTERING_PROPERTIES,
    propertyFilteringI18nConstants: PROPERTY_FILTERING_I18N_CONSTANTS,
    paginationLabels: PAGINATION_LABELS,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    contentDisplayOptions: CONTENT_DISPLAY_OPTIONS,
  };
}

export const Preferences = ({
  preferences,
  setPreferences,
  pageSizeOptions,
  contentDisplayOptions,
}: any) => {
  const { formatMessage } = useIntl();

  return (
    <CollectionPreferences
      preferences={preferences}
      onConfirm={(event) => setPreferences(event.detail)}
      title={formatMessage({ id: 'preferences' })}
      confirmLabel={formatMessage({ id: 'confirm' })}
      cancelLabel={formatMessage({ id: 'cancel' })}
      pageSizePreference={{
        title: formatMessage({ id: 'pageSize' }),
        options: pageSizeOptions,
      }}
      contentDisplayPreference={contentDisplayOptions}
    />
  );
};
