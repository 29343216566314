import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import Cache from '@aws-amplify/cache';
import configs from '../config/gandalfConfig.json';

export const createUrlInterceptor =
    ({ domain, login, logout }: { domain: string; login: string; logout: string }, verifyEmail = false) =>
    (url: string) => {
        const SELF = '_self';
        const loginRequestUrl = `https://${domain}/oauth2/authorize`;
        const logoutRequestUrl = `https://${domain}/logout`;
        let windowProxy;

        if (login && url.startsWith(loginRequestUrl)) {
            const signInUrl = url.replace(loginRequestUrl, `https://${login}/login`) + `&require_email_verification=${verifyEmail}`;
            windowProxy = window.open(signInUrl, SELF);
        } else if (logout && url.startsWith(logoutRequestUrl)) {
            const signOutUrl = url.replace(logoutRequestUrl, `https://${logout}/logout`);
            windowProxy = window.open(signOutUrl, SELF);
        } else {
            windowProxy = window.open(url, SELF);
        }
        return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
    };

export const getStage = () => {
    // https://www.w3schools.com/jsref/prop_loc_origin.asp
    const url = `${window.location.origin}`;
    // includes() is not supported in Internet Explorer.
    // Microsoft Edge is the recommended browser by Microsoft
    // Support for Internet Explorer ended on June 15, 2022. Internet Explorer 11 has been permanently disabled through a Microsoft Edge update on certain versions of Windows 10.
    // If any site you visit needs Internet Explorer 11, you can reload it with Internet Explorer mode in Microsoft Edge.
    // https://stackoverflow.com/questions/36574351/includes-not-working-in-internet-explorer
    if (url.includes('beta')) {
      return 'beta';
    } else if (url.includes('gamma')) {
      return 'gamma';
    } else if (url.includes('prod') || url.startsWith('https://lna.aws.training')) {
      return 'prod';
    } else {
      return 'dev';
    }
};

export const getConfigure = () => {
    const stage = getStage();
    if (stage === 'gamma') {
        return configs.gamma;
    } else if (stage === 'beta') {
      return configs.beta;
    } else if (stage === 'prod') {
      return configs.prod;
    }else {
      return configs.dev;
    }
};

const config = getConfigure();

/**
 * Setup amplify config
 */
const AMPLIFY_CONFIG = (verifyEmail = false) => ( {
    Auth: {
        region: config.Auth.region,
        userPoolId: config.Auth.userPoolId,
        userPoolWebClientId: config.Auth.userPoolWebClientId,
        oauth: {
            domain: config.Auth.oauth.domain,
            scope: config.Auth.oauth.scope,
            redirectSignIn: getStage() === 'dev' ? `${window.location.origin}` : config.Auth.oauth.redirectSignIn,
            redirectSignOut: getStage() === 'dev' ? `${window.location.origin}` : config.Auth.oauth.redirectSignOut,
            responseType: config.Auth.oauth.responseType,
            codeChallengeMethod: 'S256',
            codeChallenge: encodeURI(btoa(Math.random().toString())),
            urlOpener: createUrlInterceptor(
                {
                    domain: config.Auth.oauth.domain,
                    login: config.Gandalf.customSignInDomain,
                    logout: config.Gandalf.customSignOutDomain,
                },
                verifyEmail,
            ),
        },
    },
});

export const configureAmplify = (verifyEmail = false) => {
    Amplify.configure(AMPLIFY_CONFIG(verifyEmail));
};
/**
 * Checks if there is a current authenticated user.
 * Auth.currentAuthenticatedUser returns a valid jwtToken and if different
 * from Cache federatedInfo, update the cache with the valid token
 */
export const hasValidToken = async () => {
    try {
        const cognitoIdToken = (await Auth.currentAuthenticatedUser({ bypassCache: true }))
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken();
        const currentToken = Cache.getItem('federatedInfo').token;
        if (currentToken !== cognitoIdToken) {
            Cache.setItem('federatedInfo', { token: cognitoIdToken });
        }
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
};

export const redirectToLoginPage = (verifyEmail = false) => {
    configureAmplify(verifyEmail);
    Auth.federatedSignIn();
};
