/**
 * This code will replace all characters in the given range (unicode 00A0 - 9999,
 * as well as ampersand, greater & less than) with their html entity equivalents,
 * which is simply &#nnn; where nnn is the unicode value we get from charCodeAt.
 * @param rawStr {string}
 * @example encodedStr("<script>") -> '&#60;script&#62;'
 *          encodedStr("hello world") -> "hello world"
 */
export const encodedStr  = (rawStr: string) => rawStr.replace(/[\u00A0-\u9999<>\&]/g, function(i) {
  return '&#'+i.charCodeAt(0)+';';
});
