import {
  BreadcrumbGroup,
} from '@amzn/awsui-components-react';

const BreadCrumbs = (name: string, formatMessage?: any, urlParams?: string): React.ReactNode => {
  const engagementListsBreadcrumbs = [
    {
      text: formatMessage({ id: 'lna' }),
      href: '#',
    },
    {
      text: formatMessage({ id: 'engagements' }),
      href: '#/engagements',
    },
  ];
  const userListBreadcrumbs = [
    {
      text: formatMessage({ id: 'lna' }),
      href: '#',
    },
    {
      text: formatMessage({ id: 'userList' }),
      href: '#/users',
    },
  ];

  const accountListBreadcrumbs = [
    {
      text: formatMessage({ id: 'sip' }),
      href: '#',
    },
    {
      text: formatMessage({ id: 'accountsBreadcrumb' }),
      href: '#/sales-accounts'
    }
  ];

  const dashboardBreadcrumbs = [
    {
      text: formatMessage({ id: 'sip' }),
      href: '#',
    },
    {
      text: formatMessage({ id: 'dashboard' }),
      href: '#/sales-accounts/dashboard'
    }
  ];

  const accountDetailsBreadcrumbs = [
    ...accountListBreadcrumbs,
    {
      text: formatMessage({ id: 'accountDetails' }),
      href: `#/sales-accounts/${urlParams}`
    }
  ];

  const createEngagementsBreadcrumbs = [
    ...engagementListsBreadcrumbs,
    {
      text: formatMessage({ id: 'createEngagement' }),
      href: '#/create-engagement',
    },
  ];

  const engagementDetailBreadcrumbs = [
    ...engagementListsBreadcrumbs,
    {
      text: formatMessage({ id: 'engagementDetails' }),
      href: `#/engagement-detail/${urlParams}`,
    },
  ];

  const editEngagementBreadcrumbs = [
    ...engagementDetailBreadcrumbs,
    {
      text: formatMessage({ id: 'editEngagement' }),
      href: `#/edit-engagement/${urlParams}`,
    },
  ];

  const reopenEngagementBreadcrumbs = [
    ...engagementDetailBreadcrumbs,
    {
      text: formatMessage({ id: 'reopenEngagement' }),
      href: `#/reopen-engagement/${urlParams}`,
    },
  ];
  const object = {
    engagementListsBreadcrumbs: (<BreadcrumbGroup items={[...engagementListsBreadcrumbs]} />),
    createEngagementsBreadcrumbs: (<BreadcrumbGroup items={[...createEngagementsBreadcrumbs]} />),
    engagementDetailBreadcrumbs: (<BreadcrumbGroup items={[...engagementDetailBreadcrumbs]} />),
    editEngagementBreadcrumbs: (<BreadcrumbGroup items={[...editEngagementBreadcrumbs]} />),
    reopenEngagementBreadcrumbs: (<BreadcrumbGroup items={[...reopenEngagementBreadcrumbs]} />),
    userListBreadcrumbs: (<BreadcrumbGroup items={[...userListBreadcrumbs]} />),
    salesAccountBreadcrumbs: (<BreadcrumbGroup items={[...accountListBreadcrumbs]} />),
    salesAccountDetailsBreadcrumbs: (<BreadcrumbGroup items={[...accountDetailsBreadcrumbs]} />),
    dashboardBreadcrumbs: (<BreadcrumbGroup items={[...dashboardBreadcrumbs]} />)
  }
  return object[name as keyof typeof object]
};

export default BreadCrumbs;
