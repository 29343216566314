import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  RadioGroup,
  Select,
  SelectProps,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';

import { useIntl } from 'react-intl';
import { useState } from 'react';

type FeedbackModalProps = {
  visible: boolean;
  onDismissHandler: () => void;
  onConfirmHandler: () => void;
  onCancelHandler: () => void;
};

const FeedbackModal = (props: FeedbackModalProps) => {
  const { formatMessage } = useIntl();
  const { visible, onDismissHandler, onConfirmHandler, onCancelHandler } =
    props;
  const [selectedOption, setSelectedOption] = useState<SelectProps.Option>({
    label: formatMessage({ id: 'general' }),
    value: 'general',
  });
  const options = [
    { label: formatMessage({ id: 'general' }), value: 'general' },
    { label: formatMessage({ id: 'featureRequest' }), value: 'featureRequest' },
    { label: formatMessage({ id: 'reportIssue' }), value: 'reportIssue' },
    {
      label: formatMessage({ id: 'supportAccountBilling' }),
      value: 'supportAccountBilling',
    },
    { label: formatMessage({ id: 'uiFeedback' }), value: 'uiFeedback' },
  ];
  const [message, setMessage] = useState('');
  const [happy, setHappy] = useState('');

  return (
    <Modal
      className='awsui-visual-refresh'
      visible={visible}
      onDismiss={onDismissHandler}
      closeAriaLabel={formatMessage({ id: 'closeModal' })}
      header={formatMessage({ id: 'feedbackForSip' })}
      footer={
        <Box float='right'>
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
            <Button
              variant='link'
              onClick={onCancelHandler}
            >
              {formatMessage({ id: 'cancel' })}
            </Button>
            <Button
              variant='primary'
              onClick={onConfirmHandler}
            >
              {formatMessage({ id: 'submit' })}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween
        direction='vertical'
        size='l'
      >
        <p className='no-margin'>{formatMessage({ id: 'feedbackText' })}</p>
        <FormField
          label={formatMessage({ id: 'type' })}
          description={formatMessage({ id: 'typeDes' })}
        >
          <Select
            selectedOption={selectedOption}
            options={options}
            onChange={({ detail }) => setSelectedOption(detail.selectedOption)}
          />
        </FormField>
        <FormField
          label={formatMessage({ id: 'messageBelow' })}
          description={formatMessage({ id: 'messageDes' })}
        >
          <Textarea
            value={message}
            onChange={({ detail }) => setMessage(detail.value)}
          />
        </FormField>
        <FormField label={formatMessage({ id: 'satisfied' })}>
          <RadioGroup
            value={happy}
            items={[
              { value: 'yes', label: formatMessage({ id: 'yes' }) },
              { value: 'no', label: formatMessage({ id: 'no' }) },
            ]}
            onChange={({ detail }) => setHappy(detail.value)}
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};

export default FeedbackModal;
