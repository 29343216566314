import SideNavigation, {
  SideNavigationProps,
} from '@amzn/awsui-components-react/polaris/side-navigation';
import { WithRouterProps, withRouter } from '../../hooks/withRouter';
import { useLocation, useNavigate } from 'react-router-dom';

import { GlobalState } from '../../redux/reducers';
import { displaySip } from '../../util/displaySip';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// Class ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
const ServiceNavigationLocal = () => {
  const { formatMessage } = useIntl();
  const displaySIP = displaySip();
  const HOME = { text: formatMessage({ id: displaySIP ? 'sip' : 'lna' }), href: '#/' };

  const roles = useSelector<GlobalState, string[]>(state => state.user.roles);

  // All admin roles can access Users Page
  const containsUserPage = roles.some((el) => el.indexOf('admin') >= 0);

  const lnaMenu: SideNavigationProps.Item = {
    type: 'section',
    text: formatMessage({ id: 'lna' }),
    items: [
      {
        type: 'link',
        text: formatMessage({ id: 'createLna' }),
        href: '#/lna-creation',
      },
      {
        type: 'link',
        text: formatMessage({ id: 'engagements' }),
        href: '#/engagements',
      },
    ],
  };

  const userMenu: SideNavigationProps.Item = {
    type: 'section',
    text: formatMessage({ id: 'userSection' }),
    items: [
      { type: 'link', text: formatMessage({ id: 'users' }), href: '#/users' },
    ],
  };

  const sipMenu: SideNavigationProps.Item = {
    type: 'section',
    text: formatMessage({ id: 'sipLeftNav' }),
    items: [
      {
        type: 'link',
        text: formatMessage({ id: 'sipAccounts' }),
        href: '#/sales-accounts'
      },
      {
        type: 'link',
        text: formatMessage({ id: 'dashboard' }),
        href: '#/sales-accounts/dashboard'
      }
    ],
  };

  const items: SideNavigationProps.Item[] = [];

  if (displaySIP) items.push(sipMenu);
  items.push(lnaMenu);
  if (containsUserPage) items.push(userMenu);

  const navigate = useNavigate();
  const location = useLocation();
  // If the provided link is empty, do not redirect pages
  const onFollowHandler = (
    event: CustomEvent<SideNavigationProps.FollowDetail>
  ) => {
    event.preventDefault();
    if (event.detail.href) {
      navigate(event.detail.href.substring(1));
    }
  };

  return (
    <SideNavigation
      header={HOME}
      items={items}
      activeHref={`#${location.pathname}`}
      onFollow={onFollowHandler.bind(this)}
    />
  );
};

export const ServiceNavigation = withRouter(ServiceNavigationLocal);
