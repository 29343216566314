// Function that parses a date string in the format 'YYYY-MM-DD' and returns a Date object
// with the local timezone applied
function parseLocalDate(dateString: string): Date {
  // Split the input dateString using '-' as the delimiter
  // Then, convert each part of the date string (year, month, day) to an integer using parseInt
  const [year, month, day] = dateString.split('-').map((part) => parseInt(part, 10));

  // Create a new Date object using the parsed year, month, and day
  // Note that the month is zero-based, so we subtract 1 from the parsed month value
  return new Date(year, month - 1, day);
}

// Function that checks if a given date (in string format) is at least tomorrow/today, if isTodayValid is true, check at least today
export function isAtLeastTomorrow(dateString: string, isTodayValid?: boolean): boolean {
  // Create a Date object from the input dateString

  const inputDate = parseLocalDate(dateString);
  // Normalize the inputDate object to the start of the day (midnight)
  inputDate.setHours(0, 0, 0, 0);

  // Create a new Date object representing today's date
  const today = new Date();
  // Normalize the today object to the start of the day (midnight)
  today.setHours(0, 0, 0, 0);

  // Create a new Date object representing tomorrow's date
  const referenceDate = new Date(today);
   // If isEditMode is true, use today as the reference date, otherwise use tomorrow
   if (!isTodayValid) {
    referenceDate.setDate(referenceDate.getDate() + 1);
  }

  // Compare the inputDate and tomorrow objects
  // If inputDate is greater than or equal to tomorrow, return true, otherwise return false
  return inputDate.getTime() >= referenceDate.getTime();
}
