/**
 *
 * @param {string} email - The email address that will be validated
 * @returns {boolean} - Whether the email is valid or not
 * @usage Example isEmailValid('example@example.com') -> true
 *                isEmailValid('ssss') -> false
 *                isEmailValid('dd#@a.s') -> true
 *                isEmailValid('dd#@a.') -> false
 */
export function isEmailValid(email: string): boolean {
  // Regular expression to match email format
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email format against the regular expression
  return regex.test(email);
}
