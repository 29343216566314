import { AppLayout, Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';

import BreadCrumbs from '../../../../common/breadcrumbs';
import { GlobalState } from '../../../../redux/reducers';
import { NotificationState } from '../../../../redux/reducers/notificationReducer';
import { ServiceNavigation } from '../../../navigation';
import { Type } from '../../../../interfaces';
import { UUID } from '../../../../util/uuid';
import UserListContainer from './userListContainer';
import toolsTopics from '../../../../common/tools-content';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const UserList = () => {

  const { shouldShowNotification, notification } = useSelector<GlobalState, NotificationState>(state => state.notifications);
  const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  useEffect(() => {
    if(shouldShowNotification && notification) {
      const object = [{
        type: notification.type as Type,
        dismissible: notification.dismissible,
        onDismiss: () => setItems([]),
        content: notification.content,
        id: notification.id || UUID(),
        header: notification.headerText
      }];
      setItems(object);
    } else if(!shouldShowNotification) {
      setItems([]);
    }
  }, [shouldShowNotification, notification]);

    const [navigationOpen, setNavigationOpen] = useState(false);
    const [toolsOpen, setToolsOpen] = useState(false);
    const { formatMessage } = useIntl();

    return (
        <AppLayout
            toolsOpen={toolsOpen}
            breadcrumbs={BreadCrumbs('userListBreadcrumbs', formatMessage)}
            onToolsChange={event => setToolsOpen(event.detail.open)}
            tools={toolsTopics('userList', formatMessage)}
            navigation={<ServiceNavigation />}
            navigationOpen={navigationOpen}
            onNavigationChange={event => setNavigationOpen(event.detail.open)}
            content={<UserListContainer />}
            notifications={<Flashbar items={items} />}
        />
    );
};

export default UserList;
