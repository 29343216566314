import { AttributeEditor, Input } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

type DynamicInputFieldsProps = {
  fields?: string[];
  isEditMode?: boolean;
  isReopenMode?: boolean;
  isCollaboratorEnabled?: boolean;
  setParentVariables: (valuePassToParent: any[]) => void;
  onBlur?: () => void;
  placeholder: string;
  attributeName: string;
  addBtnText: string;
  maximumSize: number;
}

export const DynamicInputFields = ({
  fields = [],
  isReopenMode,
  isCollaboratorEnabled,
  setParentVariables,
  onBlur,
  placeholder,
  attributeName,
  addBtnText,
  maximumSize
}: DynamicInputFieldsProps) => {

  const { formatMessage } = useIntl();
  const [items, setItems] = useState<{ value: string, dirty: boolean }[]>(fields.map(item => ({ value: item, dirty: false })));

  useEffect(() => {
    setParentVariables(items);
  }, [items]);

  const definition = [{
    control: (item: { value: string; }, index: number) =>
      <Input
        onBlur={onBlur}
        value={item.value}
        placeholder={formatMessage({ id:  placeholder })}
        disabled={isReopenMode || (isCollaboratorEnabled != undefined && !isCollaboratorEnabled)}
        onChange={({detail})=> {
          setItems((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[index] = {
              ...updatedItems[index],
              value: detail.value,
              dirty: true
            }
            return updatedItems;
          })
      }}
      data-test='dynamic-inputs-fields'
       />,
    label: formatMessage({ id:  attributeName })
  }];

  return (
    <AttributeEditor
      className='team-names-editor'
      addButtonText={formatMessage({ id: addBtnText })}
      definition={definition}
      isItemRemovable={() => (!isReopenMode && (isCollaboratorEnabled == undefined || isCollaboratorEnabled))}
      disableAddButton={items.length >= maximumSize || isReopenMode || (isCollaboratorEnabled != undefined && !isCollaboratorEnabled)}
      items={items}
      removeButtonText={formatMessage({ id: 'remove' })}
      onAddButtonClick={() => {
        setItems([...items, {value: '', dirty: false}]);
        if (onBlur) onBlur();
      }
      }
      onRemoveButtonClick={({ detail : { itemIndex }}) => {
        const tmpItems = [...items];
        tmpItems.splice(itemIndex, 1);
        setItems((tmpItems));
        if (onBlur) onBlur();
      }}
    />
  );
};
