import {
  AppLayout,
  Flashbar,
  FlashbarProps,
} from '@amzn/awsui-components-react';
import { NotificationState, deleteNotification, showNotification } from '../../../redux/reducers/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import BreadCrumbs from '../../../common/breadcrumbs';
import { ERROR_GETTING_DETAIL } from '../../../common/const';
import EngagementDetailContainer from './engagementDetailContainer';
import EngagementDetailHeader from './engagementDetailHeader';
import { GlobalState } from '../../../redux/reducers';
import { ServiceNavigation } from '../../navigation';
import SpinWhileComponent from '../../spinWhile';
import { Type } from '../../../interfaces';
import { UUID } from '../../../util/uuid';
import getHelpPanelContent from '../../../common/tools-content';
import { useIntl } from 'react-intl';

const EngagementDetail = () => {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [toolsTopic, setToolsTopic] = useState('engagementDetail');
  const [engagementName, setEngagementName] = useState('');
  const { formatMessage } = useIntl();

  const { shouldShowNotification, notification } = useSelector<
    GlobalState,
    NotificationState
  >((state) => state.notifications);
  const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldShowNotification && notification) {
      const object = [
        {
          type: notification.type as Type,
          dismissible: notification.dismissible,
          onDismiss: () => setItems([]),
          content: notification.content,
          header: notification.headerText,
          id: UUID(),
        },
      ];
      setItems(object);
    } else if (!shouldShowNotification) {
      setItems([]);
    }
  }, [shouldShowNotification, notification]);

  const updateTools = () => {
    setToolsTopic('engagementDetail');
    setToolsOpen(!toolsOpen);
  };

  const callbackHandler = (engagementName: string, engagementStatus: string, error?: string) => {
    // Once get engagement details call finish, delete notifications first
    if (engagementName !== ERROR_GETTING_DETAIL && !error) { // If no error
      setEngagementName(engagementName);
      if (engagementStatus === 'Processing') {
        dispatch(deleteNotification());
        dispatch(showNotification({
          type: 'warning',
          dismissible: true,
          headerText: formatMessage( {id: 'processing'}),
          content: formatMessage({ id: 'engagementProcessing' }),
          id: UUID()
        }));
      }
    } else if (error) {
      dispatch(deleteNotification())
      setEngagementName('');
      dispatch(showNotification({
        type: 'error',
        dismissible: true,
        headerText: formatMessage({ id: 'getEngagementFailed' }),
        content: error,
        id: UUID()
      }));
    }
    setLoading(false);
  };

  return (
    <AppLayout
      contentHeader={
        <SpinWhileComponent
          loading={loading}
          loadingText={formatMessage({ id: 'loadingEngagementDetails' })}
          size='large'
          align='center'
        >
          {engagementName && (
            <EngagementDetailHeader
              engagementName={engagementName}
              updateTools={updateTools}
            />
          )}
        </SpinWhileComponent>
      }
      toolsOpen={toolsOpen}
      breadcrumbs={BreadCrumbs("engagementDetailBreadcrumbs", formatMessage)}
      onToolsChange={(event) => setToolsOpen(event.detail.open)}
      tools={getHelpPanelContent(toolsTopic, formatMessage)}
      navigation={<ServiceNavigation />}
      navigationOpen={navigationOpen}
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
      content={<EngagementDetailContainer parentCallback={callbackHandler} />}
      notifications={<Flashbar items={items} />}
    />
  );
};

export default EngagementDetail;
