import { ApiHookStatuses, IEngagement } from '../../../interfaces';
import { useEffect, useState } from 'react';

import EngagementsComponent from './engagementsComponent';
import { useListEngagements } from '../../../services/api/engagements-hook';

const EngagementsContainer = () => {
  const [allItems, setAllItems] = useState<IEngagement[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // initial status should be true, otherwise if will show no engagement in the list at the very beginning
  const [fetchError, setFetchError] = useState<any>();
  const [requestArgs, setRequestArgs] = useState<{ token?: string }>({});
  const { status, error, data, refetch: listEngagementsRefetch } = useListEngagements(requestArgs);

  useEffect(() => {
    if (status === ApiHookStatuses.loading && requestArgs.token === '') { // Set requestArgs.token === '' to prevent the page from refresh list
      setLoading(true);
    }
    if (status === ApiHookStatuses.error && error) {
      setLoading(false);
      setFetchError(error.message);
    }
    if (status === ApiHookStatuses.success && data) {
      setLoading(false);
      setAllItems([...data.engagements, ...allItems]);
      if (data.token) {
        setRequestArgs({ token: data.token });
      }
    }
  }, [status, requestArgs]);

  useEffect(() => {
    listEngagementsRefetch(requestArgs);
  }, [requestArgs]);

  return (
    <EngagementsComponent
      fetchError={fetchError}
      allItems={allItems}
      loading={loading}
      loadEngagements={listEngagementsRefetch}
    />
  );
};

export default EngagementsContainer;
