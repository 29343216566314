export const ERROR_GETTING_DETAIL = 'ERROR_GETTING_DETAIL';

export const LINK_WIKI = `https://w.amazon.com/bin/view/AWS_Training_and_Certification/Global_Sales_Strategy_and_Programs/Learning_Needs_Analysis_Tool/`;
export const LINK_1 = `https://w.amazon.com/bin/view/AWS_Training_and_Certification/Global_Sales_Strategy_and_Programs/Learning_Needs_Analysis_Tool/Internationalization/#HAuthorizedCountries`;
export const LINK_2 = `https://w.amazon.com/bin/view/AWS_Training_and_Certification/Global_Sales_Strategy_and_Programs/Learning_Needs_Analysis_Tool/Resource/`;
export const LINK_3 = `https://w.amazon.com/bin/view/AWS_Training_and_Certification/Global_Sales_Strategy_and_Programs/Learning_Needs_Analysis_Tool/Resource/`;
export const LINK_4 = `https://w.amazon.com/bin/view/AWS_Training_and_Certification/Global_Sales_Strategy_and_Programs/Learning_Needs_Analysis_Tool/LNA_Reporting/#HGeneratingLNAReports`;
export const LINK_5 = `https://w.amazon.com/bin/view/AWS_Training_and_Certification/Global_Sales_Strategy_and_Programs/Learning_Needs_Analysis_Tool/How_To_Video_Series/`;
export const LINK_6 = `https://partnercentral.awspartner.com/partnercentral2/s/resources?Id=0690h000009U3ZaAAK`;
export const SERVER_TAKE_TOO_LONG = `SERVER_TAKE_TOO_LONG`;

export const AMAZON_INTERNAL_DOMAIN = [
  'amazon.de',
  'amazon.lu',
  'amazon.fr',
  'amazon.co.uk',
  'amazon.co.jp',
  'amazon.com',
  'amazon.ca',
  'amazon.ie',
  'a9.com',
  'snaptell.net',
  'corp.a9.com',
  'phoneorders.amazon.com',
  'phoneorders.amazon.de',
  'phoneorders.amazon.co.uk',
  'tool-crib.com',
  'toolcrib.amazon.com',
  'innerlinx.com',
  'egghead.com',
  'amazonservices.com',
  'amazonsupply.com',
  'junglee.com',
  'lab126.com',
  'a2z.com',
  'amazon.co.in',
  'india.amazon.com',
  'az.joyo.com',
  'nownow.com',
  'shopapparel.lacoste.com',
  'acigiftcards.amazon.com',
  'endless.com',
  'expressly.com',
  'cs.mands.amazon.com',
  'cs.mands-cate.amazon.com',
  'askville.com',
  'amazonfresh.com',
  'smallparts.com',
  'withoutabox.com',
  'amazonaws.com',
  'javari.jp',
  'boxofficemojo.com',
  'amazonwireless.com',
  'amazon.nl',
  'adzinia.com',
  'a100dco.com',
  'imdb.com',
  'javari.co.uk',
  'amazon.cn',
  'audible.com',
  'audible.de',
  'audible.fr',
  'audible.co.uk',
  'audible.com.au',
  'audible.co.jp',
  'test.createspace.com',
  'cvcorp.com',
  'webstorepowered.co.uk',
  'javari.fr',
  'seller-interest.com',
  'amazon.it',
  'webstoreaoa.co.uk',
  'webstoreaoa-preview.co.uk',
  'zme.com',
  'acx.com',
  'javari.de',
  'myhabit.com',
  'amazon.es',
  'peoplesprodco.com',
  'amazoncapital.com',
  'createspace.com',
  'booksurge.com',
  'customflix.com',
  'amazon.in',
  'amazon.com.br',
  'amazonpayments.co.uk',
  'bookdepository.io',
  'lovefilmintl.com',
  'amazonsubsidiaries.com',
  'inkbigstudios.com',
  'amazonstudios.com',
  'amazonpayments.de',
  'customerservice.dpreview.com',
  'bookdepository.com',
  'amazon.com.mx',
  'amazon.com.au',
  'amazon.ru',
  'amazon-capital.jp',
  'modilis.com',
  'amazonpac.com',
  'shopbop.com',
  'amazon.cz',
  'eastdane.com',
  'woot.com',
  'evi.com',
  'trueknowledge.com',
  'trueknowledge.co.uk',
  'dpreview.com',
  'amazon.co.za',
  'amazonaws.cn',
  'amazonaws.com.cn',
  'liquavista.com',
  'ivona.com',
  'iwebreader.com',
  'ivo.pl',
  'webreader.pl',
  'ivosoftware.com',
  'fabric.com',
  'tenmarks.com',
  'goodreads.com',
  'fillz.com',
  'abebooks.com',
  'quidsi.com',
  'kivasystems.com',
  'zappos.com',
  'info.myhabit.com',
  'events.myhabit.com',
  'paywithamazon.junglee.com',
  'orders.myhabit.com',
  'alexa.com',
  'brilliancepublishing.com',
  'comixology.com',
  'support.zappos.com',
  'amazonsupply.in',
  'amazon.pl',
  'support.zapposcouture.com',
  'support.6pm.com',
  'amazonlocalbusiness.com',
  'amzn.com',
  'blindsight.com',
  'filmfinders.com',
  '1800diapers.com',
  'afterschool.com',
  'beautybar.com',
  'bookworm.com',
  'casa.com',
  'diapers.com',
  'diaperscorp.com',
  'familyhood.com',
  'look.com',
  'soap.com',
  'vine.com',
  'vinemarket.com',
  'wag.com',
  'yoyo.com',
  'rooftopmedia.net',
  'rooftopcomedy.com',
  'annapurnalabs.com',
  'amazonbusiness.in',
  'amazoncapitalservices.co.uk',
  'shoefitr.com',
  'imdbpro.com',
  'audible.it',
  'amazon.com.tr',
  'amazon.co.th',
  'amazon.my',
  'amazon.ph',
  'amazon.fi',
  'amazon.ch',
  'emvantage.com',
  'nice-software.com',
  'amazon.at',
  'aws.sinnet.com.cn',
  'bounces.sinnet.com.cn',
  'audible.in',
  'elemental.com',
  'elementaltechnologies.com',
  'amazon.com.sg',
  'amazon.ae',
  'amazon.com.sa',
  'amazon.com.bh',
  'amazon.com.vn',
  'audible.ca',
  'kindleauthorservices.com',
  'primevideo.com',
  'beijing.amazonaws.cn',
  'ningxia.amazonaws.cn',
  'amazon.id',
  'amazon.com.ar',
  'cs.comixology.com',
  'comixology.co.uk',
  'comixology.eu',
  'comixology.fr',
  'c9.io',
  'amazon.pt',
  'bookdepository.co.uk',
  'support.bookdepository.co.uk',
  'support.bookdepository.com',
  'amazon.sa',
  'amazon.eg',
  'thinkboxsoftware.com',
  'sqrrl.com',
  'audible.es',
  'amazondistribution.in',
  'amazon.sg',
  'theclimatepledge.com',
  'amazon.se',
  'amazon.cl',
  'canvas.technology',
  'canvas-technology.com',
  'amazonpay.in',
  'sizmek.com',
  'mod.heighliner.a2z.com',
  'filter.mod.heighliner.a2z.com',
  'amazon.com.kw',
  'brillianceaudio.com',
  'ring.com',
  'amazon.ro',
  'wondery.com',
  'amazon.com.be',
  'amazon.com.ng',
  'amazon.com.co',
  'veeqo.com'
];