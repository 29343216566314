import { GetUserListRequest, GetUserListResponse } from '../api/user-hook';

import Cache from '@aws-amplify/cache';
import { delay } from '../../util/delay';
import { isJsonString } from '../../util/isJsonString';
import store from '../../redux/store';
import { redirectToLoginPage } from "../../util/amplify";

export type GetUserRoleResponse = {
  roles: string[];
};

export type UpdateUserPermissionsRequest = {
  userId: string;
  roles: string[];
  partnerId?: string;
  partnerName?: string;
};

export type UpdateUserPermissionsResponse = {
  userId: string;
};

export type AddPermissionsRequest = {
  userId: string;
  roles: string[];
  partnerId?: string;
  partnerName?: string;
};

export type AddPermissionsResponse = {
  userId: string;
};

export type RemovePermissionsRequest = {
  userId: string;
};

export type RemovePermissionsResponse = {
  userId: string;
};

class UserDAO {
  authToken!: string;
  endpoint!: string;

  constructor() {
    this.init();
  }

  init = () => {
    const { api: { endpoint } } = store.getState();
    this.authToken = Cache.getItem('federatedInfo')?.token;
    this.endpoint = endpoint;
  };

    /**
   *
   * @param url {string} The URL endpoint that API will call to
   * @param config {object} https://developer.mozilla.org/en-US/docs/Web/API/fetch
   * @param delayTime {number} How long this API will wait before it call the endpoint, this is temporary, to wait to other config files finish loading
   * @param errorMessage {string} The default error message
   * @returns {JSON} The response, right now only expect all data returned as JSON
   */
    call = async (url: string, config?: RequestInit, delayTime?: number, errorMessage?:string): Promise<any> => {

      if (delayTime) {
        await delay(delayTime);
      }

      // Magic line, don't modify this block, if you don't know what you're doing, stop
      // Maximum retry attempts
      const maxRetries = 10;
      let retries = 0;
      while ((this.endpoint === '' || this.authToken === '') && retries < maxRetries) {
        await delay(500); // Wait for half second before retrying
        this.init();
        retries++;
      }
      if (retries === maxRetries) {
        throw new Error('Maximum retries hit, still no token.')
     }
      // Magic line, don't modify this block, if you don't know what you're doing, stop

      const configWithToken = { ...config, headers: { 'Authorization': this.authToken }}
      try {
        const response = await fetch(`${this.endpoint}/${url}`, configWithToken);
        if (response.ok) {
          return response.json();
        }
        return response.text().then( text => {
          if (isJsonString(text)) {
            const obj = JSON.parse(text);
            if("Unverified Email" == obj.MessageTitle) {
              redirectToLoginPage(true)
            }
            throw new Error(obj.Message || obj.message || errorMessage);
          } else {
            throw new Error(text);
          }
        });
      } catch (error) {
        console.error(error);
      }
    };

    getUserRole = async (): Promise<GetUserRoleResponse> => {
      return this.call(`permission`, {
        method: 'GET',
        mode: 'cors'
      }, 500);
    };

    getUserLists = async (req: GetUserListRequest): Promise<GetUserListResponse> => {
      const queryParameters = new URLSearchParams();

      if (req.pageSize) queryParameters.set('pageSize', req.pageSize);
      if (req.sort) queryParameters.set('sort', req.sort);
      if (req.token) queryParameters.set('token', req.token);
      const queryString = queryParameters.toString();
      return this.call(`users/?${queryString}`, {
        method: 'GET',
        mode: 'cors'
      }, 500, 'Failed to get user list');
    };

    updateUserPermissions = async (req: UpdateUserPermissionsRequest): Promise<UpdateUserPermissionsResponse> => {
      return this.call(`permission`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
      }, 0, 'Failed to update user permission');
    };

    addPermissions = async (req: AddPermissionsRequest): Promise<AddPermissionsResponse> => {
      return this.call(`permission`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
      }, 0, 'Failed to add user permission');
    };

    removePermissions = async (req: RemovePermissionsRequest): Promise<RemovePermissionsResponse> => {
      return this.call(`permission/removal`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
      }, 0, 'Failed to delete user');
    };
}

export const userDAO = new UserDAO();
