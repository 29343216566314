import { ApiHookResult, ApiMutateHookResult } from '../../interfaces';
import {
  CreateEngagementRequest,
  CreateEngagementResponse,
  DownloadResponseRequest,
  EditEngagementRequest,
  EditEngagementResponse,
  GetEngagementRequest,
  GetEngagementResponse,
  ListEngagementRequest,
  ListEngagementsResponse,
  ReopenEngagementRequest,
  ReopenEngagementResponse,
  engagementsDAO
} from '../DAO/engagementsDAO';
import { useMutation, useQuery } from '@tanstack/react-query';

import { queryClient } from '../../components/App';

export const useListEngagements = (req: ListEngagementRequest): ApiHookResult<ListEngagementsResponse, Error> => {
  const result = useQuery({
    queryKey: ['listEngagements', req.token],
    queryFn: () => engagementsDAO.listEngagements(req),
    cacheTime: 0, // Set cache time to 0
  });
  return {
    status: result.status,
    data: result.data,
    error: result.error as Error,
    refetch: result.refetch
  }
}

export const useGetEngagement = (req: GetEngagementRequest): ApiHookResult<GetEngagementResponse, Error> => {
  const result = useQuery({
    queryKey: ['getEngagement', req.engagementId],
    queryFn: () => engagementsDAO.getEngagement(req),
    enabled: !!req.engagementId,
  });
  return {
    status: result.status,
    data: result.data,
    error: result.error as Error,
    refetch: result.refetch
  }
}

export const useCreateEngagement = (): ApiMutateHookResult<CreateEngagementResponse, CreateEngagementRequest, Error> => {
  const { data, error, status, mutate } = useMutation(
    (req: CreateEngagementRequest) => engagementsDAO.createEngagement(req),
    {
      mutationKey: ['createEngagement'],
      onSettled: async () => {
        await new Promise(resolve => setTimeout(resolve, 1000)) // Wait for 1 second, this is magic line, don't delete this otherwise you will see stale data due to our backend is still running
        queryClient.invalidateQueries({ queryKey: ['listEngagements'] }) // Then invalidate list Engagement query so that the list gets updated
      }
    }
  )
  return {
    mutate: mutate,
    status: status,
    data: data,
    error: error as Error
  }
}


export const useDownloadResponse = (): ApiMutateHookResult<any, DownloadResponseRequest, Error> => {
  const { data, error, status, mutate } = useMutation(
    (req: DownloadResponseRequest) => engagementsDAO.downloadResponse(req),
    {
      mutationKey: ['downloadResponse']
    }
  );
  return {
    mutate: mutate,
    status: status,
    data: data,
    error: error as Error
  }
}

export const useEditEngagement = (): ApiMutateHookResult<EditEngagementResponse, EditEngagementRequest, Error> => {
  const { data, error, status, mutate } = useMutation(
    (req: EditEngagementRequest) => engagementsDAO.editEngagement(req),
    {
      mutationKey: ['editEngagement'],
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ['getEngagement', res.engagementId]})
      }
    }
  );
  return {
    mutate: mutate,
    status: status,
    data: data,
    error: error as Error
  }
}

export const useReopenEngagement = (): ApiMutateHookResult<ReopenEngagementResponse, ReopenEngagementRequest, Error> => {
  const { data, error, status, mutate } = useMutation(
      (req: ReopenEngagementRequest) => engagementsDAO.reopenEngagement(req),
      {
        mutationKey: ['reopenEngagement'],
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: ['getEngagement', res.engagementId]})
        }
      }
  );
  return {
    mutate: mutate,
    status: status,
    data: data,
    error: error as Error
  }
}
