import {
  Alert,
  Button,
  CollectionPreferences,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Table
} from '@amzn/awsui-components-react';

import { EmptyState } from '../../../common/table-config';
import EngagementsHeader from './engagementsHeader';
import { IEngagement } from '../../../interfaces';
import { getMatchesCountText } from '../../../util/get-match-count';
import { getTableConfig } from './table-config';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useIntl } from 'react-intl';
import { useState } from 'react';

export interface EngagementsProps {
  fetchError?: string;
  allItems?: IEngagement[];
  loading: boolean;
  loadEngagements: any;
}

const EngagementsComponent = (props: EngagementsProps) => {
  const { formatMessage } = useIntl();
  const { allItems, fetchError, loading, loadEngagements } = props;
  const tableConfigs = getTableConfig(formatMessage)
  const [preferences, setPreferences] = useState(tableConfigs.defaultPreferences);
  const count = allItems?.length || 0;


  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(allItems || [], {
    pagination: {
      pageSize: preferences.pageSize,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'engagementCreationDate'
        },
        isDescending: true
      }
    },
    propertyFiltering: {
      filteringProperties: tableConfigs.filteringProperties,
      empty: (
        <EmptyState
          title={formatMessage({ id: 'noEngagement' })}
          subtitle={formatMessage({ id: 'noEngagementSub' })}
          action={
            <Button
              href='#/lna-creation'
              variant='primary'
            >
              {formatMessage({ id: 'createEngagement' })}
            </Button>
          }
        />
      ),
      noMatch: (
        <EmptyState
          title={formatMessage({ id: 'noMatch' })}
          subtitle={formatMessage({ id: 'noMatchSub' })}
          action={
            <Button
              onClick={() =>
                actions.setPropertyFiltering({ tokens: [], operation: 'and' })
              }
            >
              {formatMessage({ id: 'clearFilter' })}
            </Button>
          }
        />
      ),
    },
  });

  return (
    <SpaceBetween size='m'>
      {fetchError && (
        <Alert
          type='error'
          action={<Button onClick={() => loadEngagements()}>{formatMessage({ id: 'retry' })}</Button>}
        >
          {fetchError}
        </Alert>
      )}
      {!fetchError && (
        <Table
          {...collectionProps}
          visibleColumns={preferences.visibleContent}
          wrapLines={preferences.wrapLines}
          loading={loading}
          loadingText={formatMessage({ id: 'loadingResources' })}
          resizableColumns
          items={items}
          variant='full-page'
          columnDefinitions={tableConfigs.columnDefinitions}
          header={<EngagementsHeader count={count} />}
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={tableConfigs.paginationLabels}
            />
          }
          filter={
            <PropertyFilter
              i18nStrings={tableConfigs.propertyFilteringI18nConstants}
              {...propertyFilterProps}
              countText={getMatchesCountText(filteredItemsCount, formatMessage)}
              expandToViewport={true}
            />
          }
          preferences={
            <CollectionPreferences
              preferences={preferences}
              onConfirm={(event) => setPreferences(event.detail)}
              title={formatMessage({id: 'preferences'})}
              confirmLabel={formatMessage({id: 'confirm'})}
              cancelLabel={formatMessage({id: 'cancel'})}
              visibleContentPreference={{
                title: formatMessage({id: 'selectVisibleColumns'}),
                options: tableConfigs.contentSelectorOptions,
              }}
              pageSizePreference={{
                title: formatMessage({id: "pageSize"}),
                options: tableConfigs.pageSelectorOptions,
              }}
            />
          }
        />
      )}
    </SpaceBetween>
  );
};

export default EngagementsComponent;
