/** Utility function to create a K:V from a list of strings
 *
 * @param {Array<T extends string>} o
 * @return {{[K in T]:K}}
 *
 * Sample usage:
 * ------------
 *  // Create a K:V
 *  const Directions = strEnum([
 *      'North',
 *      'South',
 *      'East',
 *      'West'
 *  ]);
 *
 *  // Create a Type
 *  type Direction = keyof typeof Directions;
 *
 *  // Using a string enum
 *  let sample: Direction;
 *
 *  sample = Directions.North; // Okay
 *  sample = 'North'; // Okay
 *  sample = 'AnythingElse'; // ERROR!
 *
 *  NOTE: We should stop using `enum` for string constants, and
 *  rather use this helper method to create constants properly.
 */
export const strEnum = <T extends string>(o: T[]): { [K in T]: K } => {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create({}));
};
