import { createSlice } from '@reduxjs/toolkit';

export type UserState = {
  email?: string;
  roles: string[];
  fullName?: string;
  publicProviderName: string;
};

const initialState: UserState = {
  email: '',
  roles: [],
  fullName: '',
  publicProviderName: ''
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      for (const key in state) {
        if (action.payload.hasOwnProperty(key)) {
          state[key as keyof UserState] = action.payload[key];
        }
      }
    }
  }
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
