import '../styles/index.scss';

import { FormattedMessage, IntlProvider } from 'react-intl';
import { ILanguageObject, merge } from '../common/mergeObject';
import { LnaCreationForm, LnaEditForm, LnaReopenForm } from './LNA/lna-form';
import {
  NotificationState,
  deleteNotification,
  markNewLocationDone,
} from '../redux/reducers/notificationReducer';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import AccountDetails from './SIP/accountDetails';
import Accounts from './SIP/accountList';
import { ApiHookStatuses } from '../interfaces';
import { Authenticator } from './authenticator/Authenticator';
import Brazil from '../strings/pt_BR.json';
import Chinese from '../strings/zh_CN.json';
import Dashboard from './SIP/dashboard';
import EngagementDetail from './LNA/engagementDetail';
import Engagements from './LNA/engagements';
import English from '../strings/en.json';
import FeedbackModal from '../modals/feedbackModal';
import France from '../strings/fr.json';
import Germany from '../strings/de.json';
import { GlobalState } from '../redux/reducers';
import HardChinese from '../strings/zh_TW.json';
import Italy from '../strings/it.json';
import Japanese from '../strings/ja.json';
import Korean from '../strings/ko.json';
import LNATopNavigation from './navigation/top-navigation';
import { LanguageState } from '../redux/reducers/languageReducer';
import NotFound from './404';
import ServiceHomepage from './home';
import Spain from '../strings/es.json';
import { Spinner } from '@amzn/awsui-components-react';
import UserList from './LNA/users/user-list';
import { configureAmplify } from '../util/amplify';
import { displaySip } from '../util/displaySip';
import hasFeature from '../util/hasFeature';
import { setApiConfig } from '../redux/reducers/apiReducer';
import { setUser } from '../redux/reducers/userInfoReducer';
import { useGetUserRole } from '../services/api/user-hook';

const Main = () => {
  const [message, setMessage] = useState<any>(English);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { currentLocale } = useSelector<GlobalState, LanguageState>(
    (state) => state.language
  );
  const [loadingInfo, setLoadingInfo] = useState<boolean>(true);
  const { shouldShowNotification, notification } = useSelector<
    GlobalState,
    NotificationState
  >((state) => state.notifications);
  const { data: userData, status: userStatus } = useGetUserRole();
  const [containsUserPage, setContainsUserPage] = useState<boolean>(false);
  const displaySIP = displaySip();
  const displayFeedback = hasFeature('feedback');
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  const openFeedbackModal = () => {
    setModalVisibility(true);
  };

  const closeFeedbackModal = () => {
    setModalVisibility(false);
  };

  useEffect(() => {
    if (userStatus === ApiHookStatuses.loading) {
      setContainsUserPage(false);
      setLoadingInfo(true);
    } else if (userStatus === ApiHookStatuses.error) {
      setContainsUserPage(false);
      setLoadingInfo(false);
      dispatch(setUser({ roles: [] }));
      navigate('/no-role');
    } else if (userStatus === ApiHookStatuses.success && userData) {
      setLoadingInfo(false);
      const { roles } = userData;
      dispatch(setUser({ roles }));

      if (userData.roles.some((el) => el.indexOf('admin') >= 0)) {
        setContainsUserPage(true);
      } else {
        setContainsUserPage(false);
      }
    }
  }, [userStatus]);

  // We can refactor this logic later
  let ChineseObject = Chinese as ILanguageObject;
  ChineseObject = merge(English, Chinese);

  let GermanyObject = Germany as ILanguageObject;
  GermanyObject = merge(English, Germany);

  let SpainObject = Spain as ILanguageObject;
  SpainObject = merge(English, Spain);

  let FranceObject = France as ILanguageObject;
  FranceObject = merge(English, France);

  let ItalyObject = Italy as ILanguageObject;
  ItalyObject = merge(English, Italy);

  let JapaneseObject = Japanese as ILanguageObject;
  JapaneseObject = merge(English, Japanese);

  let KoreanObject = Korean as ILanguageObject;
  KoreanObject = merge(English, Korean);

  let BrazilObject = Brazil as ILanguageObject;
  BrazilObject = merge(English, Brazil);

  let HardChineseObject = HardChinese as ILanguageObject;
  HardChineseObject = merge(English, HardChinese);

  useEffect(() => {
    switch (currentLocale) {
      case 'en':
        setMessage(English);
        break;
      case 'zh_CN':
        setMessage(ChineseObject);
        break;
      case 'de':
        setMessage(GermanyObject);
        break;
      case 'es':
        setMessage(SpainObject);
        break;
      case 'fr':
        setMessage(FranceObject);
        break;
      case 'it':
        setMessage(ItalyObject);
        break;
      case 'ja':
        setMessage(JapaneseObject);
        break;
      case 'ko':
        setMessage(KoreanObject);
        break;
      case 'pt_BR':
        setMessage(BrazilObject);
        break;
      case 'zh_TW':
        setMessage(HardChineseObject);
        break;
      default:
        setMessage(English);
        break;
    }
  }, [currentLocale]);
  /**
   * We need to fetch the endpoint and stage info for all api calls and we need to do this only once when the App starts
   */
  useEffect(() => {
    const getEndpoint = async () => {
      const res = await fetch('/settings.json', {
        method: 'GET',
        mode: 'cors',
      });
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const data = await res.json();
      dispatch(
        setApiConfig({
          stage: data.STAGE,
          endpoint: data.API_ENDPOINT,
        })
      );
      return data;
    };

    getEndpoint().catch(console.error);
  }, []);

  configureAmplify();

  // This function will be executed every time this is an URL change
  useEffect(() => {
    // Notification will be shown on new page only when displayInNewLocation === true and displayInNewLocationDone === false
    if (
      notification?.displayInNewLocation === true &&
      notification?.displayInNewLocationDone === false
    ) {
      dispatch(markNewLocationDone());
    } else if (shouldShowNotification) {
      // We want to delete notifications once we are on new page to have a clean start, add this shouldShowNotification === true check to reduce necessary actions
      dispatch(deleteNotification());
    }
  }, [location]);

  return (
    <IntlProvider
      locale='en'
      messages={message}
      defaultLocale='en'
    >
      <Authenticator globals={window}>
        <div className='awsui-visual-refresh'>
          <LNATopNavigation />
          {loadingInfo && (
            <div className='loading-page'>
              <Spinner size='large' />
              <FormattedMessage id='loadingPage' />
            </div>
          )}
          {!loadingInfo && (
            <>
              <Routes>
                <Route
                  path='/'
                  element={<ServiceHomepage />}
                />
                <Route
                  path='/lna-creation'
                  element={<LnaCreationForm />}
                />
                <Route
                  path='/engagements'
                  element={<Engagements />}
                />
                <Route
                  path='/engagement-detail/:engagementId'
                  element={<EngagementDetail />}
                />
                <Route
                  path='/edit-engagement/:engagementId'
                  element={<LnaEditForm />}
                />
                <Route
                  path='/reopen-engagement/:engagementId'
                  element={<LnaReopenForm />}
                />
                {displaySIP && (
                  <>
                    <Route
                      path='/sales-accounts'
                      element={<Accounts />}
                    />
                    <Route
                      path='/sales-accounts/account-details/:accountId'
                      element={<AccountDetails />}
                    />
                    <Route
                      path='/sales-accounts/dashboard'
                      element={<Dashboard />}
                    />
                  </>
                )}
                {containsUserPage && (
                  <Route
                    path='/users'
                    element={<UserList />}
                  />
                )}
                <Route
                  path='*'
                  element={<NotFound />}
                />
              </Routes>
              <div
                className='footer lna-footer'
                id='consoleNavFooter'
              >
                {displayFeedback && (
                  <div
                    className='feedback'
                    onClick={openFeedbackModal}
                  >
                    <div className='link'>
                      <a
                        className='pointer'
                        rel='noreferrer'
                        title='Feedback'
                      >
                        <FormattedMessage id='feedback' />
                      </a>
                    </div>
                  </div>
                )}
                <div className='link'>
                  <a
                    title='Public FAQ'
                    href='https://support.aws.amazon.com/#/contacts/aws-training'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FormattedMessage id='support' />
                  </a>
                  <a
                    title='Legal'
                    href='https://aws.amazon.com/legal/?nc1=f_cc'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FormattedMessage id='legal' />
                  </a>
                  <a
                    title='Privacy'
                    href='https://aws.amazon.com/privacy/?src=aws_training'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FormattedMessage id='privacy' />
                  </a>
                </div>
                <div className='dis'>
                  <FormattedMessage id='dis' />
                </div>
              </div>
            </>
          )}
          {displayFeedback && (
            <FeedbackModal
              visible={modalVisibility}
              onDismissHandler={closeFeedbackModal}
              onConfirmHandler={closeFeedbackModal}
              onCancelHandler={closeFeedbackModal}
            />
          )}
        </div>
      </Authenticator>
    </IntlProvider>
  );
};

export default Main;
