const images = [
  `https://images-na.ssl-images-amazon.com/images/G/01/error/173._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/129._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/168._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/166._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/73._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/25._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/143._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/10._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/35._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/150._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/102._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/4._TTD_.jpg,`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/89._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/107._TTD_.jpg`,
  `https://images-na.ssl-images-amazon.com/images/G/01/error/127._TTD_.jpg`
];

function getRandomIndex(images: string[]) {
  const randomIndex = Math.floor(Math.random() * images.length);
  return randomIndex;
}

const randomIndex = getRandomIndex(images);

export const getADog = images[randomIndex];