import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from '@amzn/awsui-components-react';

import { useIntl } from 'react-intl';

type CancelModalProps = {
  visible: boolean;
  onDismissHandler: () => void;
  onConfirmHandler: () => void;
  onCancelHandler: () => void;
};

const CancelModal = (props: CancelModalProps) => {
  const { formatMessage } = useIntl();
  const { visible, onDismissHandler, onConfirmHandler, onCancelHandler } = props;

  return (
    <Modal
      className='awsui-visual-refresh'
      onDismiss={onDismissHandler}
      visible={visible}
      closeAriaLabel={formatMessage({ id: 'closeModal' })}
      header={formatMessage({ id: 'confirmCancel' })}
      footer={
        <ModalFooter
          onConfirmHandler={onConfirmHandler}
          onCancelHandler={onCancelHandler}
        />
      }
    >
      <p>{formatMessage({ id: 'cancelModalContent' })}</p>
    </Modal>
  );
};

const ModalFooter = (props: {
  onConfirmHandler: () => void;
  onCancelHandler: () => void;
}) => {
  const { formatMessage } = useIntl();
  const { onConfirmHandler, onCancelHandler } = props;
  return (
    <Box float='right'>
      <SpaceBetween
        direction='horizontal'
        size='xs'
      >
        <Button
          variant='link'
          onClick={onCancelHandler}
        >
          {formatMessage({ id: 'nevermind' })}
        </Button>
        <Button
          variant='primary'
          onClick={onConfirmHandler}
        >
          {formatMessage({ id: 'confirmCancel' })}
        </Button>
      </SpaceBetween>
    </Box>
  );
};

export default CancelModal;
