import {
  Button,
  Header,
  NonCancelableCustomEvent,
  SpaceBetween,
  Toggle,
  ToggleProps,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

export interface AccountsHeaderProps {
  count: number;
  assignedToMe: boolean;
  callbackToParent: any;
}

const AccountsHeader = (props: AccountsHeaderProps) => {
  const { count, assignedToMe, callbackToParent } = props;
  const { formatMessage } = useIntl();

  const toggleOnChangeHandler = (
    event: NonCancelableCustomEvent<ToggleProps.ChangeDetail>
  ) => {
    callbackToParent(event.detail.checked);
  }

  return (
    <SpaceBetween
      direction='horizontal'
      size='l'
    >
      <Header
        variant='awsui-h1-sticky'
        counter={`(${count})`}
        description={formatMessage({ id: 'accountsListDescription' })}
      >
        {formatMessage({ id: 'salesAccounts' })}
      </Header>
      <Toggle
        onChange={toggleOnChangeHandler}
        checked={assignedToMe}
        name='assignedToMe'
        data-test='assignedToMe'
        id='assignedToMe'
      >
        {formatMessage({ id: 'assignedToMe' })}
      </Toggle>
    </SpaceBetween>
  );
};

export default AccountsHeader;
