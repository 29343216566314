import { Box } from '@amzn/awsui-components-react';

export const ValueWithLabel = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <div>
    <Box variant='awsui-key-label'>{label}</Box>
    <div>{children}</div>
  </div>
);

