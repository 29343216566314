import { ApiHookStatuses, IEngagement } from '../../../interfaces';
import { useEffect, useState } from 'react';

import { ERROR_GETTING_DETAIL } from '../../../common/const';
import EngagementDetailComponent from './engagementDetailComponent';
import { GlobalState } from '../../../redux/reducers';
import { useGetEngagement } from '../../../services/api/engagements-hook';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export type EngagementDetailProps = {
  engagementId: string;
};

export type EngagementDetailContainerProps = {
  parentCallback: (engagementName: string, engagementStatus: string, error?: string) => void;
};

const EngagementDetailContainer = ({
  parentCallback,
}: EngagementDetailContainerProps) => {
  const { engagementId = '' } = useParams();
  const { data, status, error } = useGetEngagement({ engagementId });
  const roles = useSelector<GlobalState, string[]>(state => state.user.roles);
  const [details, setDetails] = useState<IEngagement>();

  useEffect(() => {
    if (status === ApiHookStatuses.success && data) {
      setDetails(data);
      parentCallback(data.engagementName, data.engagementStatus);
    }
    if (status === ApiHookStatuses.error && error) {
      parentCallback(ERROR_GETTING_DETAIL, data?.engagementStatus || '', error.message);
    }
  }, [status, data]);

  return (
    <>
      {details && (
        <EngagementDetailComponent
          percentCompleted={
            Math.round(
              ((details.completedResponses || 0) /
                (details.estimatedNumberOfParticipants || 100)) *
              100
            ) + '%'
          }
          inProgress={details.inProgressResponses}
          completed={details.completedResponses}
          total={details.estimatedNumberOfParticipants || 100}
          accountName={details.accountName}
          sfdcID={details.accountSFDCId}
          closeDate={new Date(details.engagementCloseDate).toISOString().split('T')[0]}
          type={details.engagementType}
          status={details.engagementStatus}
          isIndividual={details.displayIndividualRecommendations}
          isTest={details.internalAWSTestEngagement}
          teams={details.teams}
          engagementId={details.engagementId}
          creatorEmail={details.engagementCreatedBy}
          collaborators={details.collaborators}
          partnerId={details.partnerId}
          partnerName={details.partnerName}
          userRole={roles}
        />
      )}
    </>
  );
};

export default EngagementDetailContainer;
