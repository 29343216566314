import './index.scss';

import { Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';

import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { GlobalState } from '../../redux/reducers';
import { NotificationState } from '../../redux/reducers/notificationReducer';
import { ServiceNavigation } from '../navigation';
import { Type } from '../../interfaces';
import { UUID } from '../../util/uuid';
import { appLayoutNavigationLabels } from '../../common/labels';
import { getADog } from './imageUrls';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export default function NotFound() {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const { shouldShowNotification, notification } = useSelector<GlobalState, NotificationState>((state) => state.notifications);
  const [navigateHide, setNavigateHide] = useState(false);
  const roles = useSelector<GlobalState, string[]>(state => state.user.roles);

  useEffect(() => {
    if (roles == undefined|| roles.length === 0) {
      setNavigateHide(true);
    } else {
      setNavigateHide(false);
    }
  }, [roles]);

  useEffect(() => {
    if (shouldShowNotification && notification) {
      const object = [
        {
          type: notification.type as Type,
          dismissible: notification.dismissible,
          onDismiss: () => setItems([]),
          content: notification.content,
          header: notification.headerText,
          id: UUID(),
        },
      ];
      setItems(object);
    } else if (!shouldShowNotification) {
      setItems([]);
    }
  }, [shouldShowNotification, notification]);

  return (
    <AppLayout
      headerSelector='#h'
      className='404'
      toolsOpen={false}
      toolsHide={true}
      navigation={<ServiceNavigation />}
      navigationHide={navigateHide}
      content={<Content />}
      navigationOpen={navigationOpen}
      onNavigationChange={event => setNavigationOpen(event.detail.open)}
      ariaLabels={appLayoutNavigationLabels}
      notifications={<Flashbar items={items} />}
    />
  );
}

const Content = () => {
  const { formatMessage } = useIntl();
  const roles = useSelector<GlobalState, string[]>(state => state.user.roles);
  let message = formatMessage({ id: '404Content' }, { a: (...str: any) => (<a key={str && str.toString()} href='/'>{str}</a>)});
  if (roles.length === 0) {
    message = formatMessage({ id: 'noRoleError' });
  }

  return (
    <div className='404 main'>
      <div className='error-content'>
        <h2>{formatMessage({ id: 'sorry' })}</h2>
        <p className='error-text'>
          {message}
        </p>
      </div>
      <img id='dog' alt={formatMessage({ id: 'dogOfAmzn' })} src={getADog}></img>
    </div>
  )
}