import './index.scss';

import * as React from 'react';

import { Spinner } from '@amzn/awsui-components-react';

/**
 * A component that displays spinner or child content,
 * depending on `loading` flag passed to the component.
 * loading: true - shows spinner
 * loading: false - shows content inside spinner.
 *
 * Usage:
 * `<SpinWhile loading={true}> <some-content> </SpinWhile>`
 */
type Props = {
    /**
     * Flag to indicate if the data is loading or loaded.
     * By default loading is always false.
     */
    loading: boolean;
    /**
     * Content to be displayed when the flag `loading` is false.
     * All the text inside this has to be localized by consumer.
     */
    children: React.ReactNode;
    /**
     * Size of the spinner, by default it's big
     */
    size?: "normal" | "big" | "large";
    /**
     * Loading text, by default it's "Loading Resources"
     */
    loadingText?: string;
    /**
     * Alignment of spinner icon, by default it's center
     */
    align?: "left" | "right" | "center";
    /**
     * Check to see if you want to use bridge themed DocLink. Default is false.
     */
    isBridge?: boolean;
    /**
     * Remove the additional padding around the spinning icon
     */
    noPadding?: boolean;
};

class SpinWhileComponent extends React.PureComponent<Props> {

    render(): React.ReactElement<any> {
        const {loading, children, size, loadingText, align, noPadding} = this.props;
        const alignment: string = align ? 'spinner-loading-content-center-align' : `spinner-loading-content-${align}-align`;
        const padding: string = noPadding ? '' : 'awsui-util-p-l';

        if (loading) {
            let styleClasses = `spinner-loading-content spinner-loading-icon ${padding} ${alignment}`;
            return (
                <div className={styleClasses}>
                    <Spinner size={ size || 'big' }/>
                    {loadingText ? <h3 className='spinner-loading-text awsui-util-pl-s'>{loadingText}</h3> : null}
                </div>
            );
        }

        return (
            <div className="spinner__loaded-content">
                { children }
            </div>
        );

    }
}

export default SpinWhileComponent;
