/// <reference path="../../index.d.ts" />
import {
  Container,
  Grid,
  Link,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import {
  LINK_1,
  LINK_2,
  LINK_3,
  LINK_4,
  LINK_5,
  LINK_6,
  LINK_WIKI,
} from '../../common/const';

import { GlobalState } from '../../redux/reducers';
import { Optional } from '../../interfaces';
import logo from './LNA_Roadmap.png';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export default function SipContent() {
  const { formatMessage } = useIntl();
  const provider = useSelector<GlobalState, Optional<string>>(
    (state) => state.user.publicProviderName
  );

  return (
    <SpaceBetween
      size='l'
      className='sip-homepage-container'
    >
      <Grid
        gridDefinition={[
          { colspan: { m: 6, xxs: 12 } },
          { colspan: { m: 6, xxs: 12 } },
        ]}
      >
        <Container>
          <h3>{formatMessage({ id: 'homeQ1' })}</h3>
          <p className='bottom-grey'>{formatMessage({ id: 'homeA1' })}</p>
          <h3>{formatMessage({ id: 'homeQ2' })}</h3>
          <p>{formatMessage({ id: 'homeA2' })}</p>
          <ul>
            <li>{formatMessage({ id: 'homeA2a' })}</li>
            <li>{formatMessage({ id: 'homeA2b' })}</li>
            <li>{formatMessage({ id: 'homeA2c' })}</li>
            <li>{formatMessage({ id: 'homeA2d' })}</li>
            <li>{formatMessage({ id: 'homeA2e' })}</li>
          </ul>
          <p>{formatMessage({ id: 'homeA2f' })}</p>
          <h3>{formatMessage({ id: 'homeQ3' })}</h3>
          <p>{formatMessage({ id: 'homeA3' })}</p>
          <img
            src={logo}
            alt='LNA process'
            className='logo'
          />
          {provider !== 'AWSPartnerNetwork' && (
            <>
              <h2>{formatMessage({ id: 'lnaResources' })}</h2>
              <Link href={LINK_WIKI}>{formatMessage({ id: 'wiki' })}</Link>
              <Link href={LINK_1}>{formatMessage({ id: 'link1' })}</Link>
              <Link href={LINK_2}>{formatMessage({ id: 'link2' })}</Link>
              <Link href={LINK_3}>{formatMessage({ id: 'link3' })}</Link>
              <Link href={LINK_4}>{formatMessage({ id: 'link4' })}</Link>
              <Link href={LINK_5}>{formatMessage({ id: 'link5' })}</Link>
            </>
          )}
          {provider === 'AWSPartnerNetwork' && (
            <>
              <h2>{formatMessage({ id: 'apnResources' })}</h2>
              <Link href={LINK_6}>{formatMessage({ id: 'apnLink' })}</Link>
            </>
          )}
        </Container>
        <Container>
          <h3>{formatMessage({ id: 'sip' })}</h3>
          <p>{formatMessage({ id: 'sip-p1' })}</p>
          <p>{formatMessage({ id: 'sip-p2' })}</p>
          <p className='bottom-grey'>{formatMessage({ id: 'sip-p3' })}</p>
          <h3>{formatMessage({ id: 'sip-impact' })}</h3>
          <ul>
            <li>{formatMessage({ id: 'sip-impact1' })}</li>
            <li>{formatMessage({ id: 'sip-impact2' })}</li>
            <li>{formatMessage({ id: 'sip-impact3' })}</li>
            <li>{formatMessage({ id: 'sip-impact4' })}</li>
            <li>{formatMessage({ id: 'sip-impact5' })}</li>
          </ul>
        </Container>
      </Grid>
    </SpaceBetween>
  );
}
