import { Link } from "@amzn/awsui-components-react";

export type InfoLinkProps = {
  id: string,
  onFollow: () => any
};

export const InfoLink = ({ id, onFollow}: InfoLinkProps) => (
  <Link variant='info' id={id} onFollow={onFollow}>Info</Link>
);
