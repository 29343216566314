// Promise polyfill
import 'core-js/features/promise';
import '@amzn/awsui-global-styles/polaris.css';

import App from './components/App';
import { HashRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

document.addEventListener('DOMContentLoaded', () => {
  createRoot(document.querySelector('#app')!).render(
    <HashRouter>
      <App />
    </HashRouter>
  );
});
