/**
 * Magic function, it does complex logic to convert token array we get from Polaris property filter to query API format
 * The number of propertyKey in the token array will affect the output query, like it will decide to use either term or terms
 * Or it will use single string or string array
 *
 * It will also based on the input value to decided whether it's a data type, currently we support
 * YYYY-MM-DD  MM/DD/YYYY  DD-MM-YYYY
 * If we see these format, this function will convert it to numeric number
 * Modify this function with great care
 *
 * Example:
 * Input:
 *[
    {propertyKey: 'salesforceAccountName', operator: '=', value: '123'},
    {propertyKey: 'salesforceAccountId', operator: '=', value: '32132'}
    {propertyKey: 'salesforceAccountId', operator: '=', value: '9876'}
    {propertyKey: 'awsAccountPhase', operator: '>=', value: '98273'}
    {propertyKey: 'awsAccountPhase', operator: '<=', value: '100000'}
    {propertyKey: 'industry', operator: '!=', value: 'xyz'}
    {propertyKey: 'territory', operator: '!=', value: 'uwy'}
    {propertyKey: 'territory', operator: '!=', value: '8999'},
    {propertyKey: '"isAwsEngagedCustomer"', operator: '>=', value: '2023-09-12'}
 ],

  Output:
 {
  "filter": [
    {
      "property": "salesforceAccountName",
      "type": "term",
      "value": "123"
    },
    {
      "property": "salesforceAccountId",
      "type": "term",
      "value": "32132"
    },
    {
      "property": "awsAccountPhase",
      "type": "range",
      "value": {
        "gte": 98273,
        "lte": 10000
      }
    },
    {
      "property": "isAwsEngagedCustomer",
      "type": "range",
      "value": {
        "gte": 1694476800000
      }
    }
  ],
  "must_not": [
    {
      "property": "undefined",
      "type": "term",
      "value": "SFDC ID9876"
    },
    {
      "property": "industry",
      "type": "term",
      "value": "xyz"
    },
    {
      "property": "territory",
      "type": "terms",
      "value": [
        "uwy",
        "8999"
      ]
    }
  ]
}
 */


//Interface defining the structure of a search token.
export interface Token {
  propertyKey: string; // The key of the property to filter or exclude
  operator: '=' | '!=' | '<' | '>' | '<=' | '>=' | ':'; // The operator used for comparison.
  value: string;
};

// Interface defining the structure of the query with filter and must_not clauses.
interface Query {
  filter: any[]; // Array of filter clauses.
  must_not: any[]; // Array of must_not clauses for exclusions.
  match?: any[];
};

// Function to convert an array of tokens into a filter and must_not query structure.
export default function convertTokensToQuery (tokens: Token[]): Query {
  // Initial empty query object.
  const query: Query = { filter: [], must_not: [] };

  // Objects to aggregate terms and must_not terms
  const termsAggregation: { [key: string]: Set<string | number> } = {};
  const mustNotTermsAggregation: { [key: string]: Set<string | number> } = {};
  const rangeAggregation: { [key: string]: { [key: string]: number } } = {};

  // Iterate over each token in the array.
  tokens.forEach(token => {
    const { propertyKey, operator, value } = token;

    if (operator === ':') {
      query.match = query.match || [];
      query.match.push({ property: propertyKey, type: 'match', value: value })
    } else if (['<', '<=', '>', '>='].includes(operator)) {
      let numericValue: number;

      // Check if the value is a valid data string; if so, convert it to a timestamp.
      const isDate = /^(\d{4}-\d{2}-\d{2}|\d{2}\/\d{2}\/\d{4}|\d{2}-\d{2}-\d{4})$/.test(value);
      if (isDate && !isNaN(Date.parse(value))) {
        numericValue = Date.parse(value);
      } else {
        numericValue = parseFloat(value);
        if (isNaN(numericValue)) {
          throw new Error(`Invalid number for range filter: ${ value } for property ${ propertyKey }`);
        }
      }
      if (!rangeAggregation[propertyKey]) {
        rangeAggregation[propertyKey] = {};
      }
      const rangeType = operator === '<' ? 'lt' : operator === '<=' ? 'lte' : operator === '>' ? 'gt' : 'gte';
      rangeAggregation[propertyKey][rangeType] = numericValue;
    } else {
      const aggregation = operator === '=' ? termsAggregation : mustNotTermsAggregation;
      if (!aggregation[propertyKey]) {
        aggregation[propertyKey] = new Set();
      }
      aggregation[propertyKey].add(value);
    }
  });

  for (const [key, values] of Object.entries(termsAggregation)) {
    if (values.size === 1) {
      query.filter.push({ property: key, type: 'term', value: Array.from(values)[0] });
    } else {
      query.filter.push({ property: key, type: 'terms', value: Array.from(values) });
    }
  }

  for (const [key, values] of Object.entries(mustNotTermsAggregation)) {
    if (values.size === 1) {
      query.must_not.push({ property: key, type: 'term', value: Array.from(values)[0] });
    } else {
      query.must_not.push({ property: key, type: 'terms', value: Array.from(values) });
    }
  }

  for (const [key, range] of Object.entries(rangeAggregation)) {
    query.filter.push({ property: key, type: 'range', value: range });
  }

  return query;
};

