import { Header } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

export type FormHeadProps = {
  updateTools?: any;
  isEditMode: boolean;
  isReopenMode: boolean;
};

const LNACreateFormHeader = ({ isEditMode, isReopenMode }: FormHeadProps) => {
  const { formatMessage } = useIntl();
  const description = isEditMode
    ? formatMessage({ id: 'modifySubTitle' })
    : isReopenMode
          ? formatMessage({ id: 'reopenSubTitle' })
          : formatMessage({ id: 'createSubTitle' });

  const content = isEditMode
    ? formatMessage({ id: 'editEngagement' })
    : isReopenMode
          ? formatMessage({ id: 'reopenEngagement' })
          : formatMessage({ id: 'createEngagement' })

  return (
    <Header
      variant='h1'
      description={description}
    >
      {content}
    </Header>
  );
};

export default LNACreateFormHeader;
