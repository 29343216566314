export interface ILanguageObject {
  [key: string]: string;
};

/**
 *
 * @param obj1 JSON object with key value pair that contains English language
 * @param obj2 Other language JSON file
 * @returns a new object with all the keys from English, but the value with obj2, if the key is not present in obj2, use English value as default
 */
export function merge(obj1: any, obj2: any) {
  const keys = Object.keys(obj1);
  const newObj: any = {};
  for (let key of keys) {
    if (obj2[key]) {
      newObj[key] = obj2[key]
    } else {
      newObj[key] = obj1[key]
    }
  }
  return newObj;
};
