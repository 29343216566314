import { createSlice } from '@reduxjs/toolkit';

export type LanguageState = {
  currentLocale: string;
};

const initialState: LanguageState = {
  currentLocale: 'en'
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage(state, action) {
      state.currentLocale = action.payload;
    }
  }
});

export const { changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;
