import apiReducer from './reducers/apiReducer';
import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './reducers/languageReducer';
import notificationReducer from './reducers/notificationReducer';
import userReducer from './reducers/userInfoReducer';

const store = configureStore({
  reducer: {
    language: languageReducer,
    notifications: notificationReducer,
    api: apiReducer,
    user: userReducer,
  }
});

export default store;
