import { Alert, Spinner } from '@amzn/awsui-components-react';
import { ApiHookResult, ApiHookStatuses } from '../../interfaces';
import { Children, ReactNode } from 'react';

import { useIntl } from 'react-intl';

type WaitForProps = {
  data: Partial<ApiHookResult<any>>;
  children: ReactNode;
  centerSpinner?: boolean;
  spinnerSize?: 'normal' | 'big' | 'large';
  customSpinner?: JSX.Element;
};
/**
 * Component to wait for ApiHook to finish loading before displaying results or error
 */
export const WaitFor = ({ data, children, centerSpinner, spinnerSize, customSpinner }: WaitForProps) => {
  const { formatMessage } = useIntl();
  const childArray = Children.toArray(children);
  if (data.isSuccess || data.status === ApiHookStatuses.success) return <>{childArray?.map((child) => child)}</>;
  else if (data.error) {
    if (typeof data.error.message === 'string') {
      return (
        <Alert type='error'>{data.error.message}</Alert>
      )
    } else {
      return (
        <Alert type='error'>{formatMessage({ id: 'error' })}</Alert>
      )
    }
  }
  else {
    if (customSpinner) return customSpinner;
    else if (centerSpinner)
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', color: 'white', position: 'relative', top: '-24px'}}>
          <Spinner size={spinnerSize} />
          <h2>{formatMessage({ id: 'loadingResources' })}</h2>
        </div>
      );
    else return <Spinner size={spinnerSize} />;
  }
};