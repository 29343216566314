import { createSlice } from '@reduxjs/toolkit';

export type ApiState = {
  endpoint: string;
  stage: string;
};

const initialState: ApiState = {
  endpoint: '',
  stage: ''
};

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setApiConfig(state, action) {
      state.endpoint = action.payload.endpoint;
      state.stage = action.payload.stage;
    }
  }
});

export const { setApiConfig } = apiSlice.actions;

export default apiSlice.reducer;
