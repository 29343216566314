import { hasValidToken, redirectToLoginPage } from '../../util/amplify';
import { useEffect, useState } from 'react';

import Auth from '@aws-amplify/auth';
import Cache from '@aws-amplify/cache';
import { Hub } from '@aws-amplify/core';
import { Spinner } from '@amzn/awsui-components-react';
import { setUser } from '../../redux/reducers/userInfoReducer';
import { useDispatch } from 'react-redux';

export const Authenticator = ({ children, globals }: { children: JSX.Element; globals: Window }) => {

    const [signedIn, setSignedIn] = useState(false);
    const dispatch = useDispatch();
    const setSignedInUserToLocalStorage = async () => {
        const { payload } = (await Auth.currentAuthenticatedUser({ bypassCache: true }))
            .getSignInUserSession()
            .getIdToken();
        const { family_name, given_name, email, name, public_provider_name } = payload;
        const fullName = given_name && family_name ? `${given_name} ${family_name}` : name;

        dispatch(setUser({ email, fullName, publicProviderName: public_provider_name }));
        Cache.setItem('userProfile', {
            fullName,
            email,
            public_provider_name,
        });
    };

    useEffect(() => {
        hasValidToken().then((hasToken) => {
            if (hasToken) {
                setSignedInUserToLocalStorage();
                setSignedIn(true);
            } else {
                redirectToLoginPage();
            }
        });

        Hub.listen('auth', ({ payload: { data, event } }) => {
            if (event === 'signIn') {
                const token = data.getSignInUserSession().getIdToken().getJwtToken();
                Cache.setItem('federatedInfo', { token });
                setSignedIn(true);
            }
        });
    }, [globals]);

    return signedIn ? children : <Spinner size='large' />;
};
