import { strEnum } from '../common/str-enum';

// Is of type T or undefined. Used for '?' properties of TS.
export type Optional<T> = T | undefined;

// https://code.amazon.com/packages/LNAService/blobs/mainline/--/src/main/java/com/amazon/lnaservice/pojo/dynamo/DynamoEngagementV2.java
export interface IEngagement {
  accountName: string;
  accountSFDCId: string;
  engagementId: string;
  engagementName: string;
  engagementCreationDate: number;
  engagementCloseDate: number;
  engagementCreatedBy: string;
  engagementStatus: string;
  engagementType: string;
  teams: string[];
  estimatedNumberOfParticipants?: number;
  inProgressResponses: number;
  completedResponses: number;
  lastResponseDate: number;
  isThisLNAForAnATP: boolean;
  displayIndividualRecommendations: boolean;
  internalAWSTestEngagement: boolean;
  surveyId: string;
  surveyPreviewLink: string;
  surveyDistributionLink: string;
  responseDownloadLink: string;
  collaborators?: string[];
  lastUpdateDate?: number;
  versionNumber?: number;
  partnerId?: string;
  partnerName?: string;
  userType?: string;
}

export const ApiHookStatuses = strEnum(['idle', 'loading', 'error', 'success']);

export type ApiHookStatus = keyof typeof ApiHookStatuses;

export type ApiHookResult<T, S = Error> = {
  data: T | undefined;
  error: S;
  status: ApiHookStatus;
  refetch?: any;
  isLoading?: boolean;
  isFetching?: boolean;
  isSuccess?: boolean;
};

export type ApiMutateHookResult<T, A, S> = {
  mutate: (a: A, b?: any) => void;
} & ApiHookResult<T, S>;

export type Type = 'success' | 'warning' | 'info' | 'error';

export interface IEngagementDetail {
  percentCompleted: string;
  inProgress: number;
  completed: number;
  total: number;
  accountName: string;
  sfdcID: string;
  closeDate: string;
  type: string;
  status: string;
  isIndividual: boolean;
  isTest: boolean;
  teams: string[];
  engagementId: string;
  creatorEmail: string;
  collaborators?: string[];
  partnerId?: string;
  partnerName?: string;
  userRole: string[];
}

export interface IAccount {
  salesforceAccountId: string;
  salesforceAccountName: string;
  businessUnit: string;
  segment: string;
  subSegment: string;
  industry: string;
  geo: string;
  region: string;
  subRegion: string;
  firstBillingCountry: string;
  territory: string;
  awsAccountPhase: string;
  isAttachedByAnyTMode: boolean;
  awsGreenfieldStatus: boolean;
  isAwsEngagedCustomer: boolean;
  tandcBdmContactEmail: string;
  tandcBdmLeaderContactEmail: string;
  tandcBdmAlias: string;
  tandcBdmLeaderAlias: string;
  tandcBdmBdm: string;
  tandcBdmBdmLeader: string;
  awsAccountManagerAm: string;
  isEdp: boolean;
  isMap: boolean;
  isApjDeep: boolean;
  isHighPropensityPrivateIlt: boolean;
  apjIcon: string;
  isApjIcon: boolean;
  isLatamDeep: string;
  isProservePriority: boolean;
  estimationMethod: string;
  estimationMethodRank: number;
  revBandAwsSalesT12M: string;
  penetrationTarget: number;
  isPenetrated: boolean;
  cAndTConsumption: number;
  totalAddressableSpend: number;
  revTandcBilledT12M: number;
  revAwsSales12M: number;
  totalAddrLearnersEstimate: number;
  uniqueIndTOrC: number;
  uniqueIndExclPartnerT: number;
  uniqueIndCertifications: number;
  uniqueIndAnyIlt: number;
  uniqueIndPrivateIlt: number;
  uniqueIndPublicIlt: number;
  uniqueIndChannelIlt: number;
  uniqueIndDigital: number;
  totalConsumptionTOrC: number;
  certificationsAwarded: number;
  trainingsAnyIlt: number;
  trainingsPrivateIlt: number;
  trainingsPublicIlt: number;
  trainingsChannelIlt: number;
  trainingsDigital: number;
  attTotalAddrSpendTandC: number;
  attachment: number;
  attachmentExclPartnerT: number;
  tandcPhase: string;
  accountPriScore: number;
  accountPriScoreDescription: string;
  updatedWhen: string;
  tableDescription: string;
  sfUltimateParentId: string;
  territoryId: string;
  gtmCustomerId: string;
  gtmCustomerName: string;
  priorityBucket: string;
  priorityBucketDescription: string;
  propensityCtfRank: string;
  propensityCtfScore: number;
  propensityPrivateIltScore: number;
  propensityPrivateIltScoreRank: string;
  propensitySbtsRank: string;
  propensitySbtsScore: number;
  propensityTandcRank: string;
  propensityTandcScore: number;
  proservePasmTier: string;
  isHighPropensitySbts: boolean;
  isHighPropensityCtf: boolean;
  isHighPropensityTandc: boolean;
  avgCftConsumpPerLearner: number;
  avgPIltConsumpPerLearner: number;
  priScoreHistory: string;
  priScoreChange?: number;
}

export type Accounts = IAccount[];

export type GetAccountsResponse = Accounts;

export type GetAccountDetailsResponse = IAccount;

export type GetAccountDetailsRequest = {
  accountId: string;
};

export type GetAccountsRequest = {
  filter?: any;
  must_not?: any;
  match?:any;
  sort?: { property: string | undefined, direction: string }[];
  size?: number;
  offset?: number;
  assignedToMe?: boolean;
}

export type SortAccountsByPropertyRequest = GetAccountsRequest;

export type SortAccountsByPropertyResponse = GetAccountsResponse;