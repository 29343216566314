import { Button, Header, SpaceBetween } from '@amzn/awsui-components-react';

import { useIntl } from 'react-intl';

export interface EngagementsHeaderProps {
  count: number;
}

const EngagementsHeader = (props: EngagementsHeaderProps) => {
  const { count } = props;
  const { formatMessage } = useIntl();

  return (
    <Header
      variant='awsui-h1-sticky'
      counter={`(${count})`}
      description={formatMessage({ id: 'engagementsPageDescription' })}
      actions={
        <SpaceBetween
          size='xs'
          direction='horizontal'
        >
          <Button
            href='#/lna-creation'
            variant='primary'
          >
            {formatMessage({ id: 'createEngagement' })}
          </Button>
        </SpaceBetween>
      }
    >
        {formatMessage({ id: 'engagements' })}
    </Header>
  );
};

export default EngagementsHeader;
